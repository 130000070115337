import styled from '@emotion/styled/macro'
import { color } from 'styled-system'

export const SearchSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  ${color}
`

export const SearchIcon = styled.img`
  right: 20px;
  top: 15px;
`
