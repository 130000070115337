import styled from '@emotion/styled/macro'
import { Box } from '../box'
import { Text } from '../text'

import { border } from 'styled-system'

export const MainImage = styled(Box)`
  overflow: hidden;
  ${border}
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export const TextColumn = styled(Box)`
  & > p,
  & > div {
    margin-bottom: ${(props) => props.theme.space[2]};
  }
`

export const Headline = styled(Text)`
  ${({ theme }) =>
    theme.inViewStyle({
      delay: 0,
      parent: true,
    })}
`

export const Eyebrow = styled(Box)`
  ${({ theme }) =>
    theme.inViewStyle({
      delay: 100,
      parent: true,
    })}
`

export const Description = styled(Box)`
  ${({ theme }) =>
    theme.inViewStyle({
      delay: 200,
      parent: true,
    })}
`

export const CtaWrapper = styled(Box)`
  ${({ theme }) =>
    theme.inViewStyle({
      delay: 300,
      parent: true,
    })}
`

export const OfferWrapper = styled(Box)`
  ${({ theme }) =>
    theme.inViewStyle({
      delay: 400,
      parent: true,
    })}
`
