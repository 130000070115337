import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react/macro'
import { getMediaType } from '../../utils/getMediaType'
import { useInView } from 'react-hook-inview'
import * as Styled from './BundleCard.styled'
import { ImageLazy } from '../image-lazy'
import { VideoPlayer } from '../video-player'
import { Text } from '../text'
import DisplayAmount from '../display-amount'

const BundleCard = ({
  bundle_img_url,
  img_url,
  description,
  name,
  eyebrow,
  price,
  showDescription,
  url_key,
  children,
  ...props
}) => {
  const [ref, inView] = useInView({
    threshold: 0.25,
    unobserveOnEnter: true,
  })

  const theme = useTheme()

  const renderMedia = () => {
    const mediatype = getMediaType(bundle_img_url)

    return (
      <Styled.MainImageContainer>
        {mediatype === 'image' ? (
          <ImageLazy
            h="100%"
            w="100%"
            position="absolute"
            src={bundle_img_url}
          />
        ) : (
          <Fragment>
            <ImageLazy h="100%" w="100%" position="absolute" src={img_url} />
            <VideoPlayer
              position="absolute"
              src={bundle_img_url}
              placeholder=""
            />
          </Fragment>
        )}
      </Styled.MainImageContainer>
    )
  }

  return (
    <Styled.CardWrapper
      to={'/' + url_key}
      {...props}
      ref={ref}
      className={inView ? theme.inViewClass : ''}
    >
      {renderMedia()}

      <Styled.CardContent p="x2">
        <Styled.TitleRow>
          <Text as="h4" textStyle="bundleHeader" color="primary.black">
            {name}
          </Text>
          <Text as="div" textStyle="bundleHeader" color="primary.black">
            <DisplayAmount
              amountInCents={price.fixed_price_amount.value}
              currencyCode={price.fixed_price_amount.currency_code}
            />
          </Text>
        </Styled.TitleRow>
        {!!description && showDescription && (
          <Text textStyle="bundleDescription" color="primary.black" mt="x1">
            {description}
          </Text>
        )}
      </Styled.CardContent>
      <Styled.CardImages px="x2" pb="x2" pt="0">
        {!!eyebrow && (
          <Text
            textStyle="bundleEyebrow"
            color="secondary.greymidnight"
            mt="x2"
            mb="x2"
          >
            {eyebrow}
          </Text>
        )}
        <Styled.ImagesContainer>
          {children &&
            children.map((child) => (
              <Styled.CardImage
                key={`${name}-${child.sku}`}
                src={child.img_url}
              />
            ))}
        </Styled.ImagesContainer>
      </Styled.CardImages>
    </Styled.CardWrapper>
  )
}

BundleCard.propTypes = {
  bundle_img_url: PropTypes.string.isRequired,
  img_url: PropTypes.string,
  description: PropTypes.string,
  eyebrow: PropTypes.string,
  price: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  showDescription: PropTypes.bool,
  url_key: PropTypes.string.isRequired,
  children: PropTypes.array,
}

BundleCard.defaultProps = {
  eyebrow: 'Brands Included',
  showDescription: true,
}

export default BundleCard
