import styled from '@emotion/styled/macro'
import { layout, space, flexbox, border } from 'styled-system'
import {
  Container as PP_Container,
  Row as PP_Row,
  Col as PP_Col,
} from '@paypalcorp/pp-react'

const gutter = ({ theme }, CSSProperties, modifier = 1) => {
  const { margins } = theme.breakpointData
  const units = margins.map((bp) => `${bp.value * modifier}${bp.unit}`)

  const defaultGutter = CSSProperties.map((prop) => {
    return { [prop]: units[0] }
  })

  const responsiveGutters = theme.breakpoints.map((br, index) => {
    const media = '@media (min-width:' + br + ')'
    const query = { [media]: {} }
    CSSProperties.map((prop) =>
      Object.assign(query[media], {
        [prop]: units[index + 1],
      }),
    )
    return query
  })

  return [...defaultGutter, ...responsiveGutters]
}

export const Container = styled(PP_Container)`
  width: 100%;
  max-width: ${({ theme }) => theme.breakpointData.maxWidth.value}px;
  margin-left: auto;
  margin-right: auto;
  ${(props) => gutter(props, ['paddingLeft', 'paddingRight'])}
  ${space}
  ${layout}
`

export const Row = styled(PP_Row)`
  ${(props) => gutter(props, ['marginLeft', 'marginRight'], -0.5)}
  ${space}
  ${layout}
  ${flexbox}
  ${border}
`

export const Col = styled(PP_Col)`
  ${(props) => gutter(props, ['paddingLeft', 'paddingRight'], 0.5)}
  ${space}
  ${layout}
  ${flexbox}
  ${border}
`
