import React, { useState, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-hook-inview'
import clientData from '../../utils/client-data'

import * as Styled from './VideoPlayer.styled'

const isNativeAndroid = clientData.isFromMobileApp && clientData.isFromAndroid

const VideoPlayer = (props) => {
  const { src, placeholder, onLoaded, ...rest } = props

  const playerRef = useRef()
  const [canPlay, setCanPlay] = useState(false)
  const [wrapperRef, isInView] = useInView({
    rootMargin: '100px',
  })

  const play = () => {
    if (canPlay) {
      playerRef.current.play()
    } else {
      playerRef.current.load()
    }
  }

  const pause = () => {
    if (canPlay) {
      playerRef.current.pause()
    }
  }

  useLayoutEffect(() => {
    if (isNativeAndroid) return
    if (isInView) {
      play()
    } else {
      pause()
    }
  }, [isInView, canPlay])

  const onCanPlay = () => {
    if (canPlay) return

    setCanPlay(true)

    if (onLoaded) onLoaded()
  }

  if (isNativeAndroid) return null

  return (
    <Styled.VideoWrapper ref={wrapperRef} {...rest}>
      <video
        muted={true}
        loop
        playsInline
        controls={false}
        ref={playerRef}
        onCanPlay={() => onCanPlay()}
        placeholder={isInView ? placeholder : ''}
      >
        <source src={src} type="video/mp4" />
      </video>
    </Styled.VideoWrapper>
  )
}

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  position: PropTypes.string,
  onLoaded: PropTypes.func,
}

VideoPlayer.defaultProps = {
  position: 'absolute',
}

export default VideoPlayer
