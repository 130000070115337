import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'

export const GiftCard = styled(Link)`
  display: block;
  ${({ theme }) => theme.inViewStyle()}
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`

export const CardImage = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 63%;
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  transition: 200ms ease;
  transition-property: box-shadow, transform;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.1);

  @media (hover: hover) {
    :hover {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1),
        0 4px 14px rgba(0, 0, 0, 0.1);
      transform: scale(1.025);
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }

  /* fix for lazy load not firing sometimes */
  img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
`
