import styled from '@emotion/styled/macro'
import { Box } from '../box'
import { css } from '@emotion/react/macro'

export const SearchContainer = styled(Box)`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  border-radius: 0.25rem;
  transition: box-shadow 0.3s;
  color: ${(props) => props.theme.colors.secondary.greylight};
  z-index: 1000;

  ${(props) =>
    props.isFocused &&
    css`
      color: ${props.theme.colors.primary.bluemidnight};
    `}

  ${(props) =>
    props.isFocused && !props.noShadow
      ? 'box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.15)'
      : 'box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.1)'};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    pointer-events: none;
  }

  &.in-nav {
    height: 2rem;
    border-radius: 0px;
    box-shadow: none;

    &:after {
      display: none;
    }
  }
`
export const InputContainer = styled(Box)`
  display: flex;
  position: relative;
  height: 100%;
  flex-grow: 19;
  border: 1px solid ${(props) => props.theme.colors.secondary.greymidnight};

  ${(props) =>
    props.hasSubmitButton
      ? `
    border-right: 0;
    border-radius: 0.25rem 0 0 0.25rem;
  `
      : `
    border-radius: 0.25rem;
  `}

  background: white;
  transition: 0.3s;

  .in-nav & {
    border: 0;
    border-radius: 0;
  }

  span {
    position: absolute;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 3rem;
    text-align: center;
    color: ${(props) => props.theme.colors.secondary.greymidnight};
    pointer-events: none;
    transition: color 0.3s;

    .in-nav & {
      margin-top: 0.25rem;
      width: 2rem;
    }

    &.location-icon {
      left: auto;
      right: 0;
      color: ${(props) => props.theme.colors.primary.bluemidnight};
      cursor: pointer;
      pointer-events: auto;

      &.location-icon--denied {
        color: #d20000;
        cursor: not-allowed;
      }

      p {
        margin: auto;
      }
    }
  }

  input {
    outline: none;
    border: 0;
    height: 100%;
    padding: 0;
    padding-left: 3rem;
    width: 100%;
    background-color: transparent;
    color: black;

    ::placeholder {
      color: ${(props) => props.theme.colors.secondary.greymidnight};
    }

    .in-nav & {
      font-size: 1rem;
      margin-left: 2.25rem;
      padding-left: 0rem;
      border-bottom: 1px solid;
      border-color: ${(props) => props.theme.colors.secondary.greymidnight};

      &:focus {
        border-color: ${(props) => props.theme.colors.primary.bluemidnight};
      }
    }
  }
`

export const SubmitButton = styled(Box)`
  height: calc(3rem + 2px);
  outline: none;
  border: 0;
  border-radius: 0 0.25rem 0.25rem 0;
  padding: 0 ${(props) => props.theme.space.x1};
  min-width: 5.5rem;
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.primary.bluemidnight};
  transition: background-color 0.3s;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.primary.bluemedium};
  }
`
