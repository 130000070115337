import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Row, Col } from '@paypalcorp/pp-react'
import { Container } from '../grid'
import { Text } from '../text'
import { Box } from '../box'
import { Suggestions, SearchInput } from '.'
import { MessageFormat } from '@paypalcorp/worldready'
import { ContextConsumer as WorldReadyContextConsumer } from '@paypalcorp/worldready-react'

import _ from 'lodash'
import * as Styled from './SearchBar.styled'

const SearchBar = ({
  suggestions,
  eyebrow,
  placeholder,
  enableSeeYourLocation,
  currentLocationLabel,
  onSubmit,
}) => {
  const [query, setQuery] = useState('')
  const updateQuery = (newValue) => {
    setQuery(newValue)
    onSubmit(newValue)
  }

  return (
    <WorldReadyContextConsumer>
      {(worldready) => (
        <Styled.SearchSection>
          <Container>
            <Row
              as={(props) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  {...props}
                />
              )}
            >
              <Col md={10} lg={8}>
                <SearchInput
                  id="search-bar"
                  onSubmit={updateQuery}
                  value={query}
                  placeholder={
                    _.isEmpty(placeholder)
                      ? new MessageFormat(worldready, {
                          id: 'pages/search-bar.search.input',
                        }).format()
                      : placeholder
                  }
                  enableSeeYourLocation={enableSeeYourLocation}
                  currentLocationLabel={currentLocationLabel}
                />
              </Col>
            </Row>
          </Container>
          <Box pt="1rem" mb="20px">
            {!!eyebrow && (
              <Text size="lg" color="secondary.greydark" pb="10px">
                {eyebrow}
              </Text>
            )}
            {suggestions.length ? (
              <Suggestions onChange={updateQuery} suggestions={suggestions} />
            ) : null}
          </Box>
        </Styled.SearchSection>
      )}
    </WorldReadyContextConsumer>
  )
}

SearchBar.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.string),
  eyebrow: PropTypes.string,
  placeholder: PropTypes.string,
  enableSeeYourLocation: PropTypes.bool,
  currentLocationLabel: PropTypes.string,
  onSubmit: PropTypes.func,
}

SearchBar.defaultProps = {
  suggestions: [],
  placeholder: '',
  enableSeeYourLocation: false,
  onSubmit: () => null,
}

export default SearchBar
