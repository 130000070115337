import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import { Message } from '@paypalcorp/worldready-react'

import { CartContext } from '../../context/cart-context'

const Header = styled.div`
  font-size: 20px;
  color: #2c2e2f;
  padding-bottom: 24px;
`

const OrderNumber = styled.div`
  font-size: 20px;
  color: #4a4a4a;
  padding-bottom: 36px;
`

const Note = styled.div`
  font-size: 18px;
  color: #2b2b2b;
  padding-bottom: 36px;
  text-align: left;
`

const SuccessMssage = ({ orderNumber }) => {

  const { resetCartItems } = React.useContext(CartContext)
  const [hasMounted, setHasMounted] = React.useState(false)

  // Memory leak prevention on re-render
  React.useEffect(() => {
    if (!hasMounted) {
      resetCartItems()
      setHasMounted(true)
    }
  }, [hasMounted])

  return (
    <div>
      <Header id="success-title" style={{}}>
        <Message id="pages/success.success.header" />
      </Header>
      <OrderNumber>
        <Message
          id="pages/success.success.orderNumber"
          orderNumber={orderNumber}
        />
      </OrderNumber>
  
      <React.Fragment>
        <Note>
          <Message id="pages/success.success.messageTop" />
        </Note>
        <Note>
          <Message id="pages/success.success.messageMiddle" />
        </Note>
        <Note>
          <Message id="pages/success.success.messageBottom" />
        </Note>
      </React.Fragment>
    </div>
  )
}

SuccessMssage.prototype = {
  orderNumber: PropTypes.string.isRequired,
}

export default SuccessMssage
