import React, { useState, useLayoutEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useApolloClient } from '@apollo/client'
import { useInView } from 'react-hook-inview'
import { Text } from '../text'
import { getMediaType } from '../../utils/getMediaType'
import { ImageLazy } from '../image-lazy'
import { VideoPlayer } from '../video-player'
import { SearchBar } from '../search-bar'
import { Container, Row, Col } from '@paypalcorp/pp-react'
import { withRouter } from 'react-router-dom'
import { useCurrentBreakpoint } from '../../utils/hooks/useCurrentBreakpoint'
import { MessageFormat } from '@paypalcorp/worldready'
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react'
import * as Styled from './SearchHero.styled'
import clientData from '../../utils/client-data'
import getSearchHeroVisibility from '../../graphql/getSearchHeroVisibility'

const elmo = clientData.experimentation

const SearchHero = (props) => {
  const {
    headline,
    headlineSize,
    headlineComponent,
    headlinePadding,
    headlineStyle,
    searchLabel,
    searchSuggestions,
    history,
    searchType,
    mediaSrc,
    isHomePage, //overrides for homepage header
    onSearchSubmit,
    imageStyle,
    enableSeeYourLocation,
    currentLocationLabel,
    ...rest
  } = props

  const worldready = useContext(WorldReadyContext)
  const smbSuggestion = new MessageFormat(worldready, {
    id: 'pages/suggestions.suggestions.smb',
  }).format()

  // replacing unnecessary html in string
  const htmlPrunedSearchSuggestions =
    searchSuggestions && searchSuggestions.map((v) => v.replace(/&amp;/, '&'))

  if (htmlPrunedSearchSuggestions && elmo.localBusinesses) {
    htmlPrunedSearchSuggestions.push(smbSuggestion)
  }

  const defaultSearchSubmit = (value) => {
    if (value.length) {
      if (value === smbSuggestion) history.push(`/search/businesses`)
      else history.push(`/search/${searchType}?q=${encodeURIComponent(value)}`)
    }
  }

  const bkpt = useCurrentBreakpoint()
  const [animateIn, setAnimateIn] = useState(false)
  const [ref, inView] = useInView()
  const client = useApolloClient()

  const isInView = () => {
    //if not animated in yet just return true
    if (!animateIn) return true

    return inView
  }

  //set in view to show nav bar search input
  useLayoutEffect(() => {
    client.writeQuery({
      query: getSearchHeroVisibility,
      data: { searchHeroInView: isInView() },
    })
  }, [inView])

  const onMediaLoaded = () => {
    setTimeout(() => setAnimateIn(true), 1000)
  }

  const renderMedia = () => {
    if (!mediaSrc) return null

    let src, mediaType

    if (bkpt === 0) {
      //sm size
      src = mediaSrc.sm
      mediaType = getMediaType(mediaSrc.sm)
    } else {
      src = mediaSrc.lg
      mediaType = getMediaType(mediaSrc.lg)
    }

    if (mediaType === 'video') {
      //make sure key prop is here so video re-renders during resize
      return (
        <VideoPlayer
          key={src}
          position="absolute"
          src={src}
          placeholder=""
          onLoaded={onMediaLoaded}
        />
      )
    }

    return (
      <ImageLazy
        h="100%"
        w="100%"
        position="absolute"
        bgSrc={src}
        onLoaded={onMediaLoaded}
        imageStyle={imageStyle}
      />
    )
  }

  return (
    <Styled.SearchHero
      {...rest}
      minHeight={isHomePage ? '400px' : ''}
      className={animateIn ? 'animated-in' : ''}
      data-testid="search-hero"
    >
      <Styled.BgContainer>{renderMedia()}</Styled.BgContainer>
      <Styled.MainHeadlineContainer>
        {headline && (
          <Styled.HeadlineContainer>
            <Container>
              <Row>
                <Col>
                  <Text
                    as="h1"
                    padding={headlinePadding}
                    textStyle={headlineSize === 'sm' ? 'h2' : 'h1'}
                    color="primary.black"
                    style={headlineStyle}
                  >
                    {headline}
                  </Text>
                </Col>
              </Row>
            </Container>
          </Styled.HeadlineContainer>
        )}
        {!!headlineComponent && headlineComponent}
      </Styled.MainHeadlineContainer>
      <Styled.SearchContainer ref={ref}>
        <SearchBar
          suggestions={htmlPrunedSearchSuggestions}
          placeholder={searchLabel}
          enableSeeYourLocation={enableSeeYourLocation}
          currentLocationLabel={currentLocationLabel}
          onSubmit={onSearchSubmit || defaultSearchSubmit}
        />
      </Styled.SearchContainer>
    </Styled.SearchHero>
  )
}

SearchHero.propTypes = {
  headline: PropTypes.string,
  headlineComponent: PropTypes.element,
  searchLabel: PropTypes.string,
  searchSuggestions: PropTypes.array,
  headlineSize: PropTypes.string,
  headlinePadding: PropTypes.string,
  headlineStyle: PropTypes.object,
  searchSubmitHandler: PropTypes.func,
  history: PropTypes.object,
  searchType: PropTypes.oneOf(['all', 'cards', 'bundles']),
  mediaSrc: PropTypes.object,
  isHomePage: PropTypes.bool,
  onSearchSubmit: PropTypes.func,
  imageStyle: PropTypes.object,
  enableSeeYourLocation: PropTypes.bool,
  currentLocationLabel: PropTypes.string,
}

SearchHero.defaultProps = {
  searchType: 'all',
  headlinePadding: '100px 56px',
  enableSeeYourLocation: false,
}

export default withRouter(SearchHero)
