import React from 'react'
import styled from '@emotion/styled/macro'
import PropTypes from 'prop-types'
import { Box } from '../box'
import { Text } from '../text'
import DisplayAmount from '../display-amount'
import { Message } from '@paypalcorp/worldready-react'

export const Item = styled(Box)`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.secondary.greylight};
  align-items: center;

  img {
    border-radius: 0.25rem;
  }
`

const Amount = ({ amountToPay, totalAmount }) => {
  if (amountToPay.value === totalAmount.value) {
    return (
      <span>
        <DisplayAmount
          amountInCents={amountToPay.value}
          currencyCode={amountToPay.currency_code}
        />
      </span>
    )
  }

  return (
    <div>
      <Box as="strike">
        <DisplayAmount
          amountInCents={totalAmount.value}
          currencyCode={totalAmount.currency_code}
        />
      </Box>
      <Box as="span" color="primary.red" ml="x1">
        <DisplayAmount
          amountInCents={amountToPay.value}
          currencyCode={amountToPay.currency_code}
        />
      </Box>
    </div>
  )
}

Amount.propTypes = {
  amountToPay: PropTypes.object,
  totalAmount: PropTypes.object,
}

const BundleCartItem = ({ item, handleRemove, inset = false }) => {
  const padding = ['x2', 'x3']
  const insetPadding = inset ? padding : 0

  return (
    <Item py="x2">
      <Box pl={insetPadding} pr={padding}>
        <img src={item.imgUrl} width={100} alt="" />
      </Box>
      <Box>{item.productName || item.sku}</Box>
      <Box ml="auto">
        <Amount amountToPay={item.amountToPay} totalAmount={item.totalAmount} />
      </Box>
      <Box
        color="primary.bluemedium"
        pl={padding}
        pr={insetPadding}
        role="button"
        onClick={handleRemove}
      >
        <Text style={{ cursor: 'pointer' }} textStyle="smallCaption">
          <Message id="pages/custom-bundles.cart.remove" />
        </Text>
      </Box>
    </Item>
  )
}

BundleCartItem.propTypes = {
  item: PropTypes.object,
  inset: PropTypes.bool,
  handleRemove: PropTypes.func,
}

export default BundleCartItem
