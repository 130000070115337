import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import { Redirect } from 'react-router-dom'
import clientData from '../../utils/client-data'
import GiftCardsList from '../shared/gift-cards-list'
import { GiftCardsContent } from '../shared/styled'
import { graphql } from '@apollo/client/react/hoc'
import getProductsInCategory from '../../graphql/getProductsInCategory'
import { trackImpression } from '../../utils/analytics'
import { handleSessionError } from '../../utils/error-tracking'
import { flowRight as compose } from 'lodash'
import getAllProducts from '../../graphql/getAllProducts'
import { Container } from '@paypalcorp/pp-react'
import { Message } from '@paypalcorp/worldready-react'

const Title = styled.h1`
  text-align: center;
  color: #4a4a4a;
  margin: auto;
  padding-top: 42px;
  padding-bottom: 50px;

  @media only screen and (max-width: 768px) {
    padding: 20px 0;
  }
`

class GiftCardCategory extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.object,
      getProductsInCategory: PropTypes.object,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }),
    allProducts: PropTypes.shape({
      getAllProducts: PropTypes.shape({
        all_a_z: PropTypes.array,
      }),
      loading: PropTypes.bool,
    }),
  }

  constructor(props) {
    super(props)
    this.state = {
      searchFilter: () => true,
      showAllCards: false,
    }

    this.goToAll = this.goToAll.bind(this)
  }

  componentDidMount() {
    trackImpression(
      'main:consumer:gifts:home:',
      `main:consumer:gifts:home:category:${encodeURIComponent(
        this.props.match.params.name,
      )}::`,
    )

    window.scrollTo(0, 0)
  }

  goToAll = () => {
    this.setState((prevState) => ({
      ...prevState,
      showAllCards: !prevState.showAllCards,
    }))
  }

  render() {
    const { data, allProducts } = this.props

    if (data.loading || (allProducts && allProducts.loading)) {
      return (
        <div className="vx_has-spinner-large" style={{ paddingTop: '400px' }} />
      )
    }
    if (this.props.data.error) {
      handleSessionError(this.props.data.error)
      return <Redirect to="/error" />
    }

    const categoryProducts = this.props.data.getProductsInCategory

    const path = this.props.location.pathname.split('/')
    // no matching category, redirect to categories/occasions page
    if (categoryProducts.category_name === '') {
      return path[1] === 'categories' ? (
        <Redirect to="/categories" />
      ) : (
        <Redirect to="/occasions" />
      )
    }

    return (
      <div>
        <Title className="vx_text-2">{categoryProducts.category_name}</Title>

        <div id="gift-card-category">
          <Container>
            <GiftCardsContent>
              <div id="gift-cards-a-z">
                <GiftCardsList
                  basepath="../"
                  title={
                    <Message id="pages/all-gift-cards.allgiftcards.giftcardsAZ" />
                  }
                  items={categoryProducts.all_a_z.filter(
                    this.state.searchFilter,
                  )}
                />
              </div>
            </GiftCardsContent>
          </Container>
        </div>
      </div>
    )
  }
}

function resolveRouteAliases(pathname) {
  const aliases = clientData.routeAliases[clientData.locality.country] || {}
  const route = pathname.slice(1)
  return aliases[route] || route
}

const GiftCardCategoryWithData = compose(
  graphql(getProductsInCategory, {
    options: (ownProps) => {
      return {
        variables: {
          urlKey: resolveRouteAliases(ownProps.location.pathname),
        },
      }
    },
  }),
  graphql(getAllProducts, {
    name: 'allProducts',
  }),
)(GiftCardCategory)

export default GiftCardCategoryWithData
