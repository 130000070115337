import { gql } from '@apollo/client'

export default gql`
  query getStoreInfo {
    getStoreInfo {
      store_id
      terms_and_conditions
      locale
    }
  }
`
