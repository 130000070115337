import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Icon } from '@paypalcorp/pp-react'
import { useApolloClient, gql } from '@apollo/client'
import { MessageFormat, DateTimeFormat } from '@paypalcorp/worldready'
import {
  Message,
  ContextConsumer as WorldReadyContextConsumer,
} from '@paypalcorp/worldready-react'

import { Calendar } from './index'
import { HiddenInput } from '../../utils/styled'
import clientData from '../../utils/client-data'
import * as Styled from './FormCalendarInput.styled'


const FormCalendarInput = React.forwardRef((props, ref) => {
  const [calendar, showCalendar] = useState(false)
  const client = useApolloClient()

  const selectedDayInCache = client.readQuery({
    query: gql`
      query getSelectedDay {
        selectedDay @client
      }
    `,
  })

  function toggleCalendar() {
    showCalendar(!calendar)
  }

  function handleDayClick(date, { disabled }) {
    if (disabled) {
      return
    }

    // TODO: verify date for different locale, set selectedDay in store
    client.writeQuery({
      query: gql`
        query getSelectedDay {
          selectedDay @client
        }
      `,
      data: { selectedDay: date.toString() },
    })

    showCalendar(false)
  }

  function handleKeyPress() {
    // Check to see if space or enter were pressed
    if (event.key === ' ' || event.key === 'Enter') {
      // Prevent the default action to stop scrolling when space is pressed
      event.preventDefault()
      toggleCalendar()
    }
  }

  return (
    <WorldReadyContextConsumer>
      {(worldready) => {
        const selectedDay = selectedDayInCache.selectedDay || new Date()
        const dateFormatter = new DateTimeFormat(worldready, {
          timeZone: clientData.locality.timezone,
        })
        const formattedDate = dateFormatter.format(new Date(selectedDay))
        const today = dateFormatter.format(new Date())

        return (
          <Styled.Container>
            <Styled.CalenderInputWrapper onClick={toggleCalendar}>
              <Styled.DateLabel htmlFor="date-input">
                <Message id="pages/product.product.date" />
              </Styled.DateLabel>
              <Styled.CalendarInputInner>
                <Styled.DateInput
                  type="text"
                  readOnly={true}
                  onKeyPress={handleKeyPress}
                  value={
                    formattedDate === today
                      ? new MessageFormat(worldready, {
                          id: 'pages/product.product.defaultDate',
                        }).format()
                      : formattedDate
                  }
                />
                <HiddenInput
                  value={selectedDay}
                  readOnly
                  ref={ref}
                  name={props.name}
                  data={props.data}
                />
                <Icon name="calendar" />
              </Styled.CalendarInputInner>
            </Styled.CalenderInputWrapper>

            {calendar ? (
              <Calendar
                handleDayClick={handleDayClick}
                handleOnBlur={toggleCalendar}
              />
            ) : null}
          </Styled.Container>
        )
      }}
    </WorldReadyContextConsumer>
  )
})

FormCalendarInput.propTypes = {
  name: PropTypes.string,
  data: PropTypes.string,
}


FormCalendarInput.displayName = 'FormCalendarInput';

export default FormCalendarInput
