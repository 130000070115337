import styled from '@emotion/styled/macro'
import { Text } from '../text'
import { Box } from '../box'

const inputStateColor = (props) => {
  if (props.error) return props.theme.colors.primary.red
  if (props.success) return props.theme.colors.primary.green
  return props.theme.colors.primary.bluemidnight
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  border: 2px solid;
  color: ${inputStateColor};
  background-color: ${inputStateColor};
  border-color: ${inputStateColor};
  border-radius: 0.25rem;
  overflow: hidden;
`

export const InputContainer = styled(Box)`
  height: 100%;
  padding: 4px 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: center;
  margin: 0;
`

export const HelperText = styled(Text)`
  margin: 0;
  padding: 0;
  color: inherit;
  height: 40%;
  width: 100%;
  white-space: nowrap;
  font-size: 0.7rem;
`

export const Input = styled.input`
  width: 100%;
  height: 60%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  font-family: 'PayPalSansBig-Regular';
  font-size: 16px;
  color: ${(props) => props.theme.colors.primary.black};

  ::placeholder {
    color: ${(props) => props.theme.colors.primary.greymedium};
    font-size: 0.9rem;
  }
`

export const ApplyButton = styled.button`
  background-color: transparent;
  color: ${(props) => props.theme.colors.primary.white};
  padding: 0;
  margin: 0;
  text-align: center;
  padding: 0 1rem;
  line-height: 44px;
  border-color: transparent;
  transition: background-color 0.3s;

  :hover,
  :focus {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
    outline: 0;
  }
`
