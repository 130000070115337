import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import { useTheme } from '@emotion/react/macro'
import {
  system,
  compose,
  color,
  typography,
  space,
  layout,
  shadow,
} from 'styled-system'

const StyledText = styled('p')(
  { margin: 0 },
  system({
    textDecoration: true,
    textTransform: true,
  }),
  compose(color, typography, space, layout, shadow),
)

// eslint-disable-next-line react/display-name
export const Text = React.forwardRef(({ textStyle = 'p1', ...props }, ref) => {
  const theme = useTheme()

  return (
    <StyledText ref={ref} {...theme.textStyles[textStyle]} {...props}>
      {props.children}
    </StyledText>
  )
})

Text.propTypes = {
  textStyle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
