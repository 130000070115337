import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { Icon, LoadingSpinner } from '@paypalcorp/pp-react'
import { Text } from '../text'
import { Message } from '@paypalcorp/worldready-react'

import _ from 'lodash'

import * as Styled from './SearchInput.styled'

const SearchInput = ({
  id,
  placeholder,
  onSubmit,
  value,
  submitLabel,
  noShadow,
  inNav,
  hasSubmitButton = false,
  enableSeeYourLocation,
  currentLocationLabel,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [query, setQuery] = useState(value)
  const [locationAccess, setLocationAccess] = useState('')
  const [useCurrentLocation, setUseCurrentLocation] = useState(false)
  const inputEl = useRef(null)

  useEffect(() => setQuery(value), [value])

  const getCurrentLocation = () => {
    setLocationAccess('LOADING')

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setQuery({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
        setUseCurrentLocation(true)
        setLocationAccess('SUCCESS')
        if (inputEl.current) {
          inputEl.current.blur()
        }
      },
      (err) => {
        console.error(
          `Failed to get browser location. Err: ${JSON.stringify(err)}`,
        )
        setLocationAccess('ERROR')
      },
      { timeout: 5000 },
    )
  }

  const getInputValue = () => {
    if (useCurrentLocation && locationAccess === 'SUCCESS') {
      return currentLocationLabel
    }
    return query
  }

  const handleFocus = () => {
    if (useCurrentLocation) {
      setQuery('')
      setUseCurrentLocation(false)
    }
    setIsFocused(true)
  }

  const handleBlur = () => setIsFocused(false)

  const handleSubmit = () => onSubmit(query)

  const handleEnter = (ev) => {
    if (ev.key.toLowerCase() === 'enter' && typeof onSubmit === 'function')
      handleSubmit()
  }

  const formInputId = id ? `${id}-search` : 'search'
  return (
    <Styled.SearchContainer
      isFocused={isFocused}
      noShadow={noShadow}
      className={inNav ? 'in-nav' : ''}
    >
      <Styled.InputContainer
        as="label"
        htmlFor={formInputId}
        hasSubmitButton={hasSubmitButton}
      >
        <Icon name="search" size={inNav ? 'sm' : 'md'} />
        <input
          type="text"
          id={formInputId}
          name="search"
          label={placeholder}
          aria-label={placeholder}
          placeholder={placeholder}
          onChange={(ev) => setQuery(ev.target.value)}
          value={getInputValue()}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyPress={handleEnter}
          ref={inputEl}
        />
        <span style={{ display: 'none' }}>{placeholder}</span>
        {enableSeeYourLocation &&
          (locationAccess === 'LOADING' ? (
            <span className="location-icon">
              <LoadingSpinner />
            </span>
          ) : (
            <Icon
              name="location"
              size={inNav ? 'sm' : 'md'}
              className={`location-icon ${
                locationAccess === 'ERROR' ? 'location-icon--denied' : ''
              }`}
              onClick={getCurrentLocation}
            />
          ))}
      </Styled.InputContainer>
      {hasSubmitButton && (
        <Styled.SubmitButton as="button" onClick={handleSubmit}>
          <Text color="primary.white" textStyle="h4">
            {_.isEmpty(submitLabel) ? (
              <Message id="pages/search-bar.search.input" />
            ) : (
              submitLabel
            )}
          </Text>
        </Styled.SubmitButton>
      )}
    </Styled.SearchContainer>
  )
}

SearchInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  submitLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  noShadow: PropTypes.bool,
  value: PropTypes.string,
  inNav: PropTypes.bool,
  enableSeeYourLocation: PropTypes.bool,
  currentLocationLabel: PropTypes.string,
}

SearchInput.defaultProps = {
  id: '',
  placeholder: 'Search Gift Cards',
  submitLabel: '',
  onSubmit: () => null,
  noShadow: false,
  value: '',
  inNav: false,
  enableSeeYourLocation: false,
}

export default SearchInput
