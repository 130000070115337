import styled from '@emotion/styled/macro'
import { Box } from '../box'
import { Text } from '../text'

export const Eyebrow = styled(Text)`
  transform: translateY(10px);
  ${({ theme }) =>
    theme.inViewStyle({
      translateY: '10px',
      delay: 0,
      parent: true,
    })}
`

export const Title = styled(Text)`
  padding-right: 15px;
  ${({ theme }) =>
    theme.inViewStyle({
      translateY: '10px',
      delay: 100,
      parent: true,
    })}
`

export const Description = styled(Text)`
  ${({ theme }) =>
    theme.inViewStyle({
      translateY: '10px',
      delay: 200,
      parent: true,
    })}
`

export const ComponentWrapper = styled(Box)`
  ${({ theme }) =>
    theme.inViewStyle({
      translateY: '10px',
      delay: 300,
      parent: true,
    })}
`
