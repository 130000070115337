import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ProductDiv } from '../shared/styled'
import styled from '@emotion/styled/macro'
import { SuccessSvg } from '../shared/icons/svg-images'
import styledIcon from '../shared/icons'
import { graphql } from '@apollo/client/react/hoc'
import postCheckoutProcess from '../../graphql/postCheckoutProcess'
import { trackImpression } from '../../utils/analytics'
import URL from 'url-parse'
import { log } from '../../utils/error-tracking'
import trackCartEvent from '../../utils/track-cart-event'
import { flowRight as compose } from 'lodash'
import { Message } from '@paypalcorp/worldready-react'
import { Container, Button as PPButton } from '@paypalcorp/pp-react'

import SuccessMessage from './SuccessMessage'

const IconContainer = styled.div`
  padding-top: 48px;
  padding-bottom: 20px;
`

const SuccessICON = styledIcon`
  & svg {
    height: 102px;
    width: 102px;
    fill: red;
  }
`

SuccessICON.defaultProps = { svg: SuccessSvg }

const Content = styled.div`
  background-color: #ffffff;
  text-align: center;
  margin: 0 115px 0 117px;

  @media only screen and (max-width: 768px) {
    margin: 0 12px;
  }
`

const Button = styled(PPButton)`
  margin-bottom: 16px;
  width: 270px;

  @media only screen and (max-width: 768px) {
    width: 271px;
  }
`

export class Success extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    postCheckoutProcess: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      error: false,
    }
  }

  componentDidMount() {
    const paymentInfo = this.getPaymentInfo()

    // redirect back to home if user came directly to page
    if (!paymentInfo.payerId || !paymentInfo.paymentId) {
      this.props.history.replace('/')
      return
    }

    const params = new URL(window.location, true).query
    const mfKey = params.mfKey || localStorage.getItem('cart_id')

    this.props
      .postCheckoutProcess({
        variables: { paymentInfo, mfKey },
      })
      .then(({ data }) => {
        if (data.postCheckoutProcess.userCountryAllowedForTxn === false) {
          this.redirectToXBorderError(paymentInfo.payerId)
          return
        }

        if (data.postCheckoutProcess.isPurchaseLimitExceeded) {
          this.redirectToPromotionError(paymentInfo.payerId)
          return
        }

        if (data.postCheckoutProcess.velocityLimitErrors) {
          this.redirectToVelocityError(
            paymentInfo.payerId,
            data.postCheckoutProcess.velocityLimitErrors,
          )
          return
        }

        if (!data.postCheckoutProcess.isExecutePaymentSuccess) {
          this.redirectToPaymentError(paymentInfo.payerId)
          return
        }

        if (!data.postCheckoutProcess.isOrderSuccess) {
          this.logTxnIdRedirectToError(
            paymentInfo.payerId,
            data.postCheckoutProcess.transaction.txnId,
          )

          return
        }

        this.orderReference = data.postCheckoutProcess.transaction.txnId

        this.setState({
          error: false,
          loading: false,
        })

        trackImpression(
          'main:consumer:gifts:cart:',
          'main:consumer:gifts:cart:success:::',
          {
            txn_id: this.orderReference || null,
            entry_point: 'PPDG_STOREFRONT',
          },
        )

        trackCartEvent('purchase')

        return
      })
      .catch((reason) => {
        log({
          file: 'success',
          err: {
            stack: `Exception in execute payment and create order for payerId ${
              paymentInfo.payerId
            }, redirect to error page. Error : ${JSON.stringify(reason)}`,
          },
        })

        this.props.history.replace('/error')
      })
  }

  getPaymentInfo = () => {
    const params = new URL(window.location, true).query
    const payerId = params.PayerID
    const paymentId = params.paymentId
    const paymentInfo = {
      payerId,
      paymentId,
    }
    return paymentInfo
  }

  redirectToXBorderError = (payerId) => {
    log({
      file: 'success',
      err: {
        stack: `Failed in execute payment for payerId ${payerId} due to x-border`,
      },
    })
    this.props.history.replace('/txnerror')
  }

  redirectToPromotionError = (payerId) => {
    log({
      file: 'success',
      err: {
        stack: `Purchase limit exceeded for ${payerId}, redirect to promotion error page`,
      },
    })
    this.props.history.replace('/promotionerror')
  }

  redirectToVelocityError = (payerId, velocityLimitErrors) => {
    log({
      file: 'success',
      err: {
        stack: `Velocity purchase limit exceeded for ${payerId}, redirect to velocity error page`,
      },
    })
    this.props.history.replace('/txnerror', { velocityLimitErrors })
  }

  redirectToPaymentError = (payerId) => {
    log({
      file: 'success',
      err: {
        stack: `Failed in execute payment for payerId ${payerId}, redirect to error page`,
      },
    })
    this.props.history.push('/paymenterror')
  }

  logTxnIdRedirectToError = (payerId, txnId) => {
    log({
      file: 'success',
      err: {
        stack: `Failed in create order for payerId ${payerId}, txnId: ${txnId}, redirect to error page`,
      },
    })
    this.props.history.push('/error')
  }

  handleContinue = () => {
    this.props.history.push('/all')
  }

  redirectToSendNRequest = () => {
    window.location.replace('/myaccount/transfer/homepage')
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          data-testid="loading"
          className="vx_has-spinner-large"
          style={{ paddingTop: '400px' }}
        />
      )
    }
    if (this.state.error) {
      return <Redirect to="/error" />
    }

    return (
      <Container id="success-page">
        <ProductDiv>
          <Content id="content">
            <IconContainer id="icon">
              <SuccessICON />
            </IconContainer>
            <SuccessMessage orderNumber={this.orderReference} />
            <Button
              secondary
              name="SuccessDoneButton"
              data-testid="success-done-button"
              onClick={this.handleContinue}
            >
              <Message id="pages/success.success.done" />
            </Button>
          </Content>
        </ProductDiv>
      </Container>
    )
  }
}

const SuccessWithMutation = compose(
  graphql(postCheckoutProcess, {
    name: 'postCheckoutProcess',
  }),
)(Success)

export default SuccessWithMutation
