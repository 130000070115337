import styled from '@emotion/styled/macro'
import { Box } from '../box'
import { Icon } from '@paypalcorp/pp-react'

export const Deal = styled(Box)`
  color: ${(props) => props.theme.colors.primary.bluemedium};
`

export const DealText = styled.div`
  overflow: hidden;
  color: ${(props) => props.theme.colors.primary.black};

  button {
    background: transparent;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.primary.bluemidnight};
  }
`

export const DealIcon = styled(Icon)`
  float: left;
  margin-top: 0.25rem;
  margin-right: 0.5rem;
`

export const TermsBody = styled.div`
  font-size: 13px;
  color: #9da3a6;
`
