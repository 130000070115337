import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useApolloClient, gql } from '@apollo/client'
import { TextInput } from '@paypalcorp/pp-react'
import { Box } from '../box'
import { HiddenInput } from '../../utils/styled'
import { Message } from '@paypalcorp/worldready-react'

// eslint-disable-next-line react/display-name
const FormTextInput = React.forwardRef((props, ref) => {
  const { label, name, data, inValid, multiline } = props
  const [value, setValue] = useState('')
  const [error, handleError] = useState(false)
  const client = useApolloClient()

  const handleOnChange = (event) => {
    setValue(event.target.value)
  }

  const handleOnBlur = (event) => {
    if (!error) {
      client.writeQuery({
        query: gql`
          query getCachedData {
            ${data} @client
          }
        `,
        data: { [data]: event.target.value },
      })
    }
  }

  useEffect(() => {
    handleError(inValid)
  }, [value, inValid])

  return (
    <Box pb={3}>
      <TextInput
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        placeholder={label}
        multiline={multiline}
        name={name}
        label={label}
        styles={{
          placeholder: {
            top: '14px',
          },
        }}
      />
      {error && (
        <div style={{ color: 'red' }}>
          <span className=" vx_icon vx_icon-medium vx_icon-critical-xsmall" />
          <Message id="pages/product.product.invalidEmail" />
        </div>
      )}
      <HiddenInput
        tabIndex="-1"
        as={multiline ? 'textarea' : 'input'}
        ref={ref}
        value={value}
        name={name}
        readOnly
      />
    </Box>
  )
})

FormTextInput.propTypes = {
  label: PropTypes.string,
  data: PropTypes.string,
  inValid: PropTypes.bool,
  multiline: PropTypes.bool,
  name: PropTypes.string,
}

FormTextInput.defaultProps = {
  multiline: false,
}

export default FormTextInput
