import lodash from 'lodash'
import { useQuery } from '@apollo/client'
import getAllProducts from '../../graphql/getAllProducts'

const stripQuery = (value) =>
  value.replace(/[.,/#!$%^*;:{}=\-_`~()'"]/g, '').trim()

const useAllProductsSearch = (searchQuery) => {
  const { data, loading, error } = useQuery(getAllProducts)

  const allProducts = lodash.get(data, 'getAllProducts.all_a_z')

  if (loading || error) return { loading, error }

  const searchTokens = stripQuery(searchQuery)
    .split(/\s/)
    .filter((token) => token.length > 0)

  if (!searchTokens.length)
    return {
      products: [],
      loading,
      error,
    }

  const getSearchScore = (string) => {
    const matchingTokens = searchTokens.filter(
      (token) => string.toLowerCase().indexOf(token.toLowerCase()) >= 0,
    )
    return matchingTokens.length / searchTokens.length
  }

  const scoreProduct = (product) => {
    let matches =
      getSearchScore(product.name) + getSearchScore(product.keywords)

    product.categories.forEach((category) => {
      matches += getSearchScore(category)
    })

    return matches
  }

  return {
    error,
    loading,
    products: allProducts
      .filter((product) => scoreProduct(product) > 0)
      .sort((a, b) => scoreProduct(a) - scoreProduct(b)),
  }
}

export default useAllProductsSearch
