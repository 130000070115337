import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-hook-inview'
import { useTheme } from '@emotion/react/macro'

import { Container, Col, Row } from '../grid'

import * as Styled from './ContentBlock.styled'
import { Deal } from '../deal'
import { ImageLazy } from '../image-lazy'

const RIGHT = 'right'
const LEFT = 'left'
const CENTER = 'center'

const ContentBlock = (props) => {
  const {
    header,
    eyebrowComponent,
    body,
    terms,
    ctaComponent,
    img,
    alt,
    variant,
    ...rest
  } = props

  const [ref, inView] = useInView({
    threshold: 0.25,
    unobserveOnEnter: true,
  })

  const theme = useTheme()

  const imageFirst = variant === LEFT || variant === CENTER
  const isStacked = variant === CENTER

  return (
    <Container {...rest}>
      <Row>
        <Col
          order={{ _: 0, md: imageFirst ? 0 : 1 }}
          xs="12"
          lg={isStacked ? '12' : '8'}
        >
          <ImageLazy
            borderRadius={{ _: 0, md: '0.24rem' }}
            mx={{ _: '-1.5rem', md: 0 }}
            width="100%"
            height="auto"
            src={img}
            alt={alt}
          />
        </Col>
        {isStacked && <Col xs="0" md="2" lg="3" />}
        <Col
          order={{ _: 1, lg: imageFirst ? 1 : 0 }}
          xs="12"
          md="8"
          lg={isStacked ? 6 : 4}
        >
          <Styled.TextColumn
            display="flex"
            flexDirection="column"
            ref={ref}
            className={inView ? theme.inViewClass : ''}
          >
            <Styled.Headline
              as="h2"
              textStyle="h2"
              mt={isStacked ? 'x2' : { _: 'x2', lg: '0' }}
              mb="x2"
            >
              {header}
            </Styled.Headline>
            {!!eyebrowComponent && (
              <Styled.Eyebrow display="flex" width="100%">
                {eyebrowComponent}
              </Styled.Eyebrow>
            )}
            <Styled.Description textStyle="p1">{body}</Styled.Description>
            <Styled.CtaWrapper maxWidth="100%" display="flex">
              {ctaComponent}
            </Styled.CtaWrapper>
            <Styled.OfferWrapper>
              <Deal
                offer={{
                  name,
                  terms,
                }}
                cta="See Terms"
                noIcon
              />
            </Styled.OfferWrapper>
          </Styled.TextColumn>
        </Col>
      </Row>
    </Container>
  )
}

ContentBlock.propTypes = {
  alt: PropTypes.string,
  header: PropTypes.string.isRequired,
  eyebrowComponent: PropTypes.element,
  body: PropTypes.string.isRequired,
  terms: PropTypes.string.isRequired,
  ctaComponent: PropTypes.element,
  img: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([RIGHT, LEFT, CENTER]),
}

export default ContentBlock
