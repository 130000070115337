import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import getAllProducts from '../../graphql/getAllProducts'
import { Box } from '../../components/box'
import { Row, Col } from '../../components/grid'
import { Modal, useModal } from '../../components/modal'
import { GiftCard } from '../../components/gift-card'
import { SectionIntro } from '../../components/section-intro'
import { Loader } from '../../components/loader'
import { filterBundles } from '../../utils/cardFilters'
import { useTrackImpression } from '../../utils/hooks/useTrackImpression'
import { handleSessionError } from '../../utils/error-tracking'
import CardDetails from './CardDetails'
import { bundleCartProps } from '../../utils/hooks/useBundleCart'
import { Message } from '@paypalcorp/worldready-react'

const stripQuery = (value) =>
  value.replace(/[.,/#!$%^*;:{}=\-_`~()'"]/g, '').trim()

const CardToggle = ({ card, cart }) => {
  const [details, toggleModal] = useModal(false)

  return (
    <React.Fragment>
      <Box onClick={toggleModal} mb="x4">
        <GiftCard
          img={card.img_url}
          label={card.name}
          name="GiftCard"
          data-pagename="main:consumer:gifts:byob:"
          data-pagename2={`main:consumer:gifts:byob:${card.url_key}:::`}
          style={{ cursor: 'pointer' }}
        />
      </Box>
      {details ? (
        <Modal isOpen={details} handleClose={toggleModal}>
          <CardDetails
            urlKey={card.url_key}
            onClick={toggleModal}
            cart={cart}
          />
        </Modal>
      ) : null}
    </React.Fragment>
  )
}

CardToggle.propTypes = {
  card: PropTypes.object,
  cart: bundleCartProps,
}

const CustomBundles = ({ cart, filter }) => {
  const { loading, error, data } = useQuery(getAllProducts)

  useTrackImpression(
    'main:consumer:gifts:byob:',
    'main:consumer:gifts:byob::::',
  )

  if (loading) return <Loader py="100px" />

  if (error) {
    handleSessionError(error)
    return <Redirect to="/error" />
  }

  const { all_a_z } = data.getAllProducts

  const queryTokens = stripQuery(filter)
    .split(/\s/)
    .filter((token) => token.length > 0)

  const getQueryScore = (string) => {
    const matchingTokens = queryTokens.filter(
      (token) => string.toLowerCase().indexOf(token.toLowerCase()) >= 0,
    )
    return matchingTokens.length / queryTokens.length
  }

  const scoreSku = (sku) => {
    let matches = getQueryScore(sku.name) + getQueryScore(sku.keywords)
    sku.categories.forEach((category) => {
      matches += getQueryScore(category)
    })
    return matches
  }

  let filteredCards = all_a_z

  if (queryTokens.length > 0) {
    filteredCards = filteredCards
      .filter((card) => scoreSku(card) > 0)
      .sort((a, b) => scoreSku(a) - scoreSku(b))
  }

  const popularCards = filteredCards
    .filter(filterBundles)
    .filter((card) => card.categories.indexOf('Popular') >= 0)
  const allCards = filteredCards
    .filter(filterBundles)
    .filter((bundle) => bundle.categories.indexOf('Popular') < 0)

  return (
    <Fragment>
      {!!popularCards.length && (
        <Fragment>
          <Row>
            <Col>
              <SectionIntro
                title={
                  <Message id="pages/custom-bundles.section.intro.popular" />
                }
                eyebrow={
                  <Message id="pages/custom-bundles.section.intro.eyebrow" />
                }
                mb="4"
              />
            </Col>
          </Row>
          <Row>
            {popularCards.map((card) => (
              <Col key={`card-toggle-${card.id}`} xs={12} md={3}>
                <CardToggle card={card} cart={cart} />
              </Col>
            ))}
          </Row>
        </Fragment>
      )}
      {allCards.length ? (
        <Fragment>
          <Row>
            <Col>
              <SectionIntro
                title={<Message id="pages/custom-bundles.section.intro.all" />}
                eyebrow={
                  <Message id="pages/custom-bundles.section.intro.eyebrow" />
                }
                mb="4"
              />
            </Col>
          </Row>
          <Row>
            {allCards.map((card) => (
              <Col key={`card-toggle-${card.id}`} xs={12} md={3}>
                <CardToggle card={card} cart={cart} />
              </Col>
            ))}
          </Row>
        </Fragment>
      ) : (
        'No Cards Found'
      )}
    </Fragment>
  )
}

CustomBundles.propTypes = {
  filter: PropTypes.string,
  cart: bundleCartProps,
}

export { CustomBundles }
