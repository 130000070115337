/**
 * Grabs the data we dump from the server into the DOM and exports them as a singleton.
 * This allows us to conveniently dump server data into the dom and use this to load
 * that data into our app.
 */
// because the way webpack is implemented, you can import this file anywhere
// in the codebase like so:
// import clientData from 'utils/client-data'
import debug from 'debug'
import { WorldReady } from '@paypalcorp/worldready'

const clientData = {}
function init() {
  const dataNode = document.getElementById('client-data')
  const clientDataString = (dataNode && dataNode.innerHTML) || '{}'

  try {
    Object.assign(clientData, JSON.parse(clientDataString))

    if (process.env.NODE_ENV !== 'test') {
      // paypal-scripts will load worldReady with content automatically during tests
      WorldReady.load(clientData.worldReady)
    }
    debug('ppdg:client-data:success')('load', clientData)
  } catch (error) {
    debug('ppdg:client-data:error')('load', error)
  }
}

export default clientData
export { init }
