import React from 'react'
import Error from './common'
import PropTypes from 'prop-types'
import { trackImpression } from '../../utils/analytics'
import { Message } from '@paypalcorp/worldready-react'

class PromotionError extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  componentDidMount() {
    trackImpression(
      'main:consumer:gifts:home:promotionerrorpage',
      'main:consumer:gifts:home:promotionerrorpage:::',
    )
  }

  render() {
    const errorText = [
      <Message id="pages/error.error.limitExceed.header" key={0} />,
      <Message id="pages/error.error.limitExceed.message" key={1} />,
    ]
    return (
      <Error
        history={this.props.history}
        promotionError={errorText}
        buttonText={<Message id="pages/error.error.limitExceed.btn" />}
        routeTo="/all"
      />
    )
  }
}

export default PromotionError
