import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Container, Col } from '../../components/grid'
import clientData from '../../utils/client-data'
import { useCurrentBreakpoint } from '../../utils/hooks/useCurrentBreakpoint'
import { useTheme } from '@emotion/react/macro'
import { useInView } from 'react-hook-inview'

import * as Styled from './GuideDetail.styled'

import { Box } from '../../components/box'
import { HeroHeader } from '../../components/hero-header'
import GuideDetailItem from './GuideDetailItem'
import { useTrackImpression } from '../../utils/hooks/useTrackImpression'
import { MessageFormat } from '@paypalcorp/worldready'
import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react'

const elmo = clientData.experimentation
const GuideDetail = (props) => {
  const { id, brands, isStoreVoucher, headerImage, headerImage_m } = props

  const theme = useTheme()
  const [ref, inView] = useInView({
    threshold: 0.25,
    unobserveOnEnter: true,
  })

  const currentBreakpoint = useCurrentBreakpoint()
  const worldready = useContext(WorldReadyContext)
  const heroImage = currentBreakpoint < 1 ? headerImage_m : headerImage //mobile or desktop image

  useTrackImpression(
    'main:consumer:gifts:guidedetail:',
    `main:consumer:gifts:guidedetail:${id}:::`,
  )

  const renderGuideContent = () => {
    return brands
      .filter(({ condition }) => !condition || condition(elmo))
      .map((brand, i) => (
        <GuideDetailItem
          id={id}
          key={`guide-item-${i}`}
          brand={brand}
          isStoreVoucher={isStoreVoucher}
        />
      ))
  }

  return (
    <section>
      <HeroHeader
        headerStyle="editorial"
        imgUrl={heroImage}
        eyebrow={<Message id={`gift-guides/${id}.section.hero.eyebrow`} />}
        headline={<Message id={`gift-guides/${id}.section.hero.headline`} />}
      />

      <Styled.IntroWrapper
        mt="x1"
        ref={ref}
        className={inView ? theme.inViewClass : ''}
      >
        <Container>
          <Styled.CenteredRow>
            <Col md={6}>
              <Box textStyle="p1" mt="x4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: new MessageFormat(worldready, {
                      id: `gift-guides/${id}.section.intro`,
                    }).format(),
                  }}
                />
              </Box>
            </Col>
          </Styled.CenteredRow>
        </Container>
      </Styled.IntroWrapper>

      <Box>{renderGuideContent()}</Box>
    </section>
  )
}

GuideDetail.propTypes = {
  id: PropTypes.string,
  brands: PropTypes.array,
  isStoreVoucher: PropTypes.bool,
  headerImage: PropTypes.string,
  headerImage_m: PropTypes.string,
}

export default GuideDetail
