import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled/macro'
import { trackBackButton } from '../../utils/analytics'

const PageWrapperEl = styled.div`
  background: white;

  main {
    display: flex;
    flex-flow: column;
    align-items: stretch;
  }
`

const PageWrapper = ({ children, history }) => {
  useEffect(() => {
    const unlisten = history.listen((_, state) => {
      if (state.toLowerCase() === 'pop') {
        trackBackButton()
      }
    })

    return () => {
      unlisten()
    }
  })

  window.scrollTo(0, 0)

  return <PageWrapperEl className="ppdg-wrapper">{children}</PageWrapperEl>
}

PageWrapper.propTypes = {
  children: PropTypes.array,
  history: PropTypes.object,
}

const PageWrapperWithRouter = withRouter(PageWrapper)
export { PageWrapperWithRouter as PageWrapper }
