import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { FlexBox } from '../box'
import { Text } from '../text'
import * as Styled from './TitleTicker.styled'

const TRANSITION_STATES = ['ready', 'out', 'in', 'static']

const TitleTicker = ({ staticText, textItems, delay, ...rest }) => {
  const [animState, setAnimState] = useState({
    itemIndex: 0,
    transitionIndex: 0,
  })

  const currentTimeout = useRef(false)
  const sizeRef = useRef()
  const staticRef = useRef()

  const numItems = textItems.length
  const { itemIndex, transitionIndex } = animState

  const doRotate = () => {
    const nextIndex = (itemIndex + 1) % numItems
    setAnimState({
      itemIndex,
      transitionIndex: 1,
    })
    currentTimeout.current = setTimeout(() => {
      setAnimState({
        itemIndex: nextIndex,
        transitionIndex: 2,
      })

      currentTimeout.current = setTimeout(() => {
        setAnimState({
          itemIndex: nextIndex,
          transitionIndex: 3,
        })

        currentTimeout.current = setTimeout(() => {
          setAnimState({
            itemIndex: nextIndex,
            transitionIndex: 0,
          })
        }, delay - 450)
      }, 300)
    }, 150)
  }

  useEffect(() => {
    if (transitionIndex === 0) doRotate()
  })

  useEffect(() => {
    return () => {
      if (currentTimeout.current) {
        clearTimeout(currentTimeout.current)
        currentTimeout.current = false
      }
    }
  }, [])

  if (!numItems) return null

  return (
    <Styled.TitleWrapper as="h1" className="ticker" {...rest}>
      <FlexBox flexDirection="row" justifyContent="center">
        <FlexBox flexDirection={{ _: 'column', sm: 'row' }}>
          <Text as="span" ref={staticRef} textStyle="hero">
            {staticText}&nbsp;
          </Text>
          {sizeRef.current && (
            <Styled.AnimText
              as="span"
              animState={TRANSITION_STATES[transitionIndex]}
              textStyle="hero"
              minWidth={`${sizeRef.current.clientWidth}px`}
            >
              {textItems[itemIndex]}
            </Styled.AnimText>
          )}
        </FlexBox>
      </FlexBox>
      <Styled.RefText textStyle="hero" ref={sizeRef}>
        {textItems.sort((a, b) => b.length - a.length)[0]}
      </Styled.RefText>
    </Styled.TitleWrapper>
  )
}

TitleTicker.propTypes = {
  staticText: PropTypes.string,
  textItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  delay: PropTypes.number,
}

TitleTicker.defaultProps = {
  delay: 2000,
}

export default TitleTicker
