import { gql } from '@apollo/client'

export default gql`
  query getHomepageData($urlKey: String!) {
    getLandingData {
      deals {
        name
        terms
        img_url
        url_key
        carousel_option
        banner_url
        mobile_banner_url
        end_time
        is_marketing_promotion
      }
      layout {
        banner {
          image
          tracking {
            impression
            click
          }
        }
      }
    }
    getProductsInCategory(urlKey: $urlKey) {
      category_name
      all_a_z {
        id
        img_url
        name
        url_key
        categories
        keywords
        description
      }
    }
  }
`
