import styled from '@emotion/styled/macro'

export const GiftCardsContent = styled.div`
  max-width: 736px;
  margin: auto;
`

export const ImageContent = styled.div`
  display: inline-block;
  max-width: 912px;
`

export const ProductDiv = styled.div`
  margin: 36px auto;
  max-width: 800px;
  background-color: #ffffff;
`
