import { CurrencyFormat } from '@paypalcorp/worldready'
import { fractionDisplay } from './fraction-display'
import _ from 'lodash'

export const customAmountField = (customAmountsWithSku, worldready) => {
  const { minAmount, maxAmount } = customAmountsWithSku.customAmount

  const formatedMinAmount = new CurrencyFormat(worldready, {
    currency: minAmount.currency_code,
    maximumFractionDigits: fractionDisplay(minAmount.value),
  }).format(minAmount.value / 100)

  const formatedMaxAmount = new CurrencyFormat(worldready, {
    currency: maxAmount.currency_code,
    maximumFractionDigits: fractionDisplay(maxAmount.value),
  }).format(maxAmount.value / 100)

  const customAmount = {
    sku: customAmountsWithSku.sku,
    minAmount: formatedMinAmount,
    maxAmount: formatedMaxAmount,
  }

  return customAmount
}

export const getCustomAmountRange = (variants) => {
  // only one custom price variant
  const customPriceVariant = _.find(variants, {
    price: { type: 'custom_price' },
  })
  if (!customPriceVariant) {
    return null
  }
  const {
    minimum_price_amount: minAmount = { currency_code: 'USD', value: '0' },
    maximum_price_amount: maxAmount = { currency_code: 'USD', value: '0' },
    step_price_amount: stepPriceAmount,
  } = customPriceVariant.price

  const customAmountWithSku = {
    sku: customPriceVariant.sku,
    customAmount: { minAmount, maxAmount, stepPriceAmount },
  }

  return customAmountWithSku
}
