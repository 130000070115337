import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled/macro'
import stripHtmlComments from 'strip-html-comments'
import getProductDetails from '../../graphql/getProductDetails'
import { Button } from '../../components/button'
import { Box } from '../../components/box'
import { Text } from '../../components/text'
import { Deal } from '../../components/deal'
import { CustomAmountInput } from '../../components/pdp-form'
import { getCustomAmountRange } from '../../utils/getCustomAmountRange'
import { getFixedDisplayAmounts } from '../../utils/getFixedAmounts'
import { HiddenInput } from '../../utils/styled'
import { GiftCard } from '../../components/gift-card'
import _ from 'lodash'
import { Loader } from '../../components/loader'
import { bundleCartProps } from '../../utils/hooks/useBundleCart'
import {
  Message,
  ContextConsumer as WorldReadyContextConsumer,
} from '@paypalcorp/worldready-react'

const Styled = {}

Styled.CardDetails = styled(Box)`
  position: relative;
  width: 100%;
  min-width: 320px;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 420px;
  }
`

Styled.CardImage = styled.img`
  display: block;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1), 0 6px 22px rgba(0, 0, 0, 0.1);
`

Styled.Overlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 5000;
`

const CardDetails = ({ cart, urlKey, onClick }) => {
  const [selectedVariant, pickVariant] = useState({})
  const [alreadyInBundle, setAlreadyInBundle] = useState(false)
  const [showCustomAmount, setShowCustomAmount] = useState('')
  const [customAmount, setCustomAmount] = useState({
    currency_code: 'USD',
    value: 0,
  })
  const { updating } = cart
  const { loading, error, data } = useQuery(getProductDetails, {
    variables: { urlKey },
  })

  if (loading)
    return (
      <Styled.CardDetails p="x3">
        <Loader mt="25px" px="100px" py="100px" />
      </Styled.CardDetails>
    )
  if (error) return `Error! ${error.message}`

  const { name, img_url, description, variants, terms_and_conditions } =
    data.getProductDetails

  const customAmounts = getCustomAmountRange(variants)
  const promos = []

  variants.forEach((variant) => {
    promos.push(...variant.promotions)
  })

  const promotions = _.uniqBy(promos, 'id')
  const promosOnProductPage = _.filter(promotions, { is_sold_out: false })

  async function handleOnClick() {
    if (selectedVariant) {
      if (!selectedVariant.fixedAmount) {
        selectedVariant.fixedAmount = customAmount
      }

      const card = {
        url_key: urlKey,
        sku: selectedVariant.sku,
        name,
        img_url,
        terms_and_conditions,
        promotions,
        customAmount,
      }

      cart.add(card)
      onClick()
    }
  }

  function handleClickButton(amount) {
    if (inCart(amount.sku)) {
      setAlreadyInBundle(true)
      return
    }

    pickVariant(amount)
    setAlreadyInBundle(false)

    // Set customAmount to `0` if fixed price variant
    setCustomAmount({
      currency_code: 'USD',
      value: 0,
    })
  }

  function handleClickOther(customAmt) {
    if (inCart(customAmt.sku)) {
      setAlreadyInBundle(true)
      return
    }

    pickVariant(customAmt)
    setAlreadyInBundle(false)
    setShowCustomAmount(true)
  }

  function inCart(sku) {
    let isInCart = false
    // Check if SKU is already in cart
    for (let i = 0; i < cart.items.length; i += 1) {
      if (sku === cart.items[i].sku) {
        // resolve true if SKU exists in cart
        isInCart = true
      }
    }

    return isInCart
  }

  return (
    <WorldReadyContextConsumer>
      {(worldready) => {
        const displayAmounts = getFixedDisplayAmounts(variants, worldready)

        return (
          <React.Fragment>
            <Styled.CardDetails p="x3">
              <Text textStyle="h3" mb="x5">
                {name}
              </Text>
              <Box px="2">
                <GiftCard img={img_url} label={name} />
              </Box>
              <Text textStyle="p2" mt="x5">
                <span
                  dangerouslySetInnerHTML={{
                    __html: stripHtmlComments(description),
                  }}
                />
              </Text>
              {promosOnProductPage ? (
                <Box my="x3">
                  {promosOnProductPage.map((offer) => (
                    <Deal
                      key={offer.id}
                      offer={offer}
                      cta={<Message id="pages/product.product.offerTerms" />}
                    />
                  ))}
                </Box>
              ) : null}
              <div>
                {displayAmounts.map((amount) => (
                  <Button
                    variant="price"
                    as="label"
                    selected={amount.sku === selectedVariant.sku}
                    onClick={() => handleClickButton(amount)}
                    key={amount.sku + amount.displayAmount}
                  >
                    <span>{amount.displayAmount}</span>
                    <HiddenInput
                      type="radio"
                      name={`radios-${urlKey}`}
                      disabled={inCart(amount.sku)}
                    />
                  </Button>
                ))}
                {customAmounts ? (
                  <Button
                    variant="price"
                    as="label"
                    selected={customAmounts.sku === selectedVariant.sku}
                  >
                    <span>
                      <Message id="pages/product.product.other" />
                    </span>
                    <HiddenInput
                      type="radio"
                      name="amount"
                      id="_other"
                      value="other"
                      defaultChecked={customAmounts.sku === selectedVariant.sku}
                      onClick={() => handleClickOther(customAmounts)}
                    />
                  </Button>
                ) : null}
              </div>
              {showCustomAmount ? (
                <CustomAmountInput
                  customAmounts={selectedVariant}
                  customAmount={customAmount}
                  onApply={(value) => setCustomAmount(value)}
                />
              ) : null}
              <Box mt={2}>
                <Button
                  width="100%"
                  variant="secondary"
                  onClick={handleOnClick}
                  name="cart.add"
                  data-pagename="main:consumer:gifts:byob:"
                  data-pagename2={`main:consumer:gifts:byob:add:${selectedVariant.sku}::`}
                  disabled={
                    alreadyInBundle ||
                    updating ||
                    (showCustomAmount && customAmount.value === 0) ||
                    !selectedVariant.sku
                  }
                >
                  {updating ? (
                    <Message id="pages/custom-bundles.cart.adding" />
                  ) : (
                    <Message id="pages/custom-bundles.cart.add" />
                  )}
                </Button>
                {alreadyInBundle && (
                  <Text color="primary.red" mt="x1">
                    <Message id="pages/custom-bundles.cart.added" />
                  </Text>
                )}
              </Box>
            </Styled.CardDetails>
            {updating && (
              <Styled.Overlay>
                <Box m="auto">
                  <Loader />
                  <Text textStyle="h5" mt="x10">
                    <Message id="pages/custom-bundles.cart.updating" />
                  </Text>
                </Box>
              </Styled.Overlay>
            )}
          </React.Fragment>
        )
      }}
    </WorldReadyContextConsumer>
  )
}

CardDetails.propTypes = {
  urlKey: PropTypes.string,
  onClick: PropTypes.func,
  cart: bundleCartProps,
}

export default CardDetails
