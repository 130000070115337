import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import styledIcon from '../shared/icons'
import { SuccessPopupSvg, AlertPopupSvg } from '../shared/icons/svg-images'
import getStoreErrorMsg from '../../graphql/getStoreErrorMsg'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import { Message } from '@paypalcorp/worldready-react'
import { Button as PPButton } from '@paypalcorp/pp-react'

const PopupDiv = styled.div`
  position: fixed;
  bottom: 0;
  margin: 0;
  height: ${(props) => {
    if (!props.showPopup) return 0
    if (props.status === 'error') return '390px'
    return '366px'
  }};
  width: 100%;
  z-index: 999;
  transition: 0.3s ease-in-out;
`

const PopupContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  height: 100%;
  text-align: center;
  background-image: linear-gradient(287deg, #009cde, #003087);
`

const Content = styled.div`
  margin: 0 121px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    margin: 0 24px;
  }
`

const StyledMessage = styled.div`
  text-align: center;
  color: white;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
  margin-bottom: 12px;
`

const ErrorMessage = styled.div`
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  line-height: 1.33;
`

const CheckoutButton = styled(PPButton)`
  width: 270px;
  height: 48px;
  background: white;
  margin: 12px 0 12px 0;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;

  @media only screen and (max-width: 768px) {
    width: 271px;
  }

  &:hover {
    background: white;
  }
`

const ContinueButton = styled(PPButton)`
  width: 270px;
  height: 48px;
  border-radius: 50px;
  border-color: white;
  background-color: transparent;
  margin: 12px 0 12px 0;
  margin-left: 0;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;

  @media only screen and (max-width: 768px) {
    width: 271px;
  }
`

const SuccessICON = styledIcon`
  & svg {
    height: 102px;
    width: 102px;
  }
`

const AlertICON = styledIcon`
  & svg {
    height: 98px;
    width: 98px;
  }
`

SuccessICON.defaultProps = { svg: SuccessPopupSvg }
AlertICON.defaultProps = { svg: AlertPopupSvg }

const IconContainer = styled.div`
  padding-top: 45px;
  padding-bottom: 21px;
  width: 120px;
  margin: 0 auto;
`

const Popup = (props) => {
  const storeErrorMsg = _.get(props.data, 'getStoreInfo.store_error_msg')
  return (
    <PopupDiv
      showPopup={props.showPopup}
      status={props.status}
      data-testid="product-popup"
    >
      <PopupContainer>
        {props.status === 'loading' && (
          <IconContainer
            style={{ paddingTop: '119px' }}
            id="icon"
            data-testid="checkout-loading"
          >
            <div className="vx_spinner-large" />
          </IconContainer>
        )}
        {props.status === 'done' && (
          <div>
            <IconContainer id="icon">
              <SuccessICON />
            </IconContainer>
            <Content>
              <StyledMessage>
                <Message id="pages/product.product.itemAdded" />
              </StyledMessage>
              <div>
                <CheckoutButton
                  secondary
                  onClick={props.handleCheckout}
                  name="Checkout"
                  data-testid="checkout-button"
                  data-pagename={props.pagename}
                  data-pagename2={props.pagename2}
                >
                  <Message id="pages/product.product.checkout" />
                </CheckoutButton>
              </div>
              <div>
                <ContinueButton
                  onClick={props.handleContinueShopping}
                  name="Continue"
                  data-testid="continue-shopping-button"
                  data-pagename={props.pagename}
                  data-pagename2={props.pagename2}
                >
                  <Message id="pages/product.product.continueShopping" />
                </ContinueButton>
              </div>
            </Content>
          </div>
        )}
        {props.status === 'error' && (
          <div>
            <IconContainer id="icon">
              <AlertICON />
            </IconContainer>
            <Content>
              <StyledMessage style={{ marginBottom: '24px' }}>
                <Message id="pages/error.error.header" />
              </StyledMessage>
              <ErrorMessage>
                {storeErrorMsg ? (
                  <div>{storeErrorMsg}</div>
                ) : (
                  <div>
                    <Message id="pages/error.error.text" />
                  </div>
                )}
              </ErrorMessage>
              <ErrorMessage>
                <Message id="pages/error.error.charges" />
              </ErrorMessage>
              <CheckoutButton
                secondary
                style={{ marginTop: '24px' }}
                onClick={props.handleContinueShopping}
                name="CheckoutError"
                data-pagename={props.pagename}
                data-pagename2={props.pagename2}
              >
                <Message id="pages/product.product.errorButton" />
              </CheckoutButton>
            </Content>
          </div>
        )}
      </PopupContainer>
    </PopupDiv>
  )
}

Popup.propTypes = {
  showPopup: PropTypes.bool,
  handleCheckout: PropTypes.func,
  handleContinueShopping: PropTypes.func,
  status: PropTypes.string.isRequired,
  data: PropTypes.shape({
    getStoreErrorMsg: PropTypes.shape({
      store_error_msg: PropTypes.string,
    }),
  }),
  pagename: PropTypes.string,
  pagename2: PropTypes.string,
}

const PopupWithGraphql = compose(withApollo, graphql(getStoreErrorMsg))(Popup)

export default PopupWithGraphql
