import React from 'react'
import { Route } from 'react-router-dom'
import ProductPage from './screens/product-page'
import clientData from './utils/client-data'

const paths = clientData.productPaths
export default () => [
  paths.map((path, idx) => (
    <Route path={path} key={idx} component={ProductPage} />
  )),
]
