import './init'
import App from './app'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { analyticsInit } from './utils/analytics'
import { ContextProvider as WorldReadyContextProvider } from '@paypalcorp/worldready-react'
import clientData from './utils/client-data'

analyticsInit(clientData)
let rootElement = document.getElementById('root')

if (!rootElement) {
  rootElement = document.createElement('div')
  document.body.appendChild(rootElement)
}

const root = createRoot(rootElement)

root.render(
  <WorldReadyContextProvider locale={clientData.locality.locale}>
    <Router basename={clientData.requestURI}>
      <App />
    </Router>
  </WorldReadyContextProvider>,
)
