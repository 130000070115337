import styled from '@emotion/styled/macro'

export const FormToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const FormToggleButton = styled.label`
  color: ${(props) =>
    props.isActive
      ? props.theme.colors.primary.bluemedium
      : props.theme.colors.secondary.greymedium};

  width: 50%;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  text-align: center;

  border: 0;
  background: transparent;
  outline: none;
  border-bottom: 1px solid
    ${(props) =>
      props.isActive
        ? props.theme.colors.primary.bluemedium
        : props.theme.colors.secondary.greymedium};

  padding-bottom: 10px;
  margin-bottom: 24px;

  cursor: ${(props) => (props.isActive ? 'default' : 'pointer')};
`

export const InputsContainer = styled.div`
  div {
    margin-bottom: 10px;
  }
`
