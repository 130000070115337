/* global paypal */
import { v4 as uuidv4 } from 'uuid'
import clientData from './client-data'
// TODO: Fix dependency cycle
import { log } from './error-tracking' // eslint-disable-line import/no-cycle

// Constants
const PPDG = 'ppdgcartinfo'
const DURATION = 10800000 // 3 hours

let tracker

const logError = (err) => {
  log({
    file: 'track-cart-event',
    err: {
      stack: `Exception in tracking cart event. Stack: ${err.stack}`,
      message: `Exception in tracking cart event. Message: ${err.message}`,
    },
  })
}

// eslint-disable-next-line max-statements
const _trackCartEvent = (
  cartId,
  cartEvent,
  updatedCart = [],
  newPayload = {},
) => {
  if ((clientData.locality.country || '').toUpperCase() !== 'US') return null

  if (!tracker) {
    tracker = paypal.Tracker() // eslint-disable-line new-cap
    tracker.setPropertyId(clientData.tagmanagerContainerId)
  }

  if (cartEvent === 'view') {
    // Parameter Overloading:
    //     updatedCart actually just stores view event data, newPayload stores JL payload
    return tracker.track(cartEvent, { ...updatedCart, payload: newPayload })
  }

  if (cartEvent === 'cancelCart') {
    localStorage.removeItem(PPDG)
    return tracker.track('cancelCart', { cartId })
  }

  if (cartEvent === 'purchase') {
    try {
      const cartsData = JSON.parse(localStorage.getItem(PPDG) || '{}')
      if (!cartsData || !cartsData.cartId) {
        return null
      }
      let payload = (cartsData.carts || []).map((item = {}) => ({
        count: item.quantity,
        deal_id: item.sku,
        price: Number(item.amountToPay.value) / 100,
      }))
      if (payload.length === 1) {
        payload = payload[0]
      }
      tracker.track(cartEvent, { cartId: cartsData.cartId, payload })
    } catch (err) {
      logError(err)
    }
    localStorage.setItem(PPDG, '')
    return null
  }

  const items = updatedCart.map((payload) => {
    const amountObj = payload.amountToPay || {}
    const amount = Number(amountObj.value) || 0
    return {
      id: payload.sku,
      title: payload.productName,
      url: `https://paypal.com/us/gifts/${payload.urlKey}`,
      description: payload.description,
      imgUrl: payload.imgUrl,
      price: amount / 100,
      quantity: payload.quantity,
    }
  })

  const cartTotal = updatedCart.reduce((sum, item) => {
    const totalPriceInCents = Number(item.amountToPay.value)
    return Number(totalPriceInCents / 100, 10) * item.quantity + sum
  }, 0)

  const jlPayload = items[items.length - 1]
  const cartEventPayload = {
    count: jlPayload.quantity,
    deal_id: jlPayload.id,
    price: jlPayload.price,
  }

  return tracker.track(cartEvent, {
    cartId,
    items,
    cartTotal,
    payload: cartEventPayload,
  })
}

const trackCartEvent = (cartEvent, updatedCart, newPayload) => {
  // localStorage must exist so we can accurately keeep track of cartId. Otherwise, there is no point
  if (!localStorage || !localStorage.getItem) return null

  // set cartId
  let cartId = uuidv4()
  try {
    let storedData = JSON.parse(localStorage.getItem(PPDG) || '{}')

    // Set cartId if data does not exist or last event was > 3 hours
    if (
      !storedData ||
      !storedData.cartId ||
      Date.now() - parseInt(storedData.lastUpdated || 0, 10) > DURATION
    ) {
      localStorage.setItem(
        PPDG,
        JSON.stringify({ cartId, lastUpdated: Date.now() }),
      )
    } else {
      cartId = storedData.cartId
    }
  } catch (err) {
    logError(err)
  }

  if (cartEvent === 'preparepurchase') {
    try {
      localStorage.setItem(
        PPDG,
        JSON.stringify({ cartId, carts: updatedCart, lastUpdated: Date.now() }),
      )
    } catch (ex) {
      logError(ex)
    }
    return null
  }

  try {
    _trackCartEvent(cartId, cartEvent, updatedCart, newPayload)
  } catch (ex) {
    logError(ex)
  }
  return null
}

export const pageView = () => {
  window.paypalDDL = []
  window.paypalDDL.push({
    document: new Date().getTime(),
    event: 'snippetRun',
  })
  window.PPMS = (e, d) => {
    window.paypalDDL.push({ event: e, data: d })
  }
  // loads tagmanager script tag but checks if it has already been loaded
  // so it deletes before re-loading so that analytics can get pageViews correctly
  const tagManagerScriptTag = document.querySelector('#tagManagerScriptTag')
  if (tagManagerScriptTag) {
    tagManagerScriptTag.remove()
  }
  const e = document.createElement('script')
  e.id = 'tagManagerScriptTag'
  e.async = true
  e.src =
    'https://www.paypal.com/tagmanager/pptm.js?id=' +
    clientData.tagmanagerContainerId +
    '&m=paypalDDL'
  document.head.prepend(e)
}

export default trackCartEvent
