import styled from '@emotion/styled/macro'

export const Modal = styled.div`
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  overflow: hidden;
  transition: opacity 500ms ease;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    justify-content: center;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 3rem;
  }
`

export const ModalContent = styled.div`
  position: relative;
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  opacity: 1;
  margin: auto;
`

export const ModalCloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    top: 1rem;
    right: 1rem;
  }
`

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
`
