import React from 'react'
import PropTypes from 'prop-types'
import BundleCard from './BundleCard'
import getProductDetails from '../../graphql/getProductDetails'
import { useQuery } from '@apollo/client'
import { Loader } from '../loader'

const BundleCardWithData = ({ mini, urlKey }) => {
  const { data, loading, error } = useQuery(getProductDetails, {
    variables: {
      urlKey,
    },
  })

  if (loading) return <Loader py="100px" />
  if (error) return null

  const productDetails = data.getProductDetails
  const variant = productDetails.variants[0]
  const bundle_img_url = variant.img_url || productDetails.img_url
  const price = variant.price
  const children = variant.children

  return (
    <BundleCard
      {...productDetails}
      bundle_img_url={bundle_img_url}
      price={price}
      url_key={urlKey}
      showDescription={mini ? false : true}
      children={children}
    />
  )
}

BundleCardWithData.propTypes = {
  mini: PropTypes.bool,
  urlKey: PropTypes.string.isRequired,
}

export default BundleCardWithData
