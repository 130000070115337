import styled from '@emotion/styled/macro'

import { Box } from '../box'

export const VerticalGiftList = styled.div``

export const Header = styled(Box)`
  padding: 1rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary.greylight};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  a {
    display: flex;
    justify-content: space-between;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
`

export const TextContainer = styled.div`
  flex-basis: 50%;
  ${({ theme }) => theme.inViewStyle({ translateY: '10px', parent: true })}
`

export const ImageContainer = styled(Box)`
  flex-direction: row;
  justify-content: flex-end;

  ${({ theme }) =>
    theme.inViewStyle({ translateY: '10px', delay: 100, parent: true })}

  img {
    display: block;
    border-radius: ${(props) => props.theme.borderRadius};
    width: 8rem;
    height: auto;
    object-fit: cover;
    object-position: center;
  }
`
