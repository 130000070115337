import { gql } from '@apollo/client'

export default gql`
  query getProductDetails($urlKey: String!) {
    getProductDetails(urlKey: $urlKey) {
      name
      img_url
      description
      banner
      variants {
        sku
        price {
          type
          fixed_price_amount {
            value
            currency_code
          }
          minimum_price_amount {
            currency_code
            value
          }
          maximum_price_amount {
            currency_code
            value
          }
          step_price_amount
        }
        img_url
        promotions {
          id
          name
          description
          terms
          is_sold_out
        }
        children {
          name
          sku
          price {
            type
            fixed_price_amount {
              value
              currency_code
            }
            minimum_price_amount {
              currency_code
              value
            }
            maximum_price_amount {
              currency_code
              value
            }
            step_price_amount
          }
          img_url
          promotions {
            id
            name
            description
            terms
            is_sold_out
          }
        }
      }
      terms_and_conditions
      tags
    }
  }
`
