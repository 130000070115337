import React, { useState, useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Icon } from '@paypalcorp/pp-react'

import * as Styled from './Modal.styled'

const propTypes = {
  targetElement: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

function useScrollLock(bool) {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    if (bool) document.body.style.overflow = 'hidden'
    return () => (document.body.style.overflow = originalStyle)
  }, [])
}

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen(!isOpen)
  }

  return [isOpen, toggle]
}

const Modal = ({
  children,
  isOpen,
  handleClose,
  targetElement = document.body,
}) => {
  useScrollLock(isOpen)

  if (targetElement === undefined) {
    return null
  }

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Styled.Modal>
          <Styled.ModalOverlay onClick={() => handleClose()} />
          <Styled.ModalContent>
            {children}
            <Styled.ModalCloseButton onClick={() => handleClose()}>
              <Icon name="close" />
            </Styled.ModalCloseButton>
          </Styled.ModalContent>
        </Styled.Modal>,
        targetElement,
      )}
    </React.Fragment>
  )
}

Modal.propTypes = propTypes

// export const ModalTransition = styled(Modal)`
//   opacity: 0;
//   &.${({ className }) => className}-enter-done {
//     opacity: 1;
//   }
//   &.${({ className }) => className}-enter-active {
//     opacity: 1;
//     transition: opacity 500ms ease;
//   }
//   &.${({ className }) => className}-exit {
//     opacity: 1;
//   }
//   &.${({ className }) => className}-exit-active {
//     opacity: 0;
//     transition: opacity 500ms ease;
//   }
// `;

export default Modal
