import { gql } from '@apollo/client'

export default gql`
  mutation createPayment(
    $cartInfo: CartInfo!
    $stctype: String!
    $sessionData: SessionData
  ) {
    createPayment(
      cartInfo: $cartInfo
      stctype: $stctype
      sessionData: $sessionData
    ) {
      ecToken
      promotionSoldOutErrors {
        sku
      }
    }
  }
`
