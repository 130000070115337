import { gql } from '@apollo/client'

export default gql`
  query getAllProducts {
    getAllProducts {
      most_popular {
        id
        img_url
        name
        url_key
        categories
        keywords
        description
      }
      all_a_z {
        id
        img_url
        name
        url_key
        merchant_id
        categories
        keywords
        description
      }
    }
  }
`
