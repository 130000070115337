import styled from '@emotion/styled/macro'
import { Box } from '../box'

export const BgContainer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-out;
  transform: scale(1.15);

  video {
    object-fit: cover;
  }

  .animated-in & {
    opacity: 1;
    transform: scale(1);
  }
`
export const SearchHero = styled(Box)`
  background-color: ${(props) => props.theme.colors.secondary.white};
  text-align: center;
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.colors.secondary.greylight};
  position: relative;
  display: flex;
  flex-direction: column;
`

export const HeadlineContainer = styled(Box)`
  flex-grow: 1;
`

export const SearchContainer = styled(Box)`
  position: relative;
  align-self: flex-end;
  width: 100%;
  transition: 0.5s ease-out;
  transform: translateY(20px);
  transition-delay: 0.4s;

  .animated-in & {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const MainHeadlineContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  transition: 0.5s ease-out;
  transition-delay: 0.15s;
  transform: translateY(20px);

  .animated-in & {
    opacity: 1;
    transform: translateY(0px);
  }
`
