import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react/macro'
import { useInView } from 'react-hook-inview'

import { ImageLazy } from '../image-lazy'
import { Text } from '../text'
import { BannerGiftWrap } from '../banner-giftwrap'

import { giftGuidesPath } from '../../gift-guide-routes'
import { Message } from '@paypalcorp/worldready-react'

import * as Styled from './GuideCard.styled'

const GuideCard = ({ card, banner, showEyebrow }) => {
  const url = `${giftGuidesPath}${card.path}`

  const theme = useTheme()
  const [ref, inView] = useInView({
    threshold: 0.25,
    unobserveOnEnter: true,
  })

  return (
    <Styled.CardWrapper
      to={url}
      ref={ref}
      className={inView ? theme.inViewClass : ''}
    >
      <Styled.CardImage>
        <ImageLazy bgSrc={card.coverImage} alt="" />
        {banner && <BannerGiftWrap />}
      </Styled.CardImage>

      <Styled.CardInner>
        {showEyebrow && (
          <Text as="div" textStyle="eyebrow2" color="colors.primary.white">
            <Message id={`gift-guides/${card.id}.section.hero.eyebrow`} />
          </Text>
        )}
        <Styled.Headline as="h3" textStyle="h3" mt="x1">
          <Message id={`gift-guides/${card.id}.section.hero.headline`} />
        </Styled.Headline>
      </Styled.CardInner>
    </Styled.CardWrapper>
  )
}
GuideCard.propTypes = {
  card: PropTypes.object.isRequired,
  banner: PropTypes.bool,
  showEyebrow: PropTypes.bool,
}

GuideCard.defaultProps = {
  showEyebrow: false,
}

export default GuideCard
