import React from 'react'
import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker'
import './style.css'
import onClickOutside from 'react-onclickoutside'
import clientData from 'utils/client-data'

const { calendar } = clientData

class Calendar extends React.Component {
  static propTypes = {
    handleDayClick: PropTypes.func.isRequired,
    handleOnBlur: PropTypes.func,
  }

  state = {
    selectedDay: undefined,
  }
  handleClickOutside = () => {
    this.props.handleOnBlur()
  }

  handleDayFocus = (day, modifiers) => {
    // when using keyboard tab, default is focus on 1st day of month,
    // here we check if it's disabled, move focus to Today.
    if (modifiers.disabled) {
      document
        .querySelectorAll('div.DayPicker-Day.DayPicker-Day--today')[0]
        .focus()
    } else {
      this.setState({ selectedDay: day })
    }
  }

  handleDayClick = (day, disabled) => {
    this.setState({ selectedDay: day })
    this.props.handleDayClick(day, disabled)
  }

  render() {
    const past = {
      before: new Date(),
    }

    const todayStyle = `
    .DayPicker-Day--today {
      background-color: #0070ba;
      color: white;
      border-radius: 25px;
    }`

    const {
      months,
      weekdays: weekdaysLong,
      shortWeekdays: weekdaysShort,
      firstDayOfWeek,
    } = calendar

    return (
      <div style={{ width: '270px' }}>
        <div id="triangle" className="top-left" />
        <div style={{ marginTop: '16px' }}>
          <style>{todayStyle}</style>
          <DayPicker
            showOutsideDays
            disabledDays={past}
            onDayClick={this.handleDayClick}
            months={months}
            weekdaysLong={weekdaysLong}
            weekdaysShort={weekdaysShort}
            firstDayOfWeek={firstDayOfWeek}
            onDayFocus={this.handleDayFocus}
            selectedDays={this.state.selectedDay}
          />
        </div>
      </div>
    )
  }
}

export default onClickOutside(Calendar)
