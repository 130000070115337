import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@paypalcorp/pp-react'
import { Text } from '../text'
import _ from 'lodash'
import { customAmountField } from '../../utils/getCustomAmountRange'
import * as Styled from './CustomAmountInput.styled.js'
import { MessageFormat, CurrencyFormat } from '@paypalcorp/worldready'
import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react'

const validateAmount = (value, { customAmount }) => {
  if (
    value >= customAmount.minAmount.value &&
    value <= customAmount.maxAmount.value &&
    (value - customAmount.minAmount.value) % customAmount.stepPriceAmount === 0
  ) {
    return true
  }
  return false
}

const CustomAmountInput = ({ customAmounts, onApply }) => {
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(null)
  const [value, setValue] = useState(0)
  const worldready = useContext(WorldReadyContext)

  let stepAmount

  const formattedCustomAmounts = customAmountField(customAmounts, worldready)

  const amountInCent = Number(value) * 100

  const stepPriceAmount = _.get(customAmounts, 'customAmount.stepPriceAmount')
  if (stepPriceAmount) {
    const currency = _.get(
      customAmounts,
      'customAmount.minAmount.currency_code',
      'USD',
    )
    stepAmount = new CurrencyFormat(worldready, { currency }).format(
      stepPriceAmount / 100,
    )
  }

  const handleOnChange = (e) => {
    setError(false)
    setSuccess(null)
    setValue(e.target.value)
  }

  const handleClickApply = () => {
    const amount = {
      currency_code: customAmounts.customAmount.minAmount.currency_code,
      value: 0,
    }

    const isValid = validateAmount(amountInCent, customAmounts)

    if (isValid) {
      amount.value = amountInCent
    } else {
      amount.value = 0
    }

    onApply(amount)
    setError(!isValid)
    setSuccess(isValid ? true : null)
  }

  return (
    <div>
      <Styled.Container error={error} success={success !== null}>
        <Styled.InputContainer
          as="label"
          flexGrow={3}
          id="amount-field"
          data-testid="amount-field"
        >
          <Styled.HelperText textStyle="p1">Custom Amount</Styled.HelperText>
          <Styled.Input
            required
            type="text"
            pattern="^[1-9][0-9]*$"
            placeholder={new MessageFormat(worldready, {
              id: 'pages/product.product.amountPlaceHolder',
            }).format({
              minAmount: formattedCustomAmounts.minAmount,
              maxAmount: formattedCustomAmounts.maxAmount,
            })}
            onChange={handleOnChange}
          />
        </Styled.InputContainer>
        <Styled.InputContainer width={48}>
          {success ? <Icon name="check-circle" /> : ''}
          {error ? <Icon name="critical-alt" /> : ''}
        </Styled.InputContainer>
        <Styled.ApplyButton
          onClick={handleClickApply}
          data-testid="apply-button"
        >
          <Message id="pages/product.product.applyBtn" />
        </Styled.ApplyButton>
      </Styled.Container>
      {error && (
        <Text color="primary.red" mt="x1">
          {stepAmount ? (
            <Message
              id="pages/product.product.amountErrorStepAmount"
              stepAmount={stepAmount}
            />
          ) : (
            <Message id="pages/product.product.amountError" />
          )}
        </Text>
      )}
    </div>
  )
}

CustomAmountInput.propTypes = {
  customAmount: PropTypes.object,
  customAmounts: PropTypes.object,
  onApply: PropTypes.func,
}

export default CustomAmountInput
