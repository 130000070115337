import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-hook-inview'
import { useTheme } from '@emotion/react/macro'

import * as Styled from './SectionIntro.styled'

import { Box } from '../box'

const SectionIntro = ({ title, eyebrow, component, description, ...rest }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    unobserveOnEnter: true,
  })

  const theme = useTheme()

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      {...rest}
      ref={ref}
      className={inView ? theme.inViewClass : ''}
    >
      <Box display="flex" flexDirection="column">
        {!!eyebrow && (
          <Styled.Eyebrow
            as="div"
            mb="x1"
            textStyle="eyebrow"
            color="secondary.greymidnight"
          >
            {eyebrow}
          </Styled.Eyebrow>
        )}
        {!!title && (
          <Styled.Title as="h2" p="0" textStyle="h2">
            {title}
          </Styled.Title>
        )}
        {!!description && (
          <Styled.Description
            mt="x1"
            pt="0"
            color="secondary.greymidnight"
            textStyle="p1"
          >
            {description}
          </Styled.Description>
        )}
      </Box>
      <Styled.ComponentWrapper
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        {component}
      </Styled.ComponentWrapper>
    </Box>
  )
}

SectionIntro.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  eyebrow: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  component: PropTypes.element,
  description: PropTypes.string,
}

export default SectionIntro
