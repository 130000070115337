import React from 'react'
import Error from './common'
import PropTypes from 'prop-types'
import { trackImpression } from '../../utils/analytics'
import { Message } from '@paypalcorp/worldready-react'

class PaymentError extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  componentDidMount() {
    trackImpression(
      'main:consumer:gifts:home:paymenterrorpage',
      'main:consumer:gifts:home:paymenterrorpage:::',
    )
  }

  render() {
    return (
      <Error
        history={this.props.history}
        title={<Message id="pages/error.error.payment.header" />}
        paymentErrors={[
          <Message id="pages/error.error.payment.message" key={0} />,
        ]}
        buttonText={<Message id="pages/error.error.payment.btn" />}
        routeTo="/cartsummary"
      />
    )
  }
}

export default PaymentError
