import React, { useState } from 'react'
import TextLinkToggleUI from './text-link-toggle.component'

const TextLinkToggle = ({ linkTitle, htmlTextBody, titleStyle = {} }) => {
  const [expandText, setExpandText] = useState(false)

  const toggleExpandText = () => setExpandText((prevState) => !prevState)

  const handleKeyPress = (event) => {
    if (event.key === ' ' || event.key === 'Enter') {
      // Prevent the default action to stop scrolling when space is pressed
      event.preventDefault()
      toggleExpandText()
    }
  }

  return (
    <TextLinkToggleUI
      expandText={expandText}
      handleClick={toggleExpandText}
      handleKeyPress={handleKeyPress}
      linkTitle={linkTitle}
      htmlTextBody={htmlTextBody}
      titleStyle={titleStyle}
    />
  )
}

export default TextLinkToggle
