import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker'
import clientData from '../../utils/client-data'

import * as Styled from './Calendar.styled'

const { calendar } = clientData

const Calendar = ({ handleDayClick, handleOnBlur }) => {
  const [selectedDay, setSelectedDay] = useState()

  const handleDayFocus = (day, modifiers) => {
    // when using keyboard tab, default is focus on 1st day of month,
    // here we check if it's disabled, move focus to Today.
    if (modifiers.disabled) {
      document
        .querySelectorAll('div.DayPicker-Day.DayPicker-Day--today')[0]
        .focus()
    } else setSelectedDay(day)
  }

  const onDayClick = (day, disabled) => {
    setSelectedDay(day)
    handleDayClick(day, disabled)
  }

  const past = { before: new Date() }

  const {
    months,
    weekdays: weekdaysLong,
    shortWeekdays: weekdaysShort,
    firstDayOfWeek,
  } = calendar

  const ref = useRef()

  const handleClickOutside = (ev) => {
    if (ref.current !== ev.target && !ref.current.contains(ev.target))
      handleOnBlur()
  }

  useEffect(() => {
    document.body.addEventListener('click', handleClickOutside)
    return () => document.body.removeEventListener('click', handleClickOutside)
  })

  return (
    <Styled.Wrapper ref={ref}>
      <Styled.DayPickerContainer>
        <DayPicker
          showOutsideDays
          disabledDays={past}
          onDayClick={onDayClick}
          months={months}
          weekdaysLong={weekdaysLong}
          weekdaysShort={weekdaysShort}
          firstDayOfWeek={firstDayOfWeek}
          onDayFocus={handleDayFocus}
          selectedDays={selectedDay}
        />
      </Styled.DayPickerContainer>
    </Styled.Wrapper>
  )
}

Calendar.propTypes = {
  handleDayClick: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func,
}

export default Calendar
