import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import addToCart from '../../graphql/addToCart'
import getMayflyData from '../../graphql/getMayflyData'
import getBundleItemsFromCache from '../../graphql/getBundleItemsFromCache'
import { persistBundle } from '../bundle'
import { log, handleSessionError } from '../error-tracking'

/**
 * @typedef {Object} BundleCartItem
 * @property {number} quantity
 * @property {string} productName
 * @property {string} productTermsAndConditions
 * @property {string} urlKey
 * @property {string} sku
 * @property {string} imgUrl
 * @property {Object} customAmount: CustomAmount
 * @property {Object} amountToPay: Amount
 * @property {Object} totalAmount: Amount
 * @property {Object[]} discounts: DiscountInput[]
 */

/**
 * useBundleCart hook for React.FunctionComponent
 * @returns {UseBundleHook}
 */

export const useBundleCart = () => {
  const { data, client, loading, error } = useQuery(getBundleItemsFromCache)
  const [addToBundle] = useMutation(addToCart)
  const [items, setItems] = useState([])
  const [updating, setUpdating] = useState(false)

  async function getBundleItemsFromMayfly() {
    // restore data from mayfly to in-memory cache
    const mfKey = localStorage.getItem('bundle_id')
    if (!mfKey) return null
    const mayflyData = await client
      .query({
        query: getMayflyData,
        variables: { mfKey },
      })
      .then((res) => {
        return res.data.getMayflyData
      })
      .catch(() => {
        log({
          file: 'cart-basket',
          err: {
            stack: `Exception when read cart info from Mayfly, mfKey: ${mfKey}`,
          },
        })
      })

    return mayflyData
  }

  async function updateBundle(existingCart, newItems) {
    try {
      await persistBundle(client, addToBundle, existingCart, newItems)
      setUpdating(false)
      return Promise.resolve(true)
    } catch (err) {
      setUpdating(false)
      handleSessionError(err)
      log({
        file: 'useBundleCart',
        err,
      })

      return Promise.resolve(false)
    }
  }

  useEffect(() => {
    getBundleItemsFromMayfly().then((bundleItems) => {
      client.writeQuery({
        query: getBundleItemsFromCache,
        data: { bundleItems },
      })
    })
  }, [])

  useEffect(() => {
    if (data.bundleItems) setItems(data.bundleItems)
  }, [data])

  /**
   * Adds items to bundle cart
   * @param {Object} card
   * @param {string} card.name name of gift card
   * @param {string} card.terms_and_conditions gift card terms
   * @param {string} card.url_key url of gift card, usually starts with /brands/*
   * @param {string} card.sku variant sku to add to cart
   * @param {string} card.img_url url of gift card image
   * @param {Object} card.customAmount custom amount for custom amount variants *required*
   * @param {number} card.customAmount.value custom amount value, shoule be 0 for fixed price variants
   * @param {number} card.customAmount.country_code country code for currenct, i.e. 'USD','DE', etc.
   * @returns {Promise}
   */
  async function add({
    name,
    terms_and_conditions,
    url_key,
    sku,
    img_url,
    customAmount,
  }) {
    const existingCart = items
    setUpdating(true)

    // construct payload for apollo client bundleItems
    let payload = {
      quantity: 1,
      sku,
      productName: name,
      productTermsAndConditions: terms_and_conditions,
      urlKey: url_key,
      imgUrl: img_url,
      customAmount,
      isGifting: true,
      recipientEmail: '',
      senderName: '',
      note: '',
      selectedDay: new Date().toString(),
    }

    const newItems = [payload]

    return updateBundle(existingCart, newItems)
  }

  /**
   * Remove item from bundle cart
   * @param {Object} item bundle item to be removed by sku
   */
  async function remove(item) {
    setUpdating(true)
    const filteredCart = data.bundleItems.filter(
      (bundleItem) => bundleItem.sku !== item.sku,
    )

    return updateBundle(filteredCart, [])
  }

  /**
   * Clear all items from bundle cart
   */
  async function clear() {
    return updateBundle([], [])
  }

  /**
   * Add total amountToPay of items in BundleCart
   * @readonly
   * @const {number}
   */
  const total = items.length
    ? items
        .map((item) => Number(item.amountToPay.value))
        .reduce((a, b) => a + b)
    : 0

  /**
   * @typedef {Object} UseBundleHook
   * @property {function} add Adds items to bundle cart
   * @property {function} remove Remove item from bundle cart
   * @property {function} clear Clear all items from bundle cart
   * @property {number} total Calculated total of all bundle items in cart
   * @property {BundleCartItem[]} items Array of items in bundle cart
   * @property {boolean} error Array of items in bundle cart
   * @property {boolean} loading Array of items in bundle cart
   * @property {boolean} updating Array of items in bundle cart
   */

  return {
    add,
    remove,
    clear,
    total,
    items,
    error,
    loading,
    updating,
  }
}

export const bundleCartProps = PropTypes.shape({
  add: PropTypes.func,
  remove: PropTypes.func,
  clear: PropTypes.func,
  total: PropTypes.number,
  items: PropTypes.array,
  error: PropTypes.object,
  loading: PropTypes.bool,
  updating: PropTypes.bool,
})
