import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-hook-inview'

import * as Styled from './ImageLazy.styled'

const ImageLazy = (props) => {
  const { src, alt, bgSrc, onLoaded, imageStyle, ...rest } = props

  const [loaded, setLoaded] = useState(false)

  const [ref, inView] = useInView(
    {
      rootMargin: '100px',
      unobserveOnEnter: true,
    },
    [src, bgSrc],
  )

  const onLoad = () => {
    setLoaded(true)
    if (onLoaded) onLoaded()
  }

  const loadBgImage = () => {
    const img = new Image()

    img.onload = () => {
      onLoad()
    }

    img.src = bgSrc
  }

  useLayoutEffect(() => {
    if (inView && !src && bgSrc) {
      //bg image doesn't have onload event to bind to, so load it into an image element first
      loadBgImage()
    }
  }, [inView])

  if (src) {
    return (
      <Styled.Image
        ref={ref}
        className={loaded ? 'loaded' : ''}
        src={inView ? src : ''}
        {...rest}
        onLoad={onLoad}
        alt={alt || ''}
      />
    )
  } else if (bgSrc) {
    return (
      <Styled.BgImageWrapper
        {...rest}
        ref={ref}
        className={loaded ? 'loaded' : ''}
        style={{
          backgroundImage: `url(${inView ? bgSrc : ''})`,
          ...imageStyle,
        }}
      />
    )
  }

  return null
}

ImageLazy.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  bgSrc: PropTypes.string,
  onLoaded: PropTypes.func,
  imageStyle: PropTypes.object,
}

export default ImageLazy
