import styled from '@emotion/styled/macro'
import { space, typography } from 'styled-system'
import { Text } from '../text'
import { Box } from '../box'

export const MediaWrapper = styled(Box)`
  ${({ theme }) => theme.inViewStyle({ parent: true })}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  flex-wrap: wrap;

  ${space}
  ${typography}
`
export const Eyebrow = styled(Text)`
  ${({ theme }) => theme.inViewStyle({ parent: true })}
`

export const Title = styled(Text)`
  color: ${(props) => props.theme.colors.primary.black};
  opacity: 0;

  /* l10n function wraps content in span */
  span {
    display: block;

    span {
      color: ${(props) => props.theme.colors.primary.bluemidnight};
      white-space: nowrap;
    }
  }
  ${({ theme }) => theme.inViewStyle({ delay: 100, parent: true })}
`

export const ButtonWrapper = styled(Box)`
  ${({ theme }) => theme.inViewStyle({ delay: 200, parent: true })}
`
