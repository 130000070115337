/**
 * @fileOverview Analytics – link and error tracking
 * @name Analytics Widget
 * @author dquock, jamuferguson
 *
 * This file is a wrapper around site catalyst and fpti
 * and allows us to easily fire off tracking calls to
 * both at the same time.
 *
 * Access FPTI performance info from window.PAYPAL.analytics.perf
 * https://dev.paypal.com/wiki/General/FPTI-PerformanceTracking
 *
 */

import log from 'loglevel'
import _ from 'lodash'

// TODO: Fix dependency cycle
import { pageView } from './track-cart-event' // eslint-disable-line import/no-cycle

let analytics = null

/*************
 * Public API
 *************/

/**
 * Set FPTI/SC variables and fire tracking beacons
 *
 * @param {String} linkName - The name of the link
 * @param {String} pageGroup - The page group (prev. pageName)
 * @param {String} pageName - The actual page name (prev. pageName2)
 * @param {String} trackType - i.e "link"
 * @param {String} transactionDetailsLinks - activity transaction details links
 */
export const trackLink = whenReady(
  (linkName, pageGroup, pageName, trackType, transactionDetailsLinks) => {
    const fpti = window.fpti || {}

    log.info('Recording analytics:', [
      linkName,
      pageGroup,
      pageName,
      trackType,
      transactionDetailsLinks,
    ])

    fpti.link = linkName
    fpti.pgrp = pageGroup
    fpti.page = pageName
    fpti.pglk = linkName && `${pageGroup}|${linkName}`

    clearErrors(fpti)

    try {
      if (trackType === 'link') {
        analytics.recordClick()
      } else {
        pageView()
        analytics.recordImpression()
      }
    } catch (exception) {
      log.info('Analytics Exception Found:', exception)
    }
  },
)

/**
 * Wrapper around trackLink that lets us send additional FPTI keys
 * @param linkName - the link name for the trackLink call
 * @param pageGroup - the page group for the trackLink call
 * @param {String} pageName - The actual page name (prev. pageName2)
 * @param data - key value pairs we want to send to FPTI then remove
 */
export const trackLinkWithData = whenReady(
  // eslint-disable-next-line max-params
  (linkName, pageGroup, pageName, data, trackType, transactionDetailsLinks) => {
    const fpti = window.fpti || {}

    // apply extra fields to window.fpti
    Object.assign(fpti, data)

    trackLink(linkName, pageGroup, pageName, trackType, transactionDetailsLinks)

    // remove those additional keys
    Object.keys(data).forEach((key) => {
      delete fpti[key]
    })
  },
)

/**
 * Link tracking
 * @param {Object} event - click event || the clicked jQuery object of the input used to submit a form
 * if event isn't defined or is null, explicitly specify params
 * @param {String} linkName - name attribute from the link
 * @param {String} pageGroup - data-pagename the link is going to
 * @param {String} pageName - data-pagename2 the link is going to
 * @param {String} trackType - page-level or not
 * @param {String} transactionDetailsLinks - activity transaction details links
 */
export const trackElement = whenReady(($element) => {
  const linkName =
    $element.getAttribute('name') ||
    $element.getAttribute('data-linkname') ||
    $element.innerHTML
  const trackType = $element.getAttribute('data-track-type') || 'link'

  const pageGroup = $element.getAttribute('data-pagename')
  const pageName =
    $element.getAttribute('data-pagename2') || `${pageGroup}:::${trackType}`

  const nextPageGroup = $element.getAttribute('data-nextpagegroup')
  const nextPageName = $element.getAttribute('data-nextpagename')

  const transactionDetailsLinks = $element.getAttribute(
    'data-transactiondetailslinks',
  )
  let extra = $element.getAttribute('data-extra')
  try {
    extra = JSON.parse(extra) || {}
  } catch (e) {
    extra = {}
  }

  // Don't want beacons firing without a destination
  if (pageGroup) {
    trackLinkWithData(
      linkName,
      pageGroup,
      pageName,
      extra,
      trackType,
      transactionDetailsLinks,
    )
  }

  // Don't want CPLTracking unless next pages are specified
  if (nextPageGroup) {
    startCPLTracking(nextPageGroup, nextPageName, extra)
  }
})

/**
 * Track Impression
 * Impression beacon will be fired when `trackType` is not specified
 * @param {string} error - The page name that fires impression beacon
 */
export const trackImpression = whenReady((pageGroup, pageName, data = {}) => {
  // linkName is not needed for impression
  trackLinkWithData('', pageGroup, pageName, data)
})

/**
 *  Tracks errors on the page such as on a form
 *  @param {Object} error - The object containing the props to set
 */
export const trackError = whenReady((pageGroup, pageName, options) => {
  const fpti = window.fpti || {}

  log.info('Recording analytics:', options)

  fpti.pgrp = pageGroup
  fpti.page = pageName

  // set error props
  fpti.erpg = options.message // error message
  fpti.erfd = options.fieldId // form field with error (field1|field2|field3)
  fpti.eccd = options.code // error code

  analytics.recordImpression()
})

/**
 * Log client-side JS errors
 * @param err {Error} A valid JS Error (sometimes null watch out)
 * @param source {string} filename/line/col "file.js 23:39"
 */
export const trackJSError = whenReady((err, source) => {
  if (!window.PAYPAL.analytics) {
    return
  }
  const fpti = window.fpti || {}
  const data = {
    page: fpti.page,
    pgrp: fpti.pgrp,
    comp: fpti.comp,
    erpg: (err && err.message) || 'Script error',
    // uicomp: '',
    // error_code: '',
    error_type: 'WINDOW_ONERROR', // TODO: unique one for promise errors?
    error_description: getShortStack(err),
    error_source: source,
  }
  log.info('Logging JS Error', data)

  // see: https://engineering.paypalcorp.com/confluence/display/FPTI/Client-Side+JavaScript+Implementation#Client-SideJavaScriptImplementation-SubmittingClient-sideerrors
  analytics.logActivity(data)
})

/**
 * Log instances of missing content
 * @param missingData {object} e.g. { key: 'transfer/recipient/header.title', url: 'myaccount/transfer/send' }
 */
export const trackMissingContent = whenReady((missingContentData) => {
  if (!window.PAYPAL.analytics) {
    return
  }
  const fpti = window.fpti || {}
  const data = {
    page: fpti.page,
    pgrp: fpti.pgrp,
    comp: fpti.comp,
    erpg: `Missing content for key: ${missingContentData.key}`,
    error_type: 'MISSING_CONTENT',
    error_description: JSON.stringify(missingContentData),
    error_source: missingContentData.url,
  }
  log.info('Logging instance of missing content', data)

  // see: https://engineering.paypalcorp.com/confluence/display/FPTI/Client-Side+JavaScript+Implementation#Client-SideJavaScriptImplementation-SubmittingClient-sideerrors
  analytics.logActivity(data)
})

/**
 *  Tracks link on a page
 *  keeps the pagenames the same, but tracks the link
 *  @param {String} linkName linkName to track
 */
export const trackAction = whenReady((linkName) => {
  const fpti = window.fpti || {}
  log.info('Recording analytics:', linkName)

  // set linkname
  fpti.link = linkName
  fpti.pglk = `${fpti.pgrp}|${linkName}`

  clearErrors(fpti)
  analytics.recordClick()
})

/**
 * Simple back button tracking
 */
export function trackBackButton() {
  trackAction('back')
}

/**************
 * Private API
 **************/

/**
 * Get a reasonably short stack trace so we don't hit issues
 * with going over the limit of chars allowed in an FPTI GET
 */
function getShortStack(err) {
  const stack = err && err.stack

  // Tries to remove the http://www.paypalobjects.com/ stuff from the stack traces.
  const shortStack =
    (stack && stack.replace(/http.*\/(\w+.\.js)/g, '$1').slice(0, 500)) || ''
  return shortStack
}

// clear out error props, in case they exist
function clearErrors(fpti) {
  fpti.erpg = undefined // error message
  fpti.erfd = undefined // form field with error (field1|field2|field3)
  fpti.eccd = undefined // error code
}

/**
 * Updating name with new environment
 * @param {String} env - new environment set by external flow.
 * @param {String} name - pageGroup or pageName
 */
export function getPageNameForEnvironment(name, env) {
  if (!env) {
    return name
  }

  const nameArray = name.split(':')
  nameArray[2] = env
  return nameArray.join(':')
}

/**
 * PA.js has a 500ms delay between its inception and setup initialization, and we need to know when
 * the setup is really complete before firing our analytic calls. PA.js allows us to overwrite their
 * noop setupComplete function with our own function, which we do in pageTracking.dust -
 * Our logic simply specifies that when setupComplete is invoked, set _.isReady to true.
 */
function analyticsReady(callback) {
  const analyticsIsReady = _.get(window.PAYPAL, 'analytics._isReady')
  if (!analytics) {
    analytics = new window.PAYPAL.analytics.Analytics()
  }
  if (analyticsIsReady) {
    return callback()
  }
  return setTimeout(() => analyticsReady(callback), 150)
}

/**
 * Wrap any anonymous function in the analyticsReady function
 */
function whenReady(fn) {
  return (...args) => analyticsReady(() => fn(...args))
}

/**************
 * Clientside performance tracking (CPL)
 **************/

// Currently, end tracking is called where getting the page name is not easy
// Page names need to be passed to track impressions. This is a hack grabbing the page name
// when start tracking is called and setting to last lastPageName in order to pass it to endCPLTracking.
export function startCPLTracking(pageGroup, pageName, data = {}) {
  if (_.get(window.PAYPAL, 'analytics.startCPLTracking')) {
    let options = {}
    const { ulData: { fnSessionId: sessionID = '' } = {} } = window.PAYPAL
    if (pageName && pageGroup) {
      options = { flid: sessionID, pgrp: pageGroup, page: pageName }
    }
    // creating this key on window.PAYPAL.analytics to store our beacon info
    // becauase we call endCPLTracking with no arguments
    window.PAYPAL.analytics.beaconTrackingData = data
    window.PAYPAL.analytics.startCPLTracking(options)
  }
}

// Sometimes we don't have the data necessary for the next page's impression,
// because startCPLTracking begins at the time of click from a prior page.
export function addCPLData(data) {
  if (_.get(window.PAYPAL, 'analytics.beaconTrackingData')) {
    window.PAYPAL.analytics.beaconTrackingData = {
      ...window.PAYPAL.analytics.beaconTrackingData,
      ...data,
    }
  }
}

// Sets beacon data points directly on fpti - data points will persist until overwritten
export function setPersistentBeaconData(key, value) {
  if (window.fpti) {
    window.fpti[key] = value
  }
}

export function endCPLTracking(pageGroup, pageName, _data = {}) {
  // End tracking is only called if start tracking has been called. `cpl` and `cpl.started` are
  // only available after start tracking call.
  const CPLTrackingHasStarted = _.get(window.PAYPAL, 'analytics.cpl.started')
  const endCPLTrackingExists = _.get(window.PAYPAL, 'analytics.endCPLTracking')
  if (CPLTrackingHasStarted && endCPLTrackingExists) {
    const beaconTrackingData = window.PAYPAL.analytics.beaconTrackingData // our personal beacon storage created in startCPLTracking
    const beaconData = window.PAYPAL.analytics.cpl.beaconData || {} // Where the beacons are fired from
    window.PAYPAL.analytics.cpl.beaconData = {
      ...beaconData,
      ...beaconTrackingData,
    }

    let options = {}
    const { ulData: { fnSessionId: sessionID = '' } = {} } = window.PAYPAL
    if (pageName && pageGroup) {
      options = {
        pageData: { flid: sessionID, pgrp: pageGroup, page: pageName },
      }
    }
    window.PAYPAL.analytics.endCPLTracking(options)

    // We add data to our tracking calls, but CPL persisted the data by dropping it on window.FPTI
    // We don't want ALL previous tracking data on all future calls, so let's pluck out the data we just used
    window.fpti = _.pickBy(window.fpti, function (value, key) {
      const addedBeaconKeys = Object.keys(beaconTrackingData)
      return !addedBeaconKeys.includes(key)
    })
  }
}

export function analyticsInit(clientData) {
  setPersistentBeaconData('comp', 'ppdgnodeweb')
  setPersistentBeaconData('ccpg', _.get(clientData, 'locality.country'))
  setPersistentBeaconData('locale', _.get(clientData, 'locality.culture'))
  setPersistentBeaconData('cust', _.get(clientData, 'userId'))
  setPersistentBeaconData('cnac', _.get(clientData, 'userCountry'))
}
