import React from 'react'
import PropTypes from 'prop-types'

import { EditorialHero, SearchHero } from '../hero'

const HeroHeader = props => {
  const { headerStyle } = props

  switch (headerStyle) {
    case 'editorial':
      return <EditorialHero {...props} />

    case 'search':
      return <SearchHero {...props} />
    default:
      return null
  }
}

HeroHeader.propTypes = {
  headerStyle: PropTypes.string,
}

export default HeroHeader
