export const VIDEO_EXTENSIONS = ['mp4', 'webm', 'ogg']
export const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'gif', 'png', 'webp', 'svg']
export const VIDEO = 'video'
export const IMAGE = 'image'
export const TYPES = [VIDEO, IMAGE]

export const getExtension = asset => {
  const extension = asset.split('.').pop()
  return extension.split('?')[0].toLowerCase()
}

export const getMediaType = asset => {
  const extension = getExtension(asset)

  if (IMAGE_EXTENSIONS.indexOf(extension) > -1) return IMAGE
  if (VIDEO_EXTENSIONS.indexOf(extension) > -1) return VIDEO
  return null
}
