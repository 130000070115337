import React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from '@emotion/react/macro'
import { useInView } from 'react-hook-inview'
import { ImageLazy } from '../image-lazy'
import { Button } from '../button'
import { Text } from '../text'
import * as Styled from './GiftCallout.styled'

const GiftCallout = ({
  img,
  primaryText,
  secondaryText,
  buttonText,
  url,
  ...props
}) => {
  const theme = useTheme()
  const [ref, inView] = useInView({
    threshold: 0.25,
    unobserveOnEnter: true,
  })

  return (
    <Styled.Wrapper ref={ref} className={inView ? theme.inViewClass : ''}>
      <Styled.CalloutInfo>
        <Text textStyle="p1" fontWeight="700">
          {primaryText}
        </Text>
        {!!secondaryText && (
          <Text textStyle="p2" color="secondary.greymedium">
            {secondaryText}
          </Text>
        )}
        <Button
          variant="calloutCta"
          label={buttonText}
          href={url}
          mt="x1"
          {...props}
        />
      </Styled.CalloutInfo>
      <Styled.CalloutImage
        flexBasis={{ _: '45%', sm: '130px' }}
        maxWidth="130px"
      >
        <ImageLazy src={img} />
      </Styled.CalloutImage>
    </Styled.Wrapper>
  )
}

GiftCallout.propTypes = {
  img: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default GiftCallout
