import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import { withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'

const ImgDiv = styled.div`
  position: relative;
  margin: 0 24px 48px;
  width: 256px;
  height: 256px;

  @media only screen and (max-width: 768px) {
    margin: 0 2px 12px 2px;
    width: 169px;
    height: 169px;
  }
`

const NameDiv = styled.div`
  position: absolute;
  bottom: 12px;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 24px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`

const Img = styled.img`
  width: 100%;
`

const Display = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const EmptyItem = styled.div`
  margin: 0 24px;
  width: 256px;
  height: 0;

  @media only screen and (max-width: 768px) {
    margin: 0 2px;
    width: 169px;
  }
`

class ImageList extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
  }

  constructor(props, context) {
    super(props, context)
  }

  render() {
    return (
      <Display id="image-list">
        {this.props.items &&
          this.props.items.map((item, idx) => {
            return (
              <Link tabIndex={idx + 1} key={item.id} to={item.url_key}>
                <ImgDiv>
                  <Img src={item.img_url} alt={item.name} />
                  <NameDiv className="vx_text-3">{item.name}</NameDiv>
                </ImgDiv>
              </Link>
            )
          })}
        <EmptyItem />
        <EmptyItem />
        <EmptyItem />
      </Display>
    )
  }
}

const ImageListWithData = compose(withApollo)(withRouter(ImageList))

export default ImageListWithData
