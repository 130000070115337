import styled from '@emotion/styled/macro'
import { Box } from '../../components/box'
import { Text } from '../../components/text'
import { Row } from '../../components/grid'

export const IntroWrapper = styled(Box)`
  ${({ theme }) => theme.inViewStyle()}

  strong {
    font-weight: 600;
  }

  div {
    justify-content: center;
  }

  p,
  p + p {
    margin-bottom: 2rem !important;
  }

  p:last-child {
    margin-bottom: 0 !important;
  }
`

export const GuideItem = styled(Box)``

export const DescriptionWrapper = styled(Text)`
  ${({ theme }) => theme.inViewStyle()}

  a {
    font-weight: inherit !important;
    color: ${(props) => props.theme.colors.primary.bluemedium} !important;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`

export const Headline = styled.a`
  display: block;
  ${({ theme }) => theme.inViewStyle()}
`

export const GuideItemsWrapper = styled(Box)``

export const ImageWrapper = styled(Box)`
  width: 100%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

export const CenteredRow = styled(Row)`
  justify-content: center;
`
