import styled from '@emotion/styled/macro'

export const BannerGiftWrap = styled.span`
  position: absolute;
  z-index: 2;
  width: ${(props) => (props.alignment === 'bottom' ? '154px' : '145px')};
  height: ${(props) => (props.alignment === 'bottom' ? '154px' : '112px')};

  right: 0;
  background: url(${(props) => props.imgSrc}) center no-repeat;
  background-size: cover;

  top: ${(props) => (props.alignment === 'top' ? 0 : 'auto')};
  bottom: ${(props) => (props.alignment === 'bottom' ? 0 : 'auto')};
`
