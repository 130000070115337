import styled from '@emotion/styled/macro'
import { keyframes, css } from '@emotion/react/macro'
import { Box } from '../box'
import { Text } from '../text'

export const TitleWrapper = styled(Box)`
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  pointer-events: none;
  text-align: left;
`

const moveout = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-15%);
  }
`

const movein = keyframes`
  0% {
    transform: translateY(15%);
  }
  100% {
    transform: translateY(0%);
  }
`

export const AnimText = styled(Text)`
  margin: 0;
  padding: 0;
  margin-top: 0;
  ${(props) => {
    switch (props.animState) {
      case 'out':
        return css`
          animation: ${moveout} 0.3s cubic-bezier(0.9, 0.7, 0, 0.83);
        `
      case 'in':
        return css`
          animation: ${movein} 0.3s cubic-bezier(0, 0, 0, 0.83);
        `
      default:
        return ''
    }
  }}
`

export const RefText = styled(Text)`
  opacity: 0;
  pointer-events: 0;
  max-height: 0;
  position: absolute;
`
