import React from 'react'
import Error from './common'
import PropTypes from 'prop-types'
import { trackImpression } from '../../utils/analytics'
import { Message } from '@paypalcorp/worldready-react'
import lodash from 'lodash'

class TxnError extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  componentDidMount() {
    trackImpression(
      'main:consumer:gifts:home:txnerrorpage',
      'main:consumer:gifts:home:txnerrorpage:::',
    )
  }

  render() {
    const txnLimitErrors = lodash.get(
      this.props.location,
      'state.txnLimitErrors',
      [],
    )

    const velocityLimitErrors = lodash.get(
      this.props.location,
      'state.velocityLimitErrors',
      [],
    )

    return (
      <Error
        history={this.props.history}
        title={
          txnLimitErrors.length === 0 && velocityLimitErrors.length === 0 ? (
            <Message id="pages/error.error.txn.header" />
          ) : (
            ''
          )
        }
        buttonText={<Message id="pages/error.error.txn.btn.home" />}
        txnLimitErrors={txnLimitErrors}
        velocityLimitErrors={velocityLimitErrors}
        routeTo="/"
      />
    )
  }
}

export default TxnError
