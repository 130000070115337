import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-hook-inview'
import { getMediaType } from '../../utils/getMediaType'
import { useTheme } from '@emotion/react/macro'

import { Box } from '../box'
import { Button } from '../button'
import { ImageLazy } from '../image-lazy'
import { VideoPlayer } from '../video-player'

import { Col, Row } from '@paypalcorp/pp-react'
import { Container } from '../grid'
import { useCurrentBreakpoint } from '../../utils/hooks/useCurrentBreakpoint'

import * as Styled from './ByobCallout.styled'

const ByobCallout = ({
  eyebrow,
  title,
  mediaSrc,
  ctaLabel,
  ctaHref,
  ...rest
}) => {
  const [ref, inView] = useInView({
    threshold: 0.25,
    unobserveOnEnter: true,
  })
  const theme = useTheme()
  const currentBreakpoint = useCurrentBreakpoint()

  const mediaType = getMediaType(mediaSrc)
  const mediaExists = mediaType !== undefined

  const mediaInColumn = currentBreakpoint < 2

  const RenderMedia = () => {
    if (mediaType === 'video') {
      return (
        <VideoPlayer
          borderRadius="0.5rem"
          overflow="hidden"
          position="relative"
          src={mediaSrc}
          placeholder=""
        />
      )
    }

    return (
      <ImageLazy
        borderRadius="0.5rem"
        overflow="hidden"
        height="100%"
        position="relative"
        bgSrc={mediaSrc}
      />
    )
  }

  return (
    <Container {...rest}>
      <Row>
        <Col>
          <Box
            py={{ _: 'x7', lg: 'x12' }}
            px={{ _: 0, sm: 'x4', lg: 'x12' }}
            borderRadius={{ _: 0, sm: '0.5rem' }}
            border={{ _: 0, sm: '1px solid' }}
            borderColor={{ _: '', sm: 'secondary.greylight' }}
            display="flex"
            flexDirection={{ _: 'column', sm: 'column', md: 'row' }}
            justifyContent="space-between"
            height="100%"
            ref={ref}
            className={inView ? theme.inViewClass : ''}
          >
            <Styled.Content
              textAlign={{ _: 'center', sm: 'center', md: 'left' }}
            >
              <Styled.Eyebrow
                as="div"
                mb="2"
                color="secondary.greymidnight"
                textStyle="eyebrow"
              >
                {eyebrow}
              </Styled.Eyebrow>
              <Styled.Title as="h1" mb="2" textStyle="promoHeader">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </Styled.Title>
              {mediaExists && mediaInColumn && (
                <Box
                  height="300px"
                  my="4"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                >
                  <Styled.MediaWrapper width="300px" height="100%">
                    <RenderMedia />
                  </Styled.MediaWrapper>
                </Box>
              )}
              {!!ctaLabel && (
                <Styled.ButtonWrapper>
                  <Button
                    maxWidth="100%"
                    minWidth={{ _: '100%', sm: '100%', md: '220px' }}
                    variant="primary"
                    label={ctaLabel}
                    href={ctaHref}
                  />
                </Styled.ButtonWrapper>
              )}
            </Styled.Content>
            {!mediaInColumn && mediaExists && (
              <Box
                width="100%"
                height="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                ml="2"
              >
                <Styled.MediaWrapper width="300px" height="300px">
                  <RenderMedia />
                </Styled.MediaWrapper>
              </Box>
            )}
          </Box>
        </Col>
      </Row>
    </Container>
  )
}

ByobCallout.propTypes = {
  title: PropTypes.string.isRequired,
  eyebrow: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  mediaSrc: PropTypes.string,
  ctaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  ctaHref: PropTypes.string.isRequired,
}

ByobCallout.defaultProps = {
  mediaSrc: 'https://www.paypalobjects.com/digitalassets/c/gifts/us/byob.mp4',
}

export default ByobCallout
