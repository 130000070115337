import styled from '@emotion/styled/macro'
import {
  compose,
  color,
  space,
  layout,
  flexbox,
  border,
  position,
} from 'styled-system'

const Box = styled('div')(
  compose(color, space, layout, flexbox, position, border),
)

const FlexBox = styled(Box)`
  display: flex;
`

export { Box, FlexBox }
