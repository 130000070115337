import { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

const fetchMetadata = async (path) => {
  const response = await fetch(
    `/gifts/metadata?path=${encodeURIComponent(path)}`,
  )
  if (!response.ok) {
    throw new Error('Failed to fetch page metadata')
  }
  const data = await response.json()
  return data.pageMetadata
}

export const usePageMetadata = (initialMetadata = {}) => {
  const location = useLocation()

  const [pageMetadata, setPageMetadata] = useState(initialMetadata)
  const [error, setError] = useState(null)

  const getMetadata = useCallback(async () => {
    setError(null)
    try {
      const metadata = await fetchMetadata(location.pathname)
      setPageMetadata(metadata)
    } catch (err) {
      setError(err.message)
    }
  }, [location.pathname])

  useEffect(() => {
    getMetadata()
  }, [getMetadata])

  return { pageMetadata, error }
}
