import { gql } from '@apollo/client'

export default gql`
  query getPagedProducts($page: Int!, $excludeSMB: Boolean) {
    getPagedProducts(page: $page, excludeSMB: $excludeSMB) {
      items {
        id
        sku
        img_url
        name
        url_key
        categories
        keywords
      }
      total_items
      total_pages
    }
  }
`
