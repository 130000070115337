import React, { Component } from 'react'
import theme from './theme'
import routes from './routes'
import NavBar from './components/nav-bar'
import clientData from './utils/client-data'
import { ThemeProvider } from '@emotion/react/macro'
import productPageRoutes from './product-page-routes'
import { giftGuideRoutes } from './gift-guide-routes'
import { PageWrapper } from './components/page-wrapper'
import getBundleItemsFromCache from './graphql/getBundleItemsFromCache'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { CartContextProvider } from './context/cart-context'

import {
  gql,
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client'
import Metadata from './metadata'

const basename = (({ requestURI, locality: { country } }) => {
  const countryCode = country === 'GB' ? 'UK' : country
  return `/${countryCode.toLowerCase()}${requestURI}`
})(clientData)

const elmo = clientData.experimentation
const productPage = productPageRoutes()
const giftGuides = giftGuideRoutes()

class App extends Component {
  state = {
    client: null,
    loaded: false,
  }

  async componentDidMount() {
    this.init()
    document.body.id = 'gifts-new'
  }

  writeCacheVar(client, cacheVar, cacheVal) {
    client.writeQuery({
      query: gql`
        query getCachedData {
          ${cacheVar} @client
        }
      `,
      data: { [cacheVar]: cacheVal },
    })
  }

  async init() {
    const cache = new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Query: {
          fields: {
            getPagedProducts: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: false,
              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = { items: [] }, incoming) {
                const mergedItems = [...existing.items]
                const existingIdMap = {}

                for (const [idx, product] of Object.entries(mergedItems)) {
                  if (existingIdMap[product.id]) {
                    // null out previous ID found in cache
                    mergedItems[existingIdMap[product.id]] = null
                  }

                  existingIdMap[product.id] = idx
                }

                for (let i = 0; i <= incoming.items.length - 1; ++i) {
                  const newProduct = incoming.items[i]

                  if (existingIdMap[newProduct.id]) {
                    // null out previous ID found in cache
                    mergedItems[existingIdMap[newProduct.id]] = null
                  }

                  mergedItems.push(newProduct)
                }

                return {
                  ...incoming,
                  items: mergedItems.filter((e) => e),
                }
              },
            },
          },
        },
      },
    })

    const query = new URLSearchParams(window.location.search).toString()
    const client = new ApolloClient({
      uri: `${basename}/graphql${query ? '?' + query : ''}`,
      headers: {
        'x-csrf-token': clientData.csrf,
      },
      cache,
    })

    client.writeQuery({
      query: getBundleItemsFromCache,
      data: { bundleItems: [] },
    })

    this.writeCacheVar(client, 'imgUrl', '')
    this.writeCacheVar(client, 'productName', '')
    this.writeCacheVar(client, 'productTermsAndConditions', '')
    this.writeCacheVar(client, 'productDescription', '')
    this.writeCacheVar(client, 'sku', '')
    this.writeCacheVar(client, 'isGifting', true)
    this.writeCacheVar(client, 'checkedCustomAmount', false)
    this.writeCacheVar(client, 'recipientEmail', '')
    this.writeCacheVar(client, 'senderName', '')
    this.writeCacheVar(client, 'note', '')
    this.writeCacheVar(client, 'selectedDay', '')
    this.writeCacheVar(client, 'customAmount', {
      currency_code: 'USD',
      value: 0,
    })

    this.setState({
      client,
      loaded: true,
    })
  }

  render() {
    const { client, loaded } = this.state

    if (!loaded) {
      return <div>Loading...</div>
    }

    return (
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <BrowserRouter basename={basename}>
            <CartContextProvider>
              <PageWrapper>
                <Metadata />
                <NavBar />
                <main>
                  <Switch>
                    {routes.map((route, index) => {
                      return (
                        <Route
                          key={`route-${index}`}
                          exact={route.exact}
                          path={route.path}
                          component={route.component}
                        />
                      )
                    })}

                    {productPage}

                    {!!elmo.v2guides && giftGuides}

                    <Redirect to="/" />
                  </Switch>
                </main>
              </PageWrapper>
            </CartContextProvider>
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    )
  }
}

export default App
