import { gql } from '@apollo/client'

export default gql`
  query getProductsInCategory($urlKey: String!) {
    getProductsInCategory(urlKey: $urlKey) {
      category_name
      all_a_z {
        id
        img_url
        name
        url_key
        categories
        keywords
        description
      }
    }
  }
`
