import GiftCardCategory from './screens/category'
import Occasions from './screens/occasions'
import Categories from './screens/categories'
import CartSummary from './screens/cart-summary'
import Success from './screens/success'
import GenericError from './screens/error/generic-error'
import TxnError from './screens/error/txn-error'
import PromotionError from './screens/error/promotion-error'

import { Home, CardsWithData, Deals, SearchResults } from './pages'
import clientData from './utils/client-data'
import PaymentError from './screens/error/payment-error'

const routes = [
  {
    path: '/',
    exact: true,
    component: Home,
    pgrp: 'main:consumer:gifts:home:::',
    enabled: true,
  },
  {
    path: '/all',
    exact: true,
    component: CardsWithData,
    pgrp: 'main:consumer:gifts:allcards:::',
    enabled: true,
  },
  {
    path: '/deals',
    exact: true,
    component: Deals,
    pgrp: 'main:consumer:gifts:deals:::',
    enabled: true,
  },
  {
    path: '/deals/:dealsName',
    exact: true,
    component: Deals,
    pgrp: 'main:consumer:gifts:deals:::',
    enabled: true,
  },
  {
    path: '/occasions',
    exact: true,
    component: Occasions,
    enabled: !!clientData.experimentation.v2occasions,
  },
  {
    path: '/categories',
    exact: true,
    component: Categories,
    enabled: !!clientData.experimentation.categoriesTab,
  },
  {
    path: '/categories/:name',
    exact: true,
    component: GiftCardCategory,
    enabled: !!clientData.experimentation.categoriesTab,
  },
  {
    path: '/occasions/:name',
    exact: true,
    component: GiftCardCategory,
    enabled: !!clientData.experimentation.v2occasions,
  },
  {
    path: '/search/:type',
    exact: true,
    component: SearchResults,
    pgrp: 'main:consumer:gifts:search:::',
    enabled: true,
  },
  {
    path: '/search',
    exact: true,
    component: SearchResults,
    pgrp: 'main:consumer:gifts:search:::',
    enabled: true,
  },
  {
    path: '/cartsummary',
    exact: true,
    component: CartSummary,
    pgrp: 'main:consumer:gifts:cartsummary:::',
    enabled: true,
  },
  {
    path: '/success',
    exact: true,
    component: Success,
    pgrp: 'main:consumer:gifts:success:::',
    enabled: true,
  },
  {
    path: '/error',
    exact: true,
    component: GenericError,
    pgrp: 'main:consumer:gifts:error:::',
    enabled: true,
  },
  {
    path: '/txnerror',
    exact: true,
    component: TxnError,
    pgrp: 'main:consumer:gifts:txnerror:::',
    enabled: true,
  },
  {
    path: '/promotionerror',
    exact: true,
    component: PromotionError,
    pgrp: 'main:consumer:gifts:promotionerror:::',
    enabled: true,
  },
  {
    path: '/paymenterror',
    exact: true,
    component: PaymentError,
    pgrp: 'main:consumer:gifts:paymenterror:::',
    enabled: true,
  },
]

export default routes
