import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'
import { Text } from '../text'

export const CardWrapper = styled(Link)`
  position: relative;
  overflow: hidden;
  color: ${(props) => props.theme.colors.primary.white} !important;
  display: block;
  border-radius: 0.5rem;

  padding-bottom: 139%;
  height: 0%;
  width: 100%;

  transition: 200ms ease;
  transition-property: transform;

  ${({ theme }) => theme.inViewStyle()}

  @media (hover: hover) {
    &:focus,
    &:hover {
      transform: scale(1.025);
      h3 {
        text-decoration: underline;
      }
    }
  }
`

export const CardImage = styled.div`
  position: absolute;
  border-radius: 0.5rem;

  overflow: hidden;

  height: 100%;
  width: 100%;

  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  transition: 200ms ease;
  transition-property: box-shadow, transform;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
      transform: scale(1.025);
    }
  }
`

export const CardInner = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding: 20px;
  position: absolute;
  /* Hide linear gradient fading line */
  height: 200%;
  /* Add dark overlay due for a11y support. */
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 87.5%
  );
`

export const Headline = styled(Text)`
  text-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
`
