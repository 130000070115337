import React from 'react'
import PropTypes from 'prop-types'
import { CurrencyFormat } from '@paypalcorp/worldready'
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react'
import { fractionDisplay } from '../../utils/fraction-display'
import _ from 'lodash'

const DisplayAmount = ({ amountInCents, currencyCode }) => {
  const worldready = React.useContext(WorldReadyContext)

  if (_.isString(amountInCents)) amountInCents = parseInt(amountInCents, 10)

  return new CurrencyFormat(worldready, {
    currency: currencyCode,
    maximumFractionDigits: fractionDisplay(amountInCents),
  }).format(amountInCents / 100)
}

DisplayAmount.defaultProps = {
  currencyCode: 'USD',
}

DisplayAmount.propTypes = {
  amountInCents: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  currencyCode: PropTypes.string.isRequired,
}

export default DisplayAmount
