import React from 'react'
import PropTypes from 'prop-types'

import { SectionIntro } from '../../components/section-intro'

import { useCurrentBreakpoint } from '../../utils/hooks/useCurrentBreakpoint'

import { Box } from '../../components/box'
import { Container, Row, Col } from '../../components/grid'
import { Button } from '../../components/button'
import { Message } from '@paypalcorp/worldready-react'

export const HomePageSection = ({ children, section, noEyebrow, ...props }) => {
  const { ctaHref, name } = section
  const cbp = useCurrentBreakpoint()
  const sectionKey = `section.${name}.`
  const ctaLabel = <Message id={`pages/home.${sectionKey}cta`} />

  return (
    <Box as="section" {...props} py="2rem">
      <Container>
        <Row>
          <Col>
            <SectionIntro
              eyebrow={
                noEyebrow ? (
                  ''
                ) : (
                  <Message id={`pages/home.${sectionKey}eyebrow`} />
                )
              }
              title={<Message id={`pages/home.${sectionKey}header`} />}
              mb="2"
              component={
                ctaHref && ctaLabel && cbp >= 1 ? (
                  <Button
                    variant="small"
                    label={ctaLabel}
                    href={ctaHref}
                    name="SeeAll"
                    data-pagename="main:consumer:gifts:home:"
                    data-pagename2={`main:consumer:gifts:home:${sectionKey}:::`}
                  />
                ) : null
              }
            />
          </Col>
        </Row>
      </Container>
      <Container as="section">
        <Row>
          {children}
        </Row>
      </Container>
      {ctaHref && ctaLabel && cbp < 1 && (
        <Container pt="4">
          <Row>
            <Col>
              <Button
                width="100%"
                variant="primary"
                label={ctaLabel}
                href={ctaHref}
                name="SeeAll"
                data-pagename="main:consumer:gifts:home:"
                data-pagename2={`main:consumer:gifts:home:${sectionKey}:::`}
              />
            </Col>
          </Row>
        </Container>
      )}
    </Box>
  )
}

HomePageSection.propTypes = {
  children: PropTypes.element,
  section: PropTypes.object,
  noEyebrow: PropTypes.bool,
}

HomePageSection.defaultProps = {
  noEyebrow: false,
}
