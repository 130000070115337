import { gql } from '@apollo/client'

export default gql`
  query getAllCategories {
    getAllCategories {
      id
      name
      img_url
      url_key
      type
    }
  }
`
