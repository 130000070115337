import { gql } from '@apollo/client'

export default gql`
  query getCachedData {
    bundleItems @client {
      quantity
      productName
      productTermsAndConditions
      urlKey
      sku
      imgUrl
      customAmount {
        currency_code
        value
      }
      amountToPay {
        currency_code
        value
      }
      totalAmount {
        currency_code
        value
      }
      discounts {
        id
        name
        amount
      }
    }
  }
`
