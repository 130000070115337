import React from 'react'
import PropTypes from 'prop-types'
import * as Styled from './BannerGiftWrap.styled'

const BannerGiftWrap = ({ alignment }) => {
  const img =
    alignment === 'top'
      ? 'https://www.paypalobjects.com/digitalassets/c/gifts/us/giftwrap-tr.png'
      : 'https://www.paypalobjects.com/digitalassets/c/gifts/us/giftwrap-br.png'

  return (
    <Styled.BannerGiftWrap
      data-testid="banner-gift-wrap"
      alignment={alignment}
      imgSrc={img}
    />
  )
}
BannerGiftWrap.propTypes = {
  alignment: PropTypes.string,
}

BannerGiftWrap.defaultProps = {
  alignment: 'top',
}

export default BannerGiftWrap
