import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from '../../components/grid'
import { MessageFormat } from '@paypalcorp/worldready'
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react'

import { HeroHeader } from '../../components/hero-header'
import { GuideSummary } from '../../components/guide-summary'
import { useTrackImpression } from '../../utils/hooks/useTrackImpression'

const media = {
  lg: 'https://www.paypalobjects.com/digitalassets/c/gifts/us/flags_d_02.mp4',
  sm: 'https://www.paypalobjects.com/digitalassets/c/gifts/us/flags_m_02.mp4',
}

const Guides = (props) => {
  const { routes } = props

  useTrackImpression(
    'main:consumer:gifts:guides:',
    `main:consumer:gifts:guides::::`,
  )

  const worldready = useContext(WorldReadyContext)

  const renderGuideSummary = (item, i) => {
    return <GuideSummary key={`guide-${i}`} card={item} />
  }

  const suggestions = new MessageFormat(worldready, {
    id: 'pages/suggestions.suggestions.all',
  }).format()

  return (
    <section>
      <HeroHeader
        headerStyle="search"
        headline={new MessageFormat(worldready, {
          id: 'pages/guides.guides.header',
        }).format()}
        searchSuggestions={suggestions ? suggestions.split(',') : []}
        mediaSrc={media}
      />

      <Container mt={{ _: 'x4', md: 'x6' }}>
        <Row>
          {routes.map((item, i) => {
            return (
              <Col md={4} mb={{ _: 'x4', md: 'x8' }} key={`guide-summary-${i}`}>
                {renderGuideSummary(item, i)}
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  )
}

Guides.propTypes = {
  routes: PropTypes.array,
}

export default Guides
