import styled from '@emotion/styled/macro'
import { Box } from '../box'
import { Text } from '../text'

export const SelectorWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

export const Option = styled(Box)`
  position: relative;
  width: 6rem;
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-bottom-left-radius: ${(props) => (props.index === 0 ? '6px' : '0')};
  border-top-left-radius: ${(props) => (props.index === 0 ? '6px' : '0')};
  border-bottom-right-radius: ${(props) =>
    props.index === props.total - 1 ? '6px' : '0'};
  border-top-right-radius: ${(props) =>
    props.index === props.total - 1 ? '6px' : '0'};

  transition: background-color 0.3s;

  input {
    display: none;
  }

  & > p {
    width: 100%;
  }

  :hover {
    cursor: pointer;
    ${(props) =>
      props.isActive
        ? ''
        : `background-color: ${props.theme.colors.secondary.offwhite};`}
  }
`

export const LabelText = styled(Text)`
  white-space: nowrap;
`
