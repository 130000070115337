import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import giftGuides from './gift-guides'
import { Guides, GuideDetail } from './pages'

const giftGuidesPath = `/gift-guides`

const giftGuideRoutes = () => {
  return (
    <Fragment>
      <Route
        exact={true}
        path={giftGuidesPath}
        render={props => <Guides {...props} routes={giftGuides} />}
      />

      {giftGuides.map((route, index) => {
        return (
          <Route
            key={`gift-guide-${index}`}
            exact={true}
            path={`${giftGuidesPath}${route.path}`}
            render={props => <GuideDetail {...props} {...route} />}
          />
        )
      })}
    </Fragment>
  )
}

export { giftGuidesPath, giftGuideRoutes, giftGuides }
