import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { Button } from '../button'
import { Loader } from '../loader'
import { VerticalGiftList } from '.'
import getProductsInCategory from '../../graphql/getProductsInCategory'
import { Message } from '@paypalcorp/worldready-react'

import { filterBundles, filterCards } from '../../utils/cardFilters'

const VerticalGiftListWithData = ({
  name,
  category,
  cardStyle,
  maxNumItems,
}) => {
  const { data, loading } = useQuery(getProductsInCategory, {
    variables: { urlKey: category },
  })

  if (loading) return <Loader py="100px" />

  let filter = cardStyle === 'cards' ? filterBundles : filterCards

  const productsInCategory = data.getProductsInCategory.all_a_z
    .filter(filter)
    .slice(0, maxNumItems || data.getProductsInCategory.all_a_z.length)

  return (
    <VerticalGiftList
      items={productsInCategory}
      headline={data.getProductsInCategory.category_name}
      eyebrow={<Message id={`pages/home.section.${name}.eyebrow`} />}
      cardStyle={cardStyle}
      cta={
        <Button
          variant="small"
          href={`/search/${cardStyle}?q=${encodeURIComponent(
            data.getProductsInCategory.category_name,
          )}`}
          label={<Message id={`pages/home.section.${name}.cta`} />}
          name="SeeAll"
          data-pagename="main:consumer:gifts:home:"
          data-pagename2={`main:consumer:gifts:home:category:${name}::`}
        />
      }
    />
  )
}

VerticalGiftListWithData.propTypes = {
  name: PropTypes.string,
  category: PropTypes.string,
  cardStyle: PropTypes.oneOf(['cards', 'bundles']),
  maxNumItems: PropTypes.number,
}

export default VerticalGiftListWithData
