import styled from '@emotion/styled/macro'

export const LinkTitle = styled.a`
  color: ${({ theme }) => theme.colors.primary.bluemidnight} !important;
  padding-bottom: 12px;
  font-size: 15px;
  cursor: pointer;
`

export const TextBody = styled.div`
  padding-top: 12px;
  color: ${({ theme }) => theme.colors.secondary.greymidnight};
`
