import styled from '@emotion/styled/macro'
import { Text } from '../text'

export const CardWrapper = styled.div`
  width: calc(100vw - 4rem);
  color: ${(props) => props.theme.colors.primary.black};
  text-align: center;

  :hover,
  :visited,
  :active {
    cursor: pointer;
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary.black};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 640px;
    text-align: left;
  }
`

export const ImgContainer = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  max-height: 100%;
`

export const BannerImage = styled.img`
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
`

export const TitleText = styled(Text)`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
