import { Box } from '../box'
import { Container, Row } from '../grid'
import styled from '@emotion/styled/macro'

export const NavSticky = styled.nav`
  position: sticky;
  top: 0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  background: white;
  z-index: 1000;
`

export const NavContainer = styled(Container)`
  box-sizing: border-box;
`

export const NavRow = styled(Row)``

export const NavBox = styled(Box)`
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-left: -1.5rem;
`

export const NavSlider = styled(Box)`
  display: flex;
  flex-direction: row;
  overflow: visible;
  padding-left: 1rem;
  padding-right: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    transition: color 0.3s;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    overflow: scroll;
  }
`

export const SearchContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex: 1;
  transition: opacity 0.2s ease-out;
  max-width: 14rem;
  margin-right: 50px;

  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? 'all' : 'none')};
`
