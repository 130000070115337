import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import getProductDetails from '../../graphql/getProductDetails'
import { useQuery } from '@apollo/client'

import { Container, Col } from '../../components/grid'
import { useTheme } from '@emotion/react/macro'
import { useInView } from 'react-hook-inview'
import { ImageLazy } from '../../components/image-lazy'
import { useCurrentBreakpoint } from '../../utils/hooks/useCurrentBreakpoint'

import * as Styled from './GuideDetail.styled'

import { Loader } from '../../components/loader'
import { Text } from '../../components/text'
import { GiftCallout } from '../../components/gift-callout'
import { MessageFormat } from '@paypalcorp/worldready'
import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react'

const GuideDetailItem = (props) => {
  const theme = useTheme()
  const worldready = useContext(WorldReadyContext)
  const { id, brand, isStoreVoucher } = props

  const [refTitle, inViewTitle] = useInView({
    threshold: 0.25,
    unobserveOnEnter: true,
  })

  const [refDescription, inViewDescription] = useInView({
    threshold: 0.25,
    unobserveOnEnter: true,
  })

  const itemName = `section.${brand.key}` //for getting translated properties

  const currentBreakpoint = useCurrentBreakpoint()
  const image = currentBreakpoint < 1 ? brand.image_m : brand.image //mobile or desktop image

  const brandUrl = brand.url
  const title = `${brand.name} ${
    !isStoreVoucher && currentBreakpoint > 1
      ? new MessageFormat(worldready, {
          id: 'pages/guide-detail.guideDetail.giftCard',
        }).format()
      : ''
  } ` //if not a store voucher, show "gift guide" text after the brand name in the title, but only if screen size is big enough
  const urlKey = brand.url_key
  const voucherCallout = isStoreVoucher ? (
    <Message id="pages/guide-detail.guideDetail.storeVoucher" />
  ) : null

  const { data, loading } = useQuery(getProductDetails, {
    variables: {
      urlKey,
    },
  })

  return (
    <Styled.GuideItem mt="x8" pb="x8">
      {image && (
        <Styled.ImageWrapper
          maxWidth="1164px"
          ml="auto"
          mr="auto"
          mb="x6"
          borderRadius={{ _: '0', xl: theme.borderRadiusSmall }}
        >
          <ImageLazy src={image} />
        </Styled.ImageWrapper>
      )}

      <Container>
        <Styled.CenteredRow>
          <Col md={6}>
            <Styled.Headline
              href={brandUrl}
              target="_blank"
              ref={refTitle}
              className={inViewTitle ? theme.inViewClass : ''}
            >
              <Text textStyle="h2">{title}</Text>
            </Styled.Headline>
          </Col>
        </Styled.CenteredRow>

        <Styled.CenteredRow>
          <Col md={6}>
            <Styled.DescriptionWrapper
              textStyle="p1"
              mt="x2"
              ref={refDescription}
              className={inViewDescription ? theme.inViewClass : ''}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: new MessageFormat(worldready, {
                    id: `gift-guides/${id}.${itemName}.description`,
                  }).format(),
                }}
              />
            </Styled.DescriptionWrapper>
          </Col>
        </Styled.CenteredRow>

        <Styled.CenteredRow mt="x8">
          <Col md={6}>
            {loading ? (
              <Loader p="50px 0" />
            ) : (
              <GiftCallout
                img={
                  data &&
                  data.getProductDetails &&
                  data.getProductDetails.img_url
                    ? data.getProductDetails.img_url
                    : ''
                }
                primaryText={title}
                secondaryText={voucherCallout}
                buttonText={new MessageFormat(worldready, {
                  id: 'pages/guide-detail.guideDetail.cta',
                }).format()}
                url={`/${urlKey}`}
                name="GiftGuideItem"
                data-pagename="main:consumer:gifts:giftguide:"
                data-pagename2={`main:consumer:gifts:giftguide:brand:${brand.key}::`}
              />
            )}
          </Col>
        </Styled.CenteredRow>
      </Container>
    </Styled.GuideItem>
  )
}

GuideDetailItem.propTypes = {
  id: PropTypes.string,
  brand: PropTypes.object,
  isStoreVoucher: PropTypes.bool,
}

export default GuideDetailItem
