import { gql } from '@apollo/client'

export default gql`
  mutation postCheckoutProcess($paymentInfo: PaymentInfo!, $mfKey: String!) {
    postCheckoutProcess(paymentInfo: $paymentInfo, mfKey: $mfKey) {
      userCountryAllowedForTxn
      isPurchaseLimitExceeded
      isExecutePaymentSuccess
      isOrderSuccess
      velocityLimitErrors {
        hoursAgo
        limit
        productName
      }
      payer {
        email
      }
      transaction {
        txnId
      }
      orderDetails {
        recipientEmail
      }
    }
  }
`
