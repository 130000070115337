import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import TextInput from 'react-text-input'
import { withApollo, graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import _, { flowRight as compose } from 'lodash'
import { ProductDiv } from '../shared/styled'
import Calendar from '../shared/calendar'
import getProductDetails from '../../graphql/getProductDetails'
import paymentAdvice from '../../graphql/paymentAdvice'
import AmountSection from './amount-section'
import Popup from './popup'
import SoldOutOverlay from './sold-out-overlay'
import stripHtmlComments from 'strip-html-comments'
import clientData from '../../utils/client-data'
import isEmailValid from '../../utils/validate-email'
import { trackImpression, trackError } from '../../utils/analytics'
import { log, handleSessionError } from '../../utils/error-tracking'
import trackCartEvent from '../../utils/track-cart-event'
import { Text } from '../../components/text'
import { Box } from '../../components/box'
import DisplayAmount from '../../components/display-amount'
import TextLinkToggle from '../../components/text-link-toggle'
import { MessageFormat, DateTimeFormat } from '@paypalcorp/worldready'
import {
  Message,
  ContextConsumer as WorldReadyContextConsumer,
} from '@paypalcorp/worldready-react'
import { CartContext } from '../../context/cart-context'
import { v1 as uuidv1 } from 'uuid'
import {
  CalenderInputDiv,
  DateLabel,
  DateInput,
  CalendarICON,
  Header,
  ImgDiv,
  Img,
  BundleItem,
  BundleImg,
  CardImage,
  Description,
  OfferTermsSections,
  OffersRewardsIcon,
  MerchantTnCSection,
  Promotion,
  Tab,
  InputContainer,
  NoteTextInput,
  NoteLabel,
  MeTab,
  GiftTab,
  Button,
  Content,
  ClickedStyle,
  DefaultStyle,
  PromoDes,
  ErrorDiv,
} from './product-page.styled'

export class ProductPage extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }).isRequired,
    history: PropTypes.object.isRequired,
    client: PropTypes.object,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.object,
      getProductDetails: PropTypes.object,
    }).isRequired,
  }

  constructor(props) {
    super(props)
    this.resetDataInCache()

    const cachedData = this.props.client.readQuery({
      query: gql`
        query getCachedData {
          productName @client
          sku @client
          isGifting @client
          recipientEmail @client
          senderName @client
          note @client
          selectedDay @client
          customAmount @client {
            currency_code
            value
          }
        }
      `,
    })

    this.state = {
      sku: cachedData.sku,
      isGifting: cachedData.isGifting,
      recipientEmail: cachedData.recipientEmail,
      senderName: cachedData.senderName,
      note: cachedData.note,
      selectedDay: cachedData.selectedDay,
      showCalendar: false,
      invalidEmail: false,
      handleNoteFocus: false,
      showPopup: false,
      popupStatus: 'loading',
      isBundle: false,
    }

    this.productName = cachedData.productName
    this.customAmount = cachedData.customAmount
    this.trackingProductName = encodeURIComponent(
      this.props.location.pathname.slice(1).replace(/\//g, '_'),
    )
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data.loading) {
      return null
    }

    let variants = _.get(nextProps.data, 'getProductDetails.variants')

    if (_.isEmpty(variants)) {
      return null
    }

    let newState = {}

    // if there is a single variant, select it by default, if not already selected
    if (variants.length === 1 && prevState.sku !== variants[0].sku) {
      let selectedSku = variants[0].sku

      nextProps.client.writeQuery({
        query: gql`
          query getCachedData {
            sku @client
          }
        `,
        data: { sku: selectedSku },
      })

      newState = { ...newState, sku: selectedSku }
    }

    // check if it's a bundle
    const { children } = nextProps.data.getProductDetails.variants[0]
    const isBundle = !_.isEmpty(children)
    newState = { ...newState, isBundle }

    return newState
  }

  componentDidMount() {
    trackImpression(
      'main:consumer:gifts:cart:',
      `main:consumer:gifts:cart:product:${this.trackingProductName}::`,
    )
    trackCartEvent(
      'view',
      { page: this.props.location.pathname, title: this.trackingProductName },
      {},
    )

    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleClosePopup)
  }

  resetDataInCache = () => {
    this.props.client.writeQuery({
      query: gql`
        query getCachedData {
          sku @client
          isGifting @client
          recipientEmail @client
          senderName @client
          note @client
          selectedDay @client
        }
      `,
      data: {
        sku: '',
        isGifting: true,
        recipientEmail: '',
        senderName: '',
        note: '',
        selectedDay: new Date().toString(),
      },
    })
  }

  handleClosePopup = () => {
    if (this.state.popupStatus !== 'loading') {
      this.setState({
        showPopup: false,
      })
    }
  }

  UNSAFE_componentWillUnmount() {
    document.removeEventListener('scroll', this.handleClosePopup)
  }

  handleClickGiftTab = () => {
    this.setState({ isGifting: true })

    this.props.client.writeQuery({
      query: gql`
        query getCachedData {
          isGifting @client
        }
      `,
      data: { isGifting: true },
    })
  }

  handleClickMeTab = () => {
    this.setState({ isGifting: false })

    this.props.client.writeQuery({
      query: gql`
        query getCachedData {
          isGifting @client
        }
      `,
      data: { isGifting: false },
    })

    // reset day to Today
    this.props.client.writeQuery({
      query: gql`
        query getCachedData {
          selectedDay @client
        }
      `,
      data: { selectedDay: new Date().toString() },
    })
  }

  isInputValid = (customSku) => {
    const cachedData = this.props.client.readQuery({
      query: gql`
        query getCachedData {
          checkedCustomAmount @client
          sku @client
          recipientEmail @client
          isGifting @client
          customAmount @client {
            currency_code
            value
          }
        }
      `,
    })

    // no amount entered
    if (cachedData.sku === '') {
      this.setState({ amountEmpty: true })

      trackError(
        'main:consumer:gifts:cart:|error',
        `main:consumer:gifts:cart:product:${this.trackingProductName}|error`,
        {
          message: 'no amount selected',
          fieldId: 'amount',
        },
      )

      return false
    }

    // user choose Other, but invalid custom amount
    if (cachedData.sku === customSku && cachedData.customAmount.value === 0) {
      // not yet click Apply to check amount
      if (cachedData.checkedCustomAmount === false) {
        this.setState({ showApplyAmountError: true })
      }

      trackError(
        'main:consumer:gifts:cart:|error',
        `main:consumer:gifts:cart:product:${this.trackingProductName}|error`,
        {
          message: 'invalid custom amount entered.',
          fieldId: 'customAmount',
        },
      )

      return false
    }

    if (cachedData.isGifting && !isEmailValid(cachedData.recipientEmail)) {
      this.setState({ invalidEmail: true })

      trackError(
        'main:consumer:gifts:cart:|error',
        `main:consumer:gifts:cart:product:${this.trackingProductName}|error`,
        {
          message: 'invalid email entered',
          fieldId: 'email',
        },
      )

      return false
    }

    this.customAmount = {
      currency_code: cachedData.customAmount.currency_code,
      value: cachedData.customAmount.value,
    }
    return true
  }

  buildCheckoutPayload = () => {
    const uuid = uuidv1()
    const selectedDay = this.state.selectedDay || new Date().toString()

    return {
      quantity: 1,
      productName: this.productName,
      productTermsAndConditions: this.productTermsAndConditions,
      urlKey: this.props.location.pathname.replace(/^\//g, ''),
      sku: this.state.sku,
      imgUrl: this.imgUrl,
      isGifting: this.state.isGifting,
      recipientEmail: this.state.recipientEmail,
      senderName: this.state.senderName,
      note: this.state.note,
      customAmount: {
        currency_code: this.customAmount.currency_code,
        value: this.customAmount.value,
      },
      discounts: [],
      selectedDay,
      uuid,
    }
  }

  handleAddToCart = async (customSku, addToCart) => {
    if (!this.isInputValid(customSku)) {
      return
    }

    if (!this.state.showPopup) {
      this.setState({ showPopup: true })
      this.setState({ popupStatus: 'loading' })
    }

    const newItem = this.buildCheckoutPayload()

    try {
      await addToCart([newItem])
      this.setState({ popupStatus: 'done' })
    } catch (_err) {
      this.setState({ popupStatus: 'error' })

      log({
        file: 'product-page',
        err: { stack: 'Graphql Exception in payment advice api' },
      })
    }
  }

  handleCheckout = () => {
    this.props.history.push('/cartsummary' + this.props.location.search)
  }

  handleOnBlurCalendar = () => {
    this.setState({ showCalendar: false })
  }

  handleDayClick = (date, { disabled }) => {
    if (disabled) {
      return
    }

    // TODO: verify date for different locale, set selectedDay in store
    this.props.client.writeQuery({
      query: gql`
        query getCachedData {
          selectedDay @client
        }
      `,
      data: { selectedDay: date.toString() },
    })

    this.setState({ showCalendar: false, selectedDay: date.toString() })
  }

  enterRecipientEmail = (e) => {
    this.props.client.writeQuery({
      query: gql`
        query getCachedData {
          recipientEmail @client
        }
      `,
      data: { recipientEmail: e.target.value },
    })
  }

  enterYourName = (e) => {
    this.props.client.writeQuery({
      query: gql`
        query getCachedData {
          senderName @client
        }
      `,
      data: { senderName: e.target.value },
    })
  }

  enterYourNote = (e) => {
    this.setState({ handleNoteFocus: false })

    this.props.client.writeQuery({
      query: gql`
        query getCachedData {
          note @client
        }
      `,
      data: { note: e.target.value },
    })
  }

  focusNote = () => {
    this.setState({ handleNoteFocus: true })
  }

  handleEmailChange = (e) => {
    this.setState({ invalidEmail: false })
    this.setState({ recipientEmail: e.target.value })
  }

  handleNameChange = (e) => {
    this.setState({ senderName: e.target.value })
  }

  handleNoteChange = (e) => {
    this.setState({ note: e.target.value })
  }

  renderForm = () => {
    let noteStyles = {
      fontWeight: 'normal',
      color: this.state.handleNoteFocus
        ? 'rgb(0, 112, 186)'
        : 'rgb(108, 115, 120)',
      transform:
        this.state.handleNoteFocus || this.state.note !== ''
          ? 'translate(1px, -6px) scale(0.75)'
          : 'translate3d(0,0,0)',
    }

    if (!this.state.isGifting) {
      return (
        <div
          data-testid="for-me"
          style={{ paddingTop: '14px', fontSize: '15px', color: '#4a4a4a' }}
        >
          <Message id="pages/product.product.buyForMe" />
        </div>
      )
    }

    return (
      <WorldReadyContextConsumer>
        {(worldready) => {
          const noteLabel = new MessageFormat(worldready, {
            id: 'pages/product.product.yourNote',
          }).format()

          return (
            <div id="form">
              <InputContainer className="vx_form-group">
                <TextInput
                  name="form-control_complex_email"
                  id="form-control_complex_email"
                  className="vx_form-control vx_form-control_complex"
                  value={this.state.recipientEmail}
                  onChange={this.handleEmailChange}
                  onBlur={this.enterRecipientEmail}
                  label={new MessageFormat(worldready, {
                    id: 'pages/product.product.theirEmail',
                  }).format()}
                  styles={{
                    placeholder: {
                      top: '14px',
                    },
                  }}
                />
                {this.state.invalidEmail && (
                  <div style={{ color: '#d20000' }}>
                    <span className=" vx_icon vx_icon-medium vx_icon-critical-xsmall" />
                    <Message id="pages/product.product.invalidEmail" />
                  </div>
                )}
              </InputContainer>

              <InputContainer className="vx_form-group">
                <TextInput
                  name="form-control_complex_name"
                  id="form-control_complex_name"
                  className="vx_form-control vx_form-control_complex"
                  value={this.state.senderName}
                  onChange={this.handleNameChange}
                  onBlur={this.enterYourName}
                  label={new MessageFormat(worldready, {
                    id: 'pages/product.product.yourName',
                  }).format()}
                  styles={{
                    placeholder: {
                      top: '14px',
                    },
                  }}
                />
              </InputContainer>

              <InputContainer
                className="vx_form-group"
                style={{ paddingBottom: '12px' }}
              >
                <NoteTextInput
                  id="form-control_complex_note"
                  name="form-control_complex_note"
                  className="vx_form-control vx_form-control_complex"
                  label={noteLabel}
                  aria-label={noteLabel}
                  value={this.state.note}
                  onChange={this.handleNoteChange}
                  onBlur={this.enterYourNote}
                  onFocus={this.focusNote}
                />
                <NoteLabel
                  style={noteStyles}
                  htmlFor="form-control_complex_note"
                >
                  <Message id="pages/product.product.yourNote" />
                </NoteLabel>
              </InputContainer>

              {this.renderCalenderInput()}
            </div>
          )
        }}
      </WorldReadyContextConsumer>
    )
  }

  toggleCalendar = () => {
    this.setState((prevState) => ({
      showCalendar: !prevState.showCalendar,
    }))
  }

  handleDateInputKeyPress = (event) => {
    // Check to see if space or enter were pressed
    if (event.key === ' ' || event.key === 'Enter') {
      // Prevent the default action to stop scrolling when space is pressed
      event.preventDefault()
      this.toggleCalendar()
    }
  }

  renderCalenderInput = () => {
    const selectedDayInCache = this.props.client.readQuery({
      query: gql`
        query getSelectedDay {
          selectedDay @client
        }
      `,
    })

    return (
      <WorldReadyContextConsumer>
        {(worldready) => {
          const selectedDay = selectedDayInCache.selectedDay
          const dateFormatter = new DateTimeFormat(worldready, {
            timeZone: clientData.locality.timezone,
          })
          const formattedDate = dateFormatter.format(new Date(selectedDay))
          const today = dateFormatter.format(new Date())

          return (
            <div>
              <div style={{ position: 'relative' }}>
                <CalenderInputDiv>
                  <DateLabel htmlFor="date-input">
                    <Message id="pages/product.product.date" />
                  </DateLabel>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '2px',
                    }}
                  >
                    <DateInput
                      type="text"
                      id="date-input"
                      readOnly={true}
                      name="date-input"
                      onClick={this.toggleCalendar}
                      onKeyPress={this.handleDateInputKeyPress}
                      value={
                        formattedDate === today
                          ? new MessageFormat(worldready, {
                              id: 'pages/product.product.defaultDate',
                            }).format()
                          : formattedDate
                      }
                    />
                    <button
                      style={{
                        border: 0,
                        backgroundColor: 'transparent',
                        marginRight: 14,
                        marginTop: -6,
                      }}
                      onClick={this.toggleCalendar}
                    >
                      <CalendarICON />
                    </button>
                  </div>
                </CalenderInputDiv>
              </div>

              {this.state.showCalendar ? (
                <Calendar
                  handleDayClick={this.handleDayClick}
                  handleOnBlur={this.handleOnBlurCalendar}
                />
              ) : null}
            </div>
          )
        }}
      </WorldReadyContextConsumer>
    )
  }

  renderPromotionsTerms = (offers) => {
    return offers.map((offer, idx) => {
      return (
        <Promotion id="promotion" key={idx}>
          <div style={{ display: 'flex' }}>
            <OffersRewardsIcon size="sm" />
            <PromoDes>{offer.name}</PromoDes>
          </div>

          <TextLinkToggle
            linkTitle={<Message id="pages/product.product.offerTerms" />}
            htmlTextBody={offer.terms}
          />
        </Promotion>
      )
    })
  }

  getFixedAmounts = () => {
    const { variants } = this.props.data.getProductDetails

    const fixedPriceVariants = _.filter(variants, {
      price: { type: 'fixed_price' },
    })

    if (!fixedPriceVariants) {
      return null
    }

    const denominationsWithSku = fixedPriceVariants.map((variant) => {
      const {
        sku,
        price: {
          fixed_price_amount: amount = { currency_code: 'USD', value: '0' },
        } = {},
      } = variant
      return {
        sku,
        denomination: {
          currency: amount.currency_code,
          value: amount.value,
        },
      }
    })
    return denominationsWithSku
  }

  getCustomAmountRange = () => {
    const { variants } = this.props.data.getProductDetails

    // only one custom price variant
    const customPriceVariant = _.find(variants, {
      price: { type: 'custom_price' },
    })
    if (!customPriceVariant) {
      return null
    }
    const {
      minimum_price_amount: minAmount = { currency_code: 'USD', value: '0' },
      maximum_price_amount: maxAmount = { currency_code: 'USD', value: '0' },
      step_price_amount: stepPriceAmount,
    } = customPriceVariant.price

    const customAmountWithSku = {
      sku: customPriceVariant.sku,
      customAmount: { minAmount, maxAmount, stepPriceAmount },
    }

    return customAmountWithSku
  }

  handleClickRadioBtn = (sku, isCustom = false) => {
    trackCartEvent(
      'view',
      { page: this.props.location.pathname, title: this.trackingProductName },
      { deal_id: sku },
    )

    this.selectSku(sku, isCustom)
  }

  selectSku(sku, isCustom) {
    this.props.client.writeQuery({
      query: gql`
        query getCachedData {
          sku @client
        }
      `,
      data: { sku },
    })

    this.setState({ sku })
    this.setState({ amountEmpty: false })
    if (!isCustom) {
      this.setState({ showApplyAmountError: false })

      // chose fixed amount, clear custom amount
      this.props.client.writeQuery({
        query: gql`
          query getCachedData {
            customAmount @client {
              currency_code
              value
            }
          }
        `,
        data: {
          customAmount: {
            currency_code: 'USD', // doesn't matter which currency, just to clear custom amount field
            value: 0,
          },
        },
      })
    }
  }

  onEnter = () => {
    this.setState({ showApplyAmountError: false })
  }

  renderProductTermsAndConditions = () => {
    if (/expand-panel-tnc/.test(this.productTermsAndConditions)) {
      return this.renderTermsInExpandMode()
    }
    return this.renderTermsInCollapseMode()
  }

  renderTermsInExpandMode = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: stripHtmlComments(this.productTermsAndConditions),
        }}
      />
    )
  }

  renderTermsInCollapseMode = () => {
    return (
      <TextLinkToggle
        linkTitle={
          <Message
            id="pages/cart-summary.cartsummary.proudctTerms"
            name={this.productName}
          />
        }
        htmlTextBody={this.productTermsAndConditions}
      />
    )
  }

  renderErrorAmountEmpty = () => {
    return (
      <ErrorDiv id="amount-empty-error">
        <span className=" vx_icon vx_icon-medium vx_icon-critical-xsmall" />
        <Message id="pages/product.product.amountEmpty" />
      </ErrorDiv>
    )
  }

  renderErrorNeedAmountCheck = () => {
    return (
      <ErrorDiv id="amount-check-error">
        <span className=" vx_icon vx_icon-medium vx_icon-critical-xsmall" />
        <Message id="pages/product.product.needAmountCheck" />
      </ErrorDiv>
    )
  }

  getImgUrl = () => {
    const cachedData = this.props.client.readQuery({
      query: gql`
        query getCachedData {
          sku @client
        }
      `,
    })
    const { variants, img_url } = this.props.data.getProductDetails
    const choseFixedVariant = _.find(variants, {
      sku: cachedData.sku,
      price: { type: 'fixed_price' },
    })

    let imgUrl
    if (this.state.isBundle) {
      imgUrl = img_url
    } else if (choseFixedVariant) {
      imgUrl = choseFixedVariant.img_url || img_url
    } else {
      imgUrl = img_url
    }

    return imgUrl
  }

  showOnlyGifting() {
    const { tags } = this.props.data.getProductDetails
    return Array.isArray(tags) && tags.includes('only_gifting')
  }

  // eslint-disable-next-line complexity
  render() {
    if (this.props.data.loading) {
      return (
        <div className="vx_has-spinner-large" style={{ paddingTop: '400px' }} />
      )
    }
    if (this.props.data.error) {
      handleSessionError(this.props.data.error)
      log({
        file: 'product-page',
        err: { stack: 'Graphql error, redirect to error page' },
      })
      return <Redirect to="/error" />
    }

    // keep this in cache for risk api: "referrer" field.
    // no use of gql`{ productUrl @client }`
    // this.props.client.writeData({
    //   data: { productUrl: window.location.pathname },
    // })

    const productDetails = this.props.data.getProductDetails
    this.productName = productDetails.name
    this.productTermsAndConditions = productDetails.terms_and_conditions

    const denominationsWithSku = this.getFixedAmounts()
    const customAmountsWithSku = this.getCustomAmountRange()

    const imgUrl = this.getImgUrl()
    // no use of gql`{ imgUrl @client }`
    // this.props.client.writeData({ data: { imgUrl } })
    this.imgUrl = imgUrl

    const { variants } = this.props.data.getProductDetails
    let promos = []
    variants.forEach((variant) => {
      promos.push(...variant.promotions)
    })

    // TODO: ideally this logic should reside at service level.
    // remove duplicate promo based on id
    const promotions = _.uniqBy(promos, 'id')
    this.promosOnProductPage = _.filter(promotions, { is_sold_out: false })
    const soldOutDeals = _.filter(promotions, { is_sold_out: true })

    const trackingExtra = {
      sku: this.state.sku,
    }
    if (this.state.isGifting) {
      trackingExtra.sndr_name = this.state.senderName
      trackingExtra.has_note = !!(this.state.note || '').trim()
      let selectedDay = this.state.selectedDay
        ? new Date(this.state.selectedDay)
        : new Date()
      trackingExtra.evnt_dt = selectedDay.toISOString().substr(0, 10)
    }

    // do not show tabs for SMB products and some bundles
    const shouldShowTabs = !this.showOnlyGifting()

    return (
      <div>
        {soldOutDeals.length > 0 && (
          <SoldOutOverlay
            history={this.props.history}
            soldOutOffers={soldOutDeals}
          />
        )}

        <Popup
          showPopup={this.state.showPopup}
          handleCheckout={this.handleCheckout}
          handleContinueShopping={this.handleClosePopup}
          status={this.state.popupStatus}
          pagename="main:consumer:gifts:cart:"
          pagename2={`main:consumer:gifts:cart:product:${this.trackingProductName}`}
        />

        <ProductDiv id="product-page">
          <Header>{productDetails.name}</Header>

          <ImgDiv>
            {this.state.isBundle ? (
              <BundleImg src={imgUrl} alt={productDetails.name} />
            ) : (
              <Img src={imgUrl} alt={productDetails.name} />
            )}
          </ImgDiv>

          <Content id="content">
            <Description id="description" className="vx_text-body-md">
              <div
                dangerouslySetInnerHTML={{
                  __html: stripHtmlComments(productDetails.description),
                }}
              />
            </Description>

            <OfferTermsSections>
              {this.renderPromotionsTerms(this.promosOnProductPage)}
            </OfferTermsSections>

            <MerchantTnCSection>
              {this.renderProductTermsAndConditions()}
            </MerchantTnCSection>

            <AmountSection
              enteredAmount={this.totalAmount}
              fixedAmounts={denominationsWithSku}
              customAmountsWithSku={customAmountsWithSku}
              handleClickRadioBtn={(sku, isCustom) =>
                this.handleClickRadioBtn(sku, isCustom)
              }
              onEnter={this.onEnter}
              showApplyAmountError={this.state.showApplyAmountError}
              isBundle={this.state.isBundle}
              sku={this.state.sku}
            />
            {this.state.showApplyAmountError &&
              this.renderErrorNeedAmountCheck()}
            {this.state.amountEmpty && this.renderErrorAmountEmpty()}

            {this.state.isBundle
              ? productDetails.variants[0].children.map((child) => {
                  let price = parseFloat(
                    _.get(child, 'price.fixed_price_amount', 'NaN'),
                  )

                  if (_.isNaN(price)) {
                    price = parseFloat(
                      _.get(child, 'price.fixed_price_amount.value', 'NaN'),
                    )
                  }

                  return (
                    <BundleItem
                      key={child.name}
                      justifyContent="space-between"
                      alignItems="center"
                      py="x3"
                    >
                      <Box width={100} mr="x2">
                        <CardImage src={child.img_url} />
                      </Box>
                      <div>
                        <Text>{child.name}</Text>
                      </div>
                      <Box ml="auto">
                        {!_.isNaN(price) ? (
                          <DisplayAmount amountInCents={price} />
                        ) : (
                          'N/A'
                        )}
                      </Box>
                    </BundleItem>
                  )
                })
              : null}

            {shouldShowTabs && (
              <Tab>
                <GiftTab
                  className="vx_text-body-md_medium"
                  onClick={this.handleClickGiftTab}
                  style={this.state.isGifting ? ClickedStyle : DefaultStyle}
                  name="ThisIsAGift"
                  data-testid="gift-tab"
                  data-pagename="main:consumer:gifts:cart:"
                  data-pagename2={`main:consumer:gifts:cart:product:${this.trackingProductName}`}
                >
                  <Message id="pages/product.product.giftTab" />
                </GiftTab>
                <MeTab
                  className="vx_text-body-md_medium"
                  onClick={this.handleClickMeTab}
                  style={this.state.isGifting ? DefaultStyle : ClickedStyle}
                  name="ThisIsForMe"
                  data-testid="for-me-tab"
                  data-pagename="main:consumer:gifts:cart:"
                  data-pagename2={`main:consumer:gifts:cart:product:${this.trackingProductName}`}
                >
                  <Message id="pages/product.product.meTab" />
                </MeTab>
              </Tab>
            )}
            {this.renderForm()}

            <div style={{ textAlign: 'center' }}>
              <CartContext.Consumer>
                {({ addToCart }) => (
                  <Button
                    onClick={() =>
                      this.handleAddToCart(
                        customAmountsWithSku && customAmountsWithSku.sku,
                        addToCart,
                      )
                    }
                    name="AddToCart"
                    data-testid="add-to-cart-button"
                    data-pagename="main:consumer:gifts:cart:"
                    data-pagename2={`main:consumer:gifts:cart:product:${this.trackingProductName}`}
                    data-extra={JSON.stringify(trackingExtra)}
                  >
                    <Message id="pages/product.product.btn" />
                  </Button>
                )}
              </CartContext.Consumer>
            </div>

            {_.get(productDetails, 'banner', '').toString().trim() !== '' && (
              <Description id="banner" className="vx_text-body-md">
                <div
                  dangerouslySetInnerHTML={{
                    __html: stripHtmlComments(productDetails.banner),
                  }}
                />
              </Description>
            )}
          </Content>
        </ProductDiv>
      </div>
    )
  }
}

const ProductPageWithData = compose(
  withApollo,
  graphql(getProductDetails, {
    options: (ownProps) => ({
      variables: {
        urlKey: ownProps.location.pathname.replace(/^\//g, ''),
      },
    }),
  }),
  graphql(paymentAdvice, { name: 'paymentAdvice' }),
)(ProductPage)

export default ProductPageWithData
