import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-hook-inview'
import { useTheme } from '@emotion/react/macro'

import * as Styled from './LogoBubble.styled'

const LogoBubble = ({ imgUrl, alt, url, ...props }) => {
  const [ref, inView] = useInView({
    threshold: 0.25,
    unobserveOnEnter: true,
  })
  const theme = useTheme()

  return (
    <Styled.LogoBubble
      to={url}
      ref={ref}
      className={inView ? theme.inViewClass : ''}
      {...props}
    >
      <img src={imgUrl && imgUrl.length ? imgUrl : null} alt={alt} />
    </Styled.LogoBubble>
  )
}

LogoBubble.propTypes = {
  imgUrl: PropTypes.string,
  alt: PropTypes.string,
  url: PropTypes.string,
}

export default LogoBubble
