import { useEffect } from 'react'
import { trackImpression } from '../analytics'

/** Impression hook
 * Call in body of FunctionComponent
 * to fire impression event on mount.
 */
export function useTrackImpression(...args) {
  useEffect(() => {
    trackImpression(...args)
  }, [])
}
