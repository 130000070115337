const giftGuides = [
  {
    path: '/gifts-for-teens',
    id: 'gifts-for-teens',
    headerImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_teens_header_opt.gif',
    headerImage_m:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_teens_header_opt.gif',
    coverImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/teens_cover-animated.gif',
    brands: [
      {
        key: 'ulta',
        url_key: 'brands/ulta-beauty',
        name: 'Ulta',
        url: 'https://www.ulta.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_teens_ulta_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_teens_ulta_2x.jpg',
      },
      {
        key: 'xbox',
        url_key: 'brands/xbox/xbox-giftcard',
        name: 'Xbox',
        url: 'https://www.xbox.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_teens_xbox_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_teens_xbox_2x.jpg',
      },
      {
        key: 'stub-hub',
        url_key: 'brands/stub-hub',
        name: 'StubHub',
        url: 'https://www.stubhub.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_teens_stubhub_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_teens_stubhub_2x.jpg',
      },
      {
        key: 'fandango',
        url_key: 'brands/fandango',
        name: 'Fandango',
        url: 'https://www.fandango.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_teens_fandango_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_teens_fandango_2x.jpg',
      },
      {
        key: 'karma',
        url_key: 'brands/karma-koin',
        name: 'Karma Koin',
        url: 'https://www.karmakoin.com/',
      },
      {
        key: 'nintendo',
        url_key: 'brands/nintendo-egift-card',
        name: 'Nintendo',
        url: 'https://www.nintendo.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_teens_nintendo_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_teens_nintendo_2x.jpg',
      },
      {
        key: 'gamestop',
        url_key: 'brands/gamestop',
        name: 'GameStop',
        url: 'https://www.gamestop.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_teens_gamestop_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_teens_gamestop_2x.jpg',
      },
      {
        key: 'grubhub',
        url_key: 'brands/grubhub',
        name: 'Grubhub',
        url: 'https://www.grubhub.com/lets-eat',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_teens_grubhub_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_teens_grubhub_2x.jpg',
      },
      {
        key: 'dave-busters',
        url_key: 'brands/dave-busters',
        name: "Dave & Buster's",
        url: 'https://www.daveandbusters.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_teens_dave_busters_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_teens_dave_busters_2x.jpg',
      },
      {
        key: 'apple-lynx',
        url_key: 'brands/apple',
        name: 'Apple',
        url: 'https://www.apple.com/',
        image:
          'https://pics.paypal.com/00/c/gifts/us/applebannergiftguides.jpg',
        image_m: 'https://pics.paypal.com/00/c/gifts/us/applemobile-guides.jpg',
      },
    ],
  },
  {
    path: '/gifts-for-the-home',
    id: 'gifts-for-the-home',
    headerImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_home_header_2x.jpg',
    headerImage_m:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_home_header_2x.jpg',
    coverImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/home_cover.jpg',
    brands: [
      {
        key: 'home-depot',
        url_key: 'brands/home-depot',
        name: 'The Home Depot®',
        url: 'https://www.homedepot.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_home_home-depot_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_home_home-depot_2x.jpg',
      },
      {
        key: 'sur-la-table',
        url_key: 'brands/surlatable',
        name: 'Sur La Table',
        url: 'https://www.surlatable.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_home_surlatable_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_home_surlatable_2x.jpg',
      },
      {
        key: 'shutterfly',
        url_key: 'brands/shutterfly',
        name: 'Shutterfly',
        url: 'https://www.shutterfly.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_home_shutterfly_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_home_shutterfly_2x.jpg',
      },
      {
        key: 'world-market',
        url_key: 'brands/cost-plus-world-market',
        name: 'Cost Plus World Market',
        url: 'https://www.worldmarket.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_home_worldmarket_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_home_worldmarket_2x.jpg',
      },
      {
        key: 'cb2',
        url_key: 'brands/cb2',
        name: 'CB2',
        url: 'https://www.cb2.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_home_cb2_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_home_cb2_2x.jpg',
      },
      {
        key: 'michaels',
        url_key: 'brands/michaels',
        name: 'Michaels',
        url: 'https://www.michaels.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_home_michaels_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_home_michaels_2x.jpg',
      },
      {
        key: 'yankee-candle',
        url_key: 'brands/yankee-candle',
        name: 'Yankee Candle',
        url: 'https://www.yankeecandle.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_home_yankeecandle_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_home_yankeecandle_2x.jpg',
      },
      {
        key: 'bed-bath',
        url_key: 'brands/bedbathbeyond',
        name: 'Bed Bath & Beyond',
        url: 'https://www.bedbathandbeyond.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_home_bedbathandbeyond_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_home_bedbathandbeyond_2x.jpg',
      },
      {
        key: 'petsmart',
        url_key: 'brands/petsmart',
        name: 'PetSmart',
        url: 'https://www.petsmart.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_home_petsmart_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_home_petsmart_2x.jpg',
      },
      {
        key: 'lowes',
        url_key: 'brands/lowes',
        name: "Lowe's",
        url: 'https://www.lowes.com/',
      },
    ],
  },
  {
    path: '/less-is-more',
    id: 'less-is-more',
    headerImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_stockingstuffers_header_2x.jpg',
    headerImage_m:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_stockingstuffers_header_2x.jpg',
    coverImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/stockingstuffers_cover.jpg',
    brands: [
      {
        key: 'famous-footwear',
        url_key: 'brands/famous',
        name: 'Famous Footwear',
        url:
          'https://www.famousfootwear.com/socks?icid=accessoriesDD_accessories_socks',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_stockingstuffers_famousfootwear_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_stockingstuffers_famousfootwear_2x.jpg',
      },
      {
        key: 'the-home-depot',
        url_key: 'brands/home-depot',
        name: 'The Home Depot®',
        url: 'https://www.homedepot.com/s/keychain?NCNI-5',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_stockingstuffers_homedepot_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_stockingstuffers_homedepot_2x.jpg',
      },
      {
        key: 'apple-lynx',
        url_key: 'brands/apple',
        name: 'Apple',
        url: 'https://www.apple.com/',
        image:
          'https://pics.paypal.com/00/c/gifts/us/applebannergiftguides.jpg',
        image_m: 'https://pics.paypal.com/00/c/gifts/us/applemobile-guides.jpg',
      },
      {
        key: 'bed-bath-beyond',
        url_key: 'brands/bedbathbeyond',
        name: 'Bed Bath & Beyond',
        url: 'https://www.bedbathandbeyond.com/',
      },
      {
        key: 'grubhub',
        url_key: 'brands/grubhub',
        name: 'Grubhub',
        url: 'https://www.grubhub.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_stockingstuffers_grubhub_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_stockingstuffers_grubhub_2x.jpg',
      },
      {
        key: 'build-a-bear-workshop',
        url_key: 'brands/build-a-bear-workshop',
        name: 'Build-A-Bear Workshop',
        url: 'https://www.buildabear.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_stockingstuffers_buildabear_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_stockingstuffers_buildabear_2x.jpg',
      },
      {
        key: 'fanatics',
        url_key: 'brands/fanatics',
        name: 'Fanatics',
        url: 'https://www.fanatics.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_stockingstuffers_fanatics_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_stockingstuffers_fanatics_2x.jpg',
      },
      {
        key: 'target',
        url_key: 'brands/target',
        name: 'Target',
        url: 'https://www.target.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_stockingstuffers_target_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_stockingstuffers_target_2x.jpg',
      },
    ],
  },
  {
    path: '/bargain-bites',
    id: 'bargain-bites',
    headerImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_bargainbites_header_2x.jpg',
    headerImage_m:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_bargainbites_header_2x.jpg',
    coverImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/bargainbites_cover.jpg',
    brands: [
      {
        key: 'dominos',
        url_key: 'brands/domino-s-pizza',
        name: "Domino's",
        url: 'https://www.dominos.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_bargainbites_dominos_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_bargainbites_dominos_2x.jpg',
      },
      {
        key: 'chipotle',
        url_key: 'brands/chipotle',
        name: 'Chipotle',
        url: 'https://www.chipotle.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_bargainbites_chipotle_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_bargainbites_chipotle_2x.jpg',
      },
      {
        key: 'panera-bread',
        url_key: 'brands/panera-bread-gift-card',
        name: 'Panera Bread',
        url: 'https://www.panerabread.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_bargainbites_panerabread_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_bargainbites_panerabread_2x.jpg',
      },
      {
        key: 'dunkin',
        url_key: 'brands/dunkin',
        name: "Dunkin'",
        url: 'https://www.dunkindonuts.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_bargainbites_dunkindonuts_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_bargainbites_dunkindonuts_2x.jpg',
      },
    ],
  },
  {
    path: '/gifts-for-a-night-in',
    id: 'gifts-for-a-night-in',
    headerImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_nightin_header_2x.jpg',
    headerImage_m:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_nightin_header_2x.jpg',
    coverImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/nightin_cover.jpg',
    brands: [
      {
        key: 'uber-eats',
        url_key: 'brands/ubereats',
        name: 'Uber Eats',
        url: 'https://www.ubereats.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_nightin_ubereats_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_nightin_ubereats_2x.jpg',
      },
      {
        key: 'dominos',
        url_key: 'brands/domino-s-pizza',
        name: "Domino's",
        url: 'https://www.dominos.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_nightin_dominos_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_nightin_dominos_2x.jpg',
      },
      {
        key: 'nintendo',
        url_key: 'brands/nintendo-egift-card',
        name: 'Nintendo',
        url: 'https://www.nintendo.com/switch/online-service/online-play/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_nightin_nintendo_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_nightin_nintendo_2x.jpg',
      },
      {
        key: 'apple-lynx',
        url_key: 'brands/apple',
        name: 'Apple',
        url: 'https://www.apple.com/',
        image:
          'https://pics.paypal.com/00/c/gifts/us/applebannergiftguides.jpg',
        image_m: 'https://pics.paypal.com/00/c/gifts/us/applemobile-guides.jpg',
      },
      {
        key: 'yankee-candle',
        url_key: 'brands/yankee-candle',
        name: 'Yankee Candle',
        url: 'https://www.yankeecandle.com/',
      },
      {
        key: 'bed-bath-beyond',
        url_key: 'brands/bedbathbeyond',
        name: 'Bed Bath & Beyond',
        url: 'https://www.bedbathandbeyond.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_nightin_bedbathbeyond_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_nightin_bedbathbeyond_2x.jpg',
      },
    ],
  },
  {
    path: '/gifts-for-millennials',
    id: 'gifts-for-millennials',
    headerImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_millennials_header_2x.jpg',
    headerImage_m:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_millennials_header_2x.jpg',
    coverImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/millennials_cover.jpg',
    brands: [
      {
        key: 'grubhub',
        url_key: 'brands/grubhub',
        name: 'Grubhub',
        url: 'https://www.grubhub.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_millennials_grubhub_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_millennials_grubhub_2x.jpg',
      },
      {
        key: 'apple-lynx',
        url_key: 'brands/apple',
        name: 'Apple',
        url: 'https://www.apple.com/',
        image:
          'https://pics.paypal.com/00/c/gifts/us/applebannergiftguides.jpg',
        image_m: 'https://pics.paypal.com/00/c/gifts/us/applemobile-guides.jpg',
      },
      {
        key: 'uber-eats',
        url_key: 'brands/ubereats',
        name: 'Uber Eats',
        url: 'https://www.ubereats.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_millennials_ubereats_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_millennials_ubereats_2x.jpg',
      },
      {
        key: 'uber',
        url_key: 'brands/uber',
        name: 'Uber',
        url: 'https://www.uber.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_millennials_uber_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_millennials_uber_2x.jpg',
      },
      {
        key: 'sephora',
        url_key: 'brands/sephora',
        name: 'Sephora',
        url: 'https://www.sephora.com/beauty/beauty-best-sellers',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_millennials_sephora_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_millennials_sephora_2x.jpg',
      },
    ],
  },
  {
    path: '/gifts-for-parents',
    id: 'gifts-for-parents',
    headerImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_parents_header_2x.jpg',
    headerImage_m:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_parents_header_2x.jpg',
    coverImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/parents_cover.jpg',
    brands: [
      {
        key: 'bed-bath-beyond',
        url_key: 'brands/bedbathbeyond',
        name: 'Bed Bath & Beyond',
        url: 'https://www.bedbathandbeyond.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_parents_bedbathandbeyond_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_parents_bedbathandbeyond_2x.jpg',
      },
      {
        key: 'build-a-bear-workshop',
        url_key: 'brands/build-a-bear-workshop',
        name: 'Build-A-Bear Workshop',
        url: 'https://www.buildabear.com/winter-wishes-e-gift-card/087152.html',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_parents_build-a-bear_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_parents_build-a-bear_2x.jpg',
      },
      {
        key: 'uber-eats',
        url_key: 'brands/ubereats',
        name: 'Uber Eats',
        url: 'http://www.ubereats.com',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_parents_ubereats_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_parents_ubereats_2x.jpg',
      },
      {
        key: 'the-childrens-place',
        url_key: 'brands/the-childrens-place',
        name: 'The Children’s Place',
        url: 'https://www.childrensplace.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_parents_the-childrens-place_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_parents_the-childrens-place_2x.jpg',
      },
      {
        key: 'buy-buy-baby',
        url_key: 'brands/buybuybaby',
        name: 'buybuyBABY',
        url: 'https://www.buybuybaby.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_parents_buy-buy-baby_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_parents_buy-buy-baby_2x.jpg',
      },
      {
        key: 'dunkin',
        url_key: 'brands/dunkin',
        name: 'Dunkin’',
        url: 'http://www.dunkindonuts.com',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_parents_dunkin_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_parents_dunkin_2x.jpg',
      },
      {
        key: 'target',
        url_key: 'brands/target',
        name: 'Target',
        url: 'https://www.target.com/',
      },
    ],
  },
  {
    path: '/fitness-gifts',
    id: 'fitness-gifts',
    headerImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_fitness_header_2x.jpg',
    headerImage_m:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_fitness_header_2x.jpg',
    coverImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/fitness_cover.jpg',
    brands: [
      {
        key: 'under-armour',
        url_key: 'brands/under-armour',
        name: 'Under Armour',
        url: 'https://www.underarmour.com/en-us/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_fitness_underarmour_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_fitness_underarmour_2x.jpg',
      },
      {
        key: 'top-golf',
        url_key: 'brands/topgolf',
        name: 'Topgolf',
        url: 'http://www.topgolf.com',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_fitness_topgolf_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_fitness_topgolf_2x.jpg',
      },
      {
        key: 'fanatics',
        url_key: 'brands/fanatics',
        name: 'Fanatics',
        url: 'https://www.fanatics.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_fitness_fanatics_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_fitness_fanatics_2x.jpg',
      },
      {
        key: 'spa-wellness',
        url_key: 'brands/spa-week',
        name: 'Spa & Wellness',
        url: 'https://www.spaweek.com/',
      },
    ],
  },
  {
    path: '/gifts-for-recent-grads',
    id: 'gifts-for-recent-grads',
    headerImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_collegegrads_header_2x.jpg',
    headerImage_m:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_collegegrads_header_2x.jpg',
    coverImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/collegegrads_cover.jpg',
    brands: [
      {
        key: 'bed-bath-beyond',
        url_key: 'brands/bedbathbeyond',
        name: 'Bed Bath & Beyond',
        url: 'https://www.bedbathandbeyond.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_collegegrads_bedbath_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_collegegrads_bedbath_2x.jpg',
      },
      {
        key: 'dominos',
        url_key: 'brands/domino-s-pizza',
        name: 'Domino’s',
        url: 'https://www.dominos.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_collegegrads_dominos_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_collegegrads_dominos_2x.jpg',
      },
      {
        key: 'chipotle',
        url_key: 'brands/chipotle',
        name: 'Chipotle',
        url: 'https://www.chipotle.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_collegegrads_chipotle_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_collegegrads_chipotle_2x.jpg',
      },
      {
        key: 'dunkin',
        url_key: 'brands/dunkin',
        name: 'Dunkin’',
        url: 'http://www.dunkindonuts.com',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_collegegrads_dunkin_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_collegegrads_dunkin_2x.jpg',
      },
      {
        key: 'uber',
        url_key: 'brands/uber',
        name: 'Uber',
        url: 'https://www.uber.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_collegegrads_uber_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_collegegrads_uber_2x.jpg',
      },
    ],
  },
  {
    path: '/travel-gifts',
    id: 'travel-gifts',
    headerImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_travel_header_2x.jpg',
    headerImage_m:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_travel_header_2x.jpg',
    coverImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/travel_cover.jpg',
    brands: [
      {
        key: 'airbnb',
        url_key: 'brands/airbnb',
        name: 'Airbnb',
        url: 'https://www.airbnb.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_travel_airbnb_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_travel_airbnb_2x.jpg',
      },
      {
        key: 'bed-bath-beyond',
        url_key: 'brands/bedbathbeyond',
        name: 'Bed Bath & Beyond',
        url: 'https://www.bedbathandbeyond.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_travel_bedbathandbeyond_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_travel_bedbathandbeyond_2x.jpg',
      },
      {
        key: 'nintendo',
        url_key: 'brands/nintendo-egift-card',
        name: 'Nintendo',
        url: 'https://www.nintendo.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_travel_nintendo_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_travel_nintendo_2x.jpg',
      },
      {
        key: 'dsw',
        url_key: 'brands/dsw',
        name: 'DSW',
        url: 'https://www.dsw.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_travel_dsw_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_travel_dsw_2x.jpg',
      },
      {
        key: 'southwest-airlines',
        url_key: 'brands/southwest-airlines',
        name: 'Southwest Airlines',
        url: 'https://www.southwest.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_travel_southwestairlines_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_travel_southwestairlines_2x.jpg',
      },
      {
        key: 'carnival-cruise-line',
        url_key: 'brands/carnival-cruise-lines',
        name: 'Carnival Cruise Line',
        url: 'https://www.carnival.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_travel_carnivalcruise_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_travel_carnivalcruise_2x.jpg',
      },
      {
        key: 'hotels',
        url_key: 'brands/hotels-com',
        name: 'Hotels.com',
        url: 'https://www.hotels.com/',
      },
      {
        key: 'uber',
        url_key: 'brands/uber',
        name: 'Uber',
        url: 'https://www.uber.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_travel_uber_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_travel_uber_2x.jpg',
      },
    ],
  },
  {
    path: '/gifts-for-life-hackers',
    id: 'gifts-for-life-hackers',
    headerImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_lifehackers_header_2x.jpg',
    headerImage_m:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_lifehackers_header_2x.jpg',
    coverImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/lifehackers_cover.jpg',
    brands: [
      {
        key: 'bed-bath-beyond',
        url_key: 'brands/bedbathbeyond',
        name: 'Bed Bath & Beyond',
        url: 'https://www.bedbathandbeyond.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_lifehackers_bedbathandbeyond_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_lifehackers_bedbathandbeyond_2x.jpg',
      },
      {
        key: 'home-depot',
        url_key: 'brands/home-depot',
        name: 'The Home Depot®',
        url: 'https://www.homedepot.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_lifehackers_homedepot_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_lifehackers_homedepot_2x.jpg',
      },
      {
        key: 'famous-footwear',
        url_key: 'brands/famous',
        name: 'Famous Footwear',
        url: 'https://www.famousfootwear.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_lifehackers_famousfootwear_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_lifehackers_famousfootwear_2x.jpg',
      },
      {
        key: 'burlington',
        url_key: 'brands/burlington-coat-factory',
        name: 'Burlington',
        url: 'https://www.burlington.com/',
      },
      {
        key: 'samsclub',
        url_key: 'brands/samsclub',
        name: 'Sam’s Club',
        url: 'https://www.samsclub.com/',
      },
      {
        key: 'world-market',
        url_key: 'brands/cost-plus-world-market',
        name: 'Cost Plus World Market',
        url: 'https://www.worldmarket.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_lifehackers_costplusworldmarket_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_lifehackers_costplusworldmarket_2x.jpg',
      },
      {
        key: 'spa-wellness',
        url_key: 'brands/spa-week',
        name: 'Spa & Wellness',
        url: 'https://www.spaweek.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_lifehackers_spaandwellness_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_lifehackers_spaandwellness_2x.jpg',
      },
    ],
  },
  {
    path: '/beauty-gifts',
    id: 'beauty-gifts',
    headerImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_beautygurus_header_2x.jpg',
    headerImage_m:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_beautygurus_header_2x.jpg',
    coverImage:
      'https://www.paypalobjects.com/digitalassets/c/gifts/us/beautygurus_cover.jpg',
    brands: [
      {
        key: 'sephora',
        url_key: 'brands/sephora',
        name: 'Sephora',
        url: 'https://www.sephora.com/beauty/beauty-best-sellers',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_beautygurus_sephora_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_beautygurus_sephora_2x.jpg',
      },
      {
        key: 'lord-taylor',
        url_key: 'brands/lord-taylor',
        name: 'Lord + Taylor',
        url: 'https://www.lordandtaylor.com/Entry.jsp',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_beautygurus_lordandtaylor_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_beautygurus_lordandtaylor_2x.jpg',
      },
      {
        key: 'bed-bath-beyond',
        url_key: 'brands/bedbathbeyond',
        name: 'Bed Bath & Beyond',
        url: 'https://www.bedbathandbeyond.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_beautygurus_bedbathandbeyond_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_beautygurus_bedbathandbeyond_2x.jpg',
      },
      {
        key: 'jcpenney',
        url_key: 'brands/jcpenney',
        name: 'JCPenney',
        url: 'https://www.jcpenney.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_beautygurus_jcpenney_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_beautygurus_jcpenney_2x.jpg',
      },
      {
        key: 'spa-wellness',
        url_key: 'brands/spa-week',
        name: 'Spa & Wellness',
        url: 'https://www.spaweek.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_beautygurus_spaandwellness_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_beautygurus_spaandwellness_2x.jpg',
      },
      {
        key: 'bath-body',
        url_key: 'brands/bathbodyworks',
        name: 'Bath & Body Works',
        url: 'https://www.bathandbodyworks.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_beautygurus_bathandbodyworks_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_beautygurus_bathandbodyworks_2x.jpg',
      },
      {
        key: 'yankee-candle',
        url_key: 'brands/yankee-candle',
        name: 'Yankee Candle',
        url: 'https://www.yankeecandle.com/',
        image:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/d_beautygurus_yankeecandle_2x.jpg',
        image_m:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/m_beautygurus_yankeecandle_2x.jpg',
      },
    ],
  },
]

export default giftGuides
