import { gql } from '@apollo/client'

export default gql`
  mutation paymentAdvice($paymentAdviceInput: PaymentAdviceInput) {
    paymentAdvice(paymentAdviceInput: $paymentAdviceInput) {
      total_value {
        currency_code
        value
      }
      amount_to_pay {
        value
        currency_code
      }
      promotions {
        id
        amount {
          value
          currency_code
        }
      }
    }
  }
`
