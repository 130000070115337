import React from 'react'
import PropTypes from 'prop-types'
import Calendar from './index'
import styled from '@emotion/styled/macro'
import { CalendarSvg } from '../icons/svg-images'
import styledIcon from '../icons'
import { Message } from '@paypalcorp/worldready-react'

const CalenderInput = styled.div`
  height: 48px;
  width: 100%;
  display: inline-block;
  border: solid 1px #b7bcbf;
  border-radius: 5px;
  padding-left: 13px;

  @media only screen and (max-width: 768px) {
    margin-top: 24px;
  }
`

const DateLabel = styled.label`
  display: block;
  font-size: 12px;
  color: #687173;
  margin-bottom: 0;
  height: 15px;
`

const DateInput = styled.input`
  display: table-cell;
  border: none;
  outline: none;
  font-size: 15px;
`

const CalendarICON = styledIcon`
  & svg {
    height: 21px;
    width: 25px;
  }
`

CalendarICON.defaultProps = { svg: CalendarSvg }

class CalendarInput extends React.Component {
  static propTypes = {
    handleDayClick: PropTypes.func,
    handleOnBlurCalendar: PropTypes.func.isRequired,
    selectedDay: PropTypes.string.isRequired,
  }
  state = {
    showCalendar: true,
  }

  toggleCalendar = () => {
    this.setState((prevState) => ({
      showCalendar: !prevState.showCalendar,
    }))
  }

  render() {
    return (
      <div>
        <div style={{ position: 'relative' }}>
          <CalenderInput>
            <DateLabel htmlFor="date">
              <Message id="pages/cart-summary.cartsummary.date" />
            </DateLabel>
            <div style={{ display: 'flex', marginTop: '2px' }}>
              <DateInput
                type="text"
                id="date-input"
                readOnly={true}
                style={{ flex: '1' }}
                onClick={this.toggleCalendar}
                value={this.props.selectedDay}
              />
              <button
                style={{ border: 0, backgroundColor: 'transparent' }}
                onClick={this.toggleCalendar}
              >
                <CalendarICON />
              </button>
            </div>
          </CalenderInput>
        </div>

        {this.state.showCalendar ? (
          <Calendar
            handleDayClick={this.props.handleDayClick}
            handleOnBlur={this.props.handleOnBlurCalendar}
          />
        ) : null}
      </div>
    )
  }
}

export default CalendarInput
