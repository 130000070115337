/**
 * Log client-side errors to the server
 */

// TODO: Fix dependency cycle
import { trackJSError } from './analytics' // eslint-disable-line import/no-cycle
import logger from 'loglevel'
import clientData from './client-data'

// max number of errors logged per session
const MAX_ERRORS = 2

// error count
let errors = 0

// eslint-disable-next-line consistent-return
export async function handleAsyncError(opts, fn) {
  try {
    return await fn()
  } catch (err) {
    log({
      ...opts,
      err: { stack: opts.errMessagePrefix + ' ' + err.message },
    })
  }
}

/**
 * post the messages to the server
 */
export function log({ /* message, */ file, line, column, err }) {
  // log to CAL
  fetch('/gifts/error', {
    method: 'POST',
    headers: { 'x-csrf-token': clientData.csrf },
    data: JSON.stringify({
      file,
      line,
      column,
      message: err && err.message,
      href: window.location.href,
      stack: err ? err.stack : '',
      userAgent: navigator.userAgent,
    }),
  })

  // log to FPTI
  trackJSError(err, `${file} ${line}:${column}`)
}

/**
 * Start capturing global errors
 */
export function startClientErrorTracking() {
  window.addEventListener('unhandledrejection', handleRejection)
  window.onerror = handleError

  function handleRejection(event) {
    event.preventDefault()
    // if it looks basically like an Error object, handle it
    if (event.reason && event.reason.stack) {
      logger.error(event.reason)
      handleError(event.reason.message, '', 0, 0, event.reason)
    }
  }

  function handleError(message, file, line, column, err) {
    // avoid spamming the service if there are a lot of errors
    if (errors > MAX_ERRORS) {
      return
    }

    errors++

    // log the messages to the server
    log({ message, file, line, column, err })
  }
}

export function handleSessionError(err) {
  if (err.networkError && err.networkError.statusCode === 403) {
    window.location.reload()
    throw new Error('CSRF token expired')
  }
}
