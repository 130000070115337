import React, { Fragment, useContext, useState } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import getPagedProducts from '../../graphql/getPagedProducts'
import getPopularProducts from '../../graphql/getPopularProducts'
import { Container, Row, Col } from '../../components/grid'
import { Loader } from '../../components/loader'
import { HeroHeader } from '../../components/hero-header'
import { SectionIntro } from '../../components/section-intro'
import { GiftCard } from '../../components/gift-card'
import { useTrackImpression } from '../../utils/hooks/useTrackImpression'
import { handleSessionError } from '../../utils/error-tracking'
import { MessageFormat } from '@paypalcorp/worldready'
import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react'
import InfiniteScroll from 'react-infinite-scroll-component'

const media = {
  lg:
    'https://www.paypalobjects.com/digitalassets/c/gifts/us/balloons_d_01.mp4',
  sm:
    'https://www.paypalobjects.com/digitalassets/c/gifts/us/balloons_m_01.mp4',
}

const Cards = (props) => {
  const [page, setPage] = useState(1)
  const {
    data: getPagedProductsData,
    error: getPagedProductsError,
    loading: getPagedProductsLoading,
    fetchMore: getPagedProductsFetchMore,
  } = useQuery(getPagedProducts, {
    variables: { page, excludeSMB: true },
  })
  const {
    data: getPopularProductsData,
    error: getPopularProductsError,
  } = useQuery(getPopularProducts)
  const worldready = useContext(WorldReadyContext)

  useTrackImpression('main:consumer:gifts:all:', 'main:consumer:gifts:all::::')

  const { basepath } = props

  if (getPagedProductsError && getPopularProductsError) {
    handleSessionError(getPagedProductsError)
    handleSessionError(getPopularProductsError)
    return <Redirect to="/error" />
  }

  const fetchMoreLazyItems = () => {
    const nextPage = page + 1

    getPagedProductsFetchMore({
      variables: {
        page: nextPage,
        excludeSMB: true,
      },
    })

    setPage(nextPage)
  }

  const renderCardCategory = (items) => {
    return items.map((item, i) => {
      return (
        <Col md={3} key={`gift-card-${i}`} mb="x4">
          <GiftCard
            img={item.img_url}
            label={item.name}
            url={`${basepath || ''}${item.url_key}`}
            name="GiftCard"
            data-pagename="main:consumer:gifts:all:"
            data-pagename2={`main:consumer:gifts:all:${item.url_key}:::`}
          />
        </Col>
      )
    })
  }

  const renderCards = () => {
    if (getPagedProductsLoading) {
      return (
        <Container>
          <Loader p="100px 0" />
        </Container>
      )
    }

    return (
      <Container>
        {getPopularProductsData &&
          getPopularProductsData.getProductsInCategory &&
          getPopularProductsData.getProductsInCategory.all_a_z.length > 0 && (
            <Fragment>
              <Row>
                <Col>
                  <SectionIntro
                    mt="x4"
                    mb="x4"
                    title={
                      <Message id="pages/all-gift-cards.allgiftcards.mostPopular" />
                    }
                    eyebrow={
                      <Message id="pages/all-gift-cards.allgiftcards.label" />
                    }
                  />
                </Col>
              </Row>
              <Row>
                {renderCardCategory(
                  getPopularProductsData.getProductsInCategory.all_a_z,
                )}
              </Row>
            </Fragment>
          )}

        {getPagedProductsData &&
          getPagedProductsData.getPagedProducts &&
          getPagedProductsData.getPagedProducts.items.length > 0 && (
            <Fragment>
              <Row>
                <Col>
                  <SectionIntro
                    mt="x4"
                    mb="x4"
                    title={
                      <Message id="pages/all-gift-cards.allgiftcards.header" />
                    }
                    eyebrow={
                      <Message id="pages/all-gift-cards.allgiftcards.label" />
                    }
                  />
                </Col>
              </Row>
              <InfiniteScroll
                dataLength={getPagedProductsData.getPagedProducts.items.length}
                next={fetchMoreLazyItems}
                hasMore={
                  getPagedProductsData.getPagedProducts.items.length <
                  getPagedProductsData.getPagedProducts.total_items
                }
                loader={<Loader p="100px 0" />}
                style={{ overflow: 'hidden' }} // hides scrollbar
              >
                <Row>
                  {renderCardCategory(
                    getPagedProductsData.getPagedProducts.items,
                  )}
                </Row>
              </InfiniteScroll>
            </Fragment>
          )}
      </Container>
    )
  }

  const suggestions = new MessageFormat(worldready, {
    id: 'pages/suggestions.suggestions.all',
  }).format()

  return (
    <section>
      <HeroHeader
        headerStyle="search"
        headline={new MessageFormat(worldready, {
          id: 'pages/all-gift-cards.allgiftcards.giftcardsAZ',
        }).format()}
        searchSuggestions={suggestions ? suggestions.split(',') : []}
        searchType="cards"
        mediaSrc={media}
      />

      {renderCards()}
    </section>
  )
}

Cards.propTypes = {
  basepath: PropTypes.string,
}

export default withRouter(Cards)
