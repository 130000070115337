import { CurrencyFormat } from '@paypalcorp/worldready'
import { fractionDisplay } from './fraction-display'
import _ from 'lodash'

function isFixedPrice(variant) {
  return variant.price.type === 'fixed_price'
}

function sortDenominations(denominations) {
  denominations.sort(function (a, b) {
    if (a.denomination.value < b.denomination.value) return -1
    if (a.denomination.value > b.denomination.value) return 1
    return 0
  })

  return denominations
}

function mapDenominations(variant) {
  const {
    sku,
    price: {
      fixed_price_amount: amount = { currency_code: 'USD', value: 0 },
    } = {},
  } = variant

  return {
    sku,
    denomination: {
      currency_code: amount.currency_code,
      value: parseInt(amount.value, 10),
    },
  }
}

export const getFixedDisplayAmounts = (variants, worldready) => {
  const fixedPriceVariants = variants.filter(isFixedPrice)

  if (!fixedPriceVariants) {
    return null
  }

  const fixedDenominations = fixedPriceVariants.map(mapDenominations)
  const sortedDenominations = sortDenominations(fixedDenominations)

  const formatedDenominations = sortedDenominations.map((item) => {
    let value = parseFloat(_.get(item, 'denomination.value', 'NaN'))

    const displayAmount = !_.isNaN(value)
      ? new CurrencyFormat(worldready, {
          currency: item.denomination.currency_code,
          maximumFractionDigits: fractionDisplay(value),
        }).format(value / 100)
      : 'NaN'

    return {
      sku: item.sku,
      displayAmount,
      fixedAmount: {
        value: item.denomination.value,
        currency_code: item.denomination.currency_code,
      },
    }
  })

  return formatedDenominations
}
