import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Text } from '../text'
import { Box } from '../box'

import * as Styled from './Selector.styled'

const Selector = ({ label, name, options, value, onChange }) => {
  const [selected, setSelected] = useState(value || options)

  const handleValueChange = (newVal) => {
    setSelected(newVal)
    onChange(newVal)
  }

  return (
    <Box verticalAlign="middle" flexDirection="column">
      <Styled.LabelText
        textTransform="none"
        textStyle="eyebrow"
        color="secondary.greymidnight"
      >
        {label}
      </Styled.LabelText>
      <Styled.SelectorWrapper pt="1">
        {options.map((option, ind) => {
          const isActive = selected === option
          return (
            <Styled.Option
              as="button"
              color={isActive ? 'primary.white' : 'primary.black'}
              bg={isActive ? 'primary.bluemidnight' : 'primary.white'}
              border="1px solid"
              m="0"
              ml={ind > 0 ? '-1px' : '0'}
              zIndex={isActive ? '2' : '1'}
              borderColor={
                isActive ? 'primary.bluemidnight' : 'secondary.greylight'
              }
              key={`option_${option}`}
              isActive={isActive}
              index={ind}
              total={options.length}
              onClick={() => handleValueChange(option)}
            >
              <Text textStyle="subnav" textAlign="center">
                {option}
              </Text>
              <input
                type="radio"
                name={name}
                value={option}
                checked={isActive}
                onChange={handleValueChange}
              />
            </Styled.Option>
          )
        })}
      </Styled.SelectorWrapper>
    </Box>
  )
}

Selector.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
}

Selector.defaultProps = {
  name: 'selector',
  options: ['All', 'Cards', 'Bundles'],
  onChange: () => null,
}

export default Selector
