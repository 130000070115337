import styled from '@emotion/styled/macro'
import { Box } from '../box'

export const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px;
  border: 1px solid ${(props) => props.theme.colors.secondary.greylight};
  background-color: ${(props) => props.theme.colors.secondary.white};
  border-radius: 0.5rem;
  align-items: center;
  ${({ theme }) => theme.inViewStyle()}
`

export const CalloutInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-right: 15px;
`

export const CalloutImage = styled(Box)`
  border-radius: 0.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  img {
    display: block;
  }
`
