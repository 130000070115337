import { useState, useLayoutEffect } from 'react'
import theme from '../../theme'

const getCurrentBreakpoint = () => {
  const { breakpoints } = theme.breakpointData
  let newCurrentBreakpoint = 0
  const currentWidth = window.innerWidth
  breakpoints.some(bp => {
    if (currentWidth >= Math.floor(bp.value * (bp.unit === 'rem' ? 16 : 1))) {
      newCurrentBreakpoint++
      return false
    }
    return true
  })
  return newCurrentBreakpoint
}

export function useCurrentBreakpoint() {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(
    getCurrentBreakpoint(),
  )

  useLayoutEffect(() => {
    const handler = () => setCurrentBreakpoint(getCurrentBreakpoint())
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])

  return currentBreakpoint
}
