import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '../box'
import { Button } from '../button'
import { Carousel } from '../carousel'

const Suggestions = ({ suggestions, onChange }) => (
  <Carousel
    itemSpacing="x1"
    center
    disableSnap
    pageButtons={false}
    disableFaders
    items={suggestions.map((suggestion, i) => (
      <Box key={i}>
        <Button
          onClick={() => onChange(suggestion)}
          variant="filter"
          label={suggestion}
        />
      </Box>
    ))}
  />
)

Suggestions.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
}

Suggestions.defaultProps = {
  suggestions: [],
  onChange: () => null,
}

export default Suggestions
