import { css } from '@emotion/react/macro'
import styled from '@emotion/styled/macro'

export const hidden = css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
`
export const HiddenInput = styled.input`
  ${hidden}
`
