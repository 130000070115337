import React from 'react'
import Error from './common'
import { trackImpression } from '../../utils/analytics'
import PropTypes from 'prop-types'
import { Message } from '@paypalcorp/worldready-react'

class GenericError extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  componentDidMount() {
    trackImpression(
      'main:consumer:gifts:home:errorpage',
      'main:consumer:gifts:home:errorpage:::',
    )
  }

  render() {
    return (
      <Error
        history={this.props.history}
        title={<Message id="pages/error.error.header" />}
        buttonText={<Message id="pages/error.error.tryAgain" />}
        routeTo="/all"
      />
    )
  }
}

export default GenericError
