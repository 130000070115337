import lodash from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import gqlAddToCart from '../graphql/addToCart'
import { useApolloClient } from '@apollo/client'
import getMayflyData from '../graphql/getMayflyData'
import trackCartEvent from '../utils/track-cart-event'
import { handleAsyncError } from '../utils/error-tracking'
import React, { useState, createContext, useEffect } from 'react'

export const CartContext = createContext({})

export const CartContextProvider = ({ children }) => {
  const client = useApolloClient()
  const [updateQueue, setUpdateQueue] = useState([])
  const [loading, setLoading] = useState(false)
  const [cartItems, setCartItems] = useState([])

  async function fetchCartItems(cartId) {
    const res = await client.query({
      query: getMayflyData,
      variables: { mfKey: cartId },
      fetchPolicy: 'network-only',
    })

    return lodash.get(res, 'data.getMayflyData', [])
  }

  async function fetchAndUpdateCartItems(cartId) {
    const syncError = {
      file: 'cart-context',
      errMessagePrefix: 'Error when syncing cart',
    }

    await handleAsyncError(syncError, async () => {
      const cartData = await fetchCartItems(cartId)
      setCartItems(cartData)
      setLoading(false)
    })
  }

  const syncCartItems = async (cartId, forceUpdate) => {
    if (!cartId) {
      localStorage.removeItem('cart_id')
      setCartItems([])
      setLoading(false)
      return
    }

    if (!forceUpdate && localStorage.getItem('cart_id') === cartId) return

    localStorage.setItem('cart_id', cartId)
    fetchAndUpdateCartItems(cartId)
  }

  useEffect(() => {
    syncCartItems(localStorage.getItem('cart_id'), true)
  }, [])

  useEffect(() => {
    if (updateQueue.length === 0) return

    const lastUpdate = updateQueue[updateQueue.length - 1]

    // check if last update has resolved
    if (lastUpdate.resolvedCartId) {
      syncCartItems(lastUpdate.resolvedCartId)
      return
    }

    ;(async (_updateQueue) => {
      const resolvedUpdateQueue = await Promise.all(
        _updateQueue.map(async (update) => ({
          ...update,
          resolvedCartId: await update.promise,
        })),
      )

      setUpdateQueue((prevQueue) =>
        prevQueue.map((prevUpdate) => {
          const foundResolved = resolvedUpdateQueue.find(
            (resolved) => resolved.id === prevUpdate.id,
          )
          if (foundResolved) return foundResolved
          return prevUpdate
        }),
      )
    })([...updateQueue])
  }, [updateQueue])

  const generateCartItems = ({
    existingCartItems = [],
    cartItemToUpdate,
    updatedCartItem,
  }) => {
    const updatedCartItems = []
    let hasUpdatedItem = false // used to short circuit update to only a single item

    for (const item of existingCartItems) {
      if (!hasUpdatedItem && lodash.isEqual(item, cartItemToUpdate)) {
        if (updatedCartItem) updatedCartItems.push(updatedCartItem)
        hasUpdatedItem = true
        continue
      }

      updatedCartItems.push(item)
    }

    return updatedCartItems
  }

  const _updateCart = async ({
    source = '_updateCart',
    updatedCartItems = [],
    newCartItems = [],
    queueAsync = true,
    // eslint-disable-next-line consistent-return
  }) => {
    setCartItems([...updatedCartItems, ...newCartItems])

    const promise = handleAsyncError(
      {
        file: 'cart-context',
        errMessagePrefix: `Graphql error on ${source}`,
      },
      async () => {
        const response = await client.mutate({
          mutation: gqlAddToCart,
          variables: {
            cartInfo: { cartItems: updatedCartItems, newItems: newCartItems },
          },
        })

        const newCartId = lodash.get(response, 'data.addToCart.cart_id')
        return newCartId
      },
    )

    if (queueAsync) {
      setUpdateQueue((prevUpdateQueue) => [
        ...prevUpdateQueue,
        {
          id: uuidv4(),
          promise,
        },
      ])
    }

    if (!queueAsync) {
      return syncCartItems(await promise)
    }
  }

  const addToCart = (newItems) => {
    setLoading(true)
    trackCartEvent('setCart', [...cartItems, ...newItems])

    return _updateCart({
      source: 'addToCart',
      updatedCartItems: cartItems,
      newCartItems: newItems,
      queueAsync: false,
    })
  }

  const removeCartItem = (itemToRemove) => {
    const filteredCartItems = generateCartItems({
      existingCartItems: cartItems,
      cartItemToUpdate: itemToRemove,
    })

    trackCartEvent('removeFromCart', cartItems, itemToRemove)

    _updateCart({
      source: 'removeCartItem',
      updatedCartItems: filteredCartItems,
    })
  }

  const updateCartItem = (itemToUpdate, updatedItem) => {
    const updatedCartItems = generateCartItems({
      existingCartItems: cartItems,
      cartItemToUpdate: itemToUpdate,
      updatedCartItem: updatedItem,
    })

    _updateCart({
      source: 'removeCartItem',
      updatedCartItems,
    })
  }

  const resetCartItems = () => syncCartItems(null)

  return (
    <CartContext.Provider
      value={{
        loading,
        cartItems,
        addToCart,
        removeCartItem,
        updateCartItem,
        resetCartItems,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
