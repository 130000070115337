import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import * as Styled from './Button.styled'

const Button = ({ children, label, href, as, ...rest }) => {
  const buttonComp = (
    <Styled.Button className="button" {...rest} as={as}>
      {label || children}
    </Styled.Button>
  )

  if (!href) return buttonComp

  return <Link to={href}>{buttonComp}</Link>
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  href: PropTypes.string,
  variant: PropTypes.string,
  as: PropTypes.string,
}

export default Button
