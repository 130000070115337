const GIFT_BUNDLES = 'Gift Bundles'
const MINI_BUNDLES = 'Mini Bundles'

export const isSMB = (item) => {
  const SMB_CATEGORY = /^Small Business$/i
  const categories = item.categories || []
  return categories.some((category) => SMB_CATEGORY.test(category.trim()))
}

export function isPopular(item) {
  // FIXME l10n for other languages
  const regexp = /(popular|populaires|beliebtesten)/i
  return item.categories.some((category) => regexp.test(category))
}

export function filterBundles(items) {
  return (
    items.categories.indexOf(GIFT_BUNDLES) === -1 &&
    items.categories.indexOf(MINI_BUNDLES) === -1
  )
}

export function filterCards(items) {
  return (
    items.categories.indexOf(GIFT_BUNDLES) >= 0 ||
    items.categories.indexOf(MINI_BUNDLES) >= 0
  )
}

export function filterOnlyBundles(items) {
  return items.categories.indexOf(GIFT_BUNDLES) >= 0
}

export function filterOnlyMiniBundles(items) {
  return items.categories.indexOf(MINI_BUNDLES) >= 0
}
