// import global styles as the very first thing to avoid flash of unstyled content
import './css'
import './global.css'

import { startClientErrorTracking } from './utils/error-tracking'
import { trackElement } from './utils/analytics'

import 'intersection-observer'
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

// let webpack know how to resolve dependencies for static assets
// and dynamic imports
__webpack_public_path__ = window.webpackPublicPath

// wait for the dom to be loaded before attempting to start up the app
document.addEventListener('DOMContentLoaded', () => {
  startClientErrorTracking()

  const urlParams = new URLSearchParams(window.location.search)
  // Prevent over-writing when redirected back from the payments page
  if (sessionStorage && sessionStorage.getItem('utm_source') === null) {
    sessionStorage.setItem(
      'utm_source',
      urlParams.get('utm_source') || urlParams.get('entry') || '',
    )
  }

  // Reference - http://youmightnotneedjquery.com/
  document.body.addEventListener(
    'click',
    function (event) {
      // loop parent nodes from the target to the delegation node
      for (
        let target = event.target;
        target && target !== this;
        target = target.parentNode
      ) {
        if (target.matches('[data-pagename]')) {
          trackElement(target)
          break
        }
      }
    },
    false,
  )

  // this is the only `require` that should be in our app. Using `require` here
  // allows us to defer import resolution until after the DOMContentLoaded
  // event is fired.
  require('./render')
})
