/*
 * This is a function that gives you back an Icon component. It has logic
 * for whether to use a className (if you provide an `icon`
 * prop or an svg (using svg-inline-react) if you provide
 * an `svg` prop, which would be something you import like:
 * `import mySvg from './my-svg'`
 */
import React from 'react'
import PropTypes from 'prop-types'
import InlineSVG from 'svg-inline-react'
import styled from '@emotion/styled/macro'

// This Icon accepts the className and applies it to the thing that
// should be styled based on whether an icon or svg is provided
function Icon({ icon, svg, className, ...rest }) {
  if (svg) {
    return (
      <InlineSVG
        src={svg}
        className={`icon-svg ${className}`}
        aria-hidden="true"
        {...rest}
      />
    )
  } else if (icon) {
    return (
      <span
        className={`icon icon-${icon} ${className}`}
        aria-hidden="true"
        {...rest}
      />
    )
  }
  return null
}

Icon.propTypes = {
  icon: PropTypes.string,
  svg: PropTypes.string,
  className: PropTypes.string,
}

const styledIcon = styled(Icon, {
  rootEl: 'svg',
  forwardProps: ['svg', 'icon'],
})

export default styledIcon
