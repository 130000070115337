import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import { withRouter } from 'react-router-dom'
import {
  ScheduleSvg,
  NeverExpiresSvg,
  AddPersonalMsgSvg,
} from '../shared/icons/svg-images'
import styledIcon from '../shared/icons'
import { Message } from '@paypalcorp/worldready-react'
import {
  Container,
  Row as PPRow,
  Col,
  Button as PPButton,
} from '@paypalcorp/pp-react'

const Div = styled.div`
  text-align: center;
  margin: 12px 0;
`

const Label = styled.div`
  text-align: center;
  font-size: 30px;
  padding-bottom: 36px;
`

const Row = styled(PPRow)`
  color: #4a4a4a;
  margin-left: 80px;
  margin-right: 80px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const Button = styled(PPButton)`
  width: 270px;
  height: 48px;
  border-radius: 24px;
  padding: 0;

  @media only screen and (max-width: 768px) {
    width: 271px;
    height: 36px;
    max-width: 350px;
  }
`

const Option = styled(Col)`
  display: flex;
  flex-flow: column nowrap;

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    margin-bottom: 36;
  }
`

const Text = styled.div``

const IconContainer = styled.div`
  display: flex;
  padding-top: 24;
  padding-bottom: 60;
  margin-top: auto;

  @media only screen and (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const ScheduleTheDeliveryICON = styledIcon`
  flex: 1 1 auto;

  & svg {
    height: 88.2px;
    width: 79.1px;
  }
`

ScheduleTheDeliveryICON.defaultProps = { svg: ScheduleSvg }

const NeverExpiresICON = styledIcon`
  flex: 1 1 auto;

  & svg {
    height: 88.2px;
    width: 79.1px;
  }
`

NeverExpiresICON.defaultProps = { svg: NeverExpiresSvg }

const PersonalMsgICON = styledIcon`
  flex: 1 1 auto;

  & svg {
    height: 88.2px;
    width: 79.1px;
  }
`

PersonalMsgICON.defaultProps = { svg: AddPersonalMsgSvg }

class GiftOfChoice extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    parentPage: PropTypes.string,
  }

  handleBrowseAll = () => {
    this.props.history.push('/all')
  }

  render() {
    return (
      <Container>
        <Div style={{ paddingTop: '12px' }}>
          <Button
            onClick={this.handleBrowseAll}
            name="SeeAllGiftCardsButton"
            data-pagename={`main:consumer:gifts:home:${
              this.props.parentPage || ''
            }`}
            data-pagename2={`main:consumer:gifts:home:${
              this.props.parentPage || ''
            }:::`}
          >
            <Message id="pages/landing.landing.btn.seeThemAll" />
          </Button>
        </Div>

        <Label>
          <Message id="pages/landing.landing.moreLoveLessHassle" />
        </Label>

        <Container>
          <Row className="vx_text-4">
            <Option sm="12" md="4">
              <Text>
                <Message id="pages/landing.landing.sendAnyDay" />
              </Text>
              <IconContainer>
                <ScheduleTheDeliveryICON />
              </IconContainer>
            </Option>

            <Option sm="12" md="4">
              <Text>
                <Message id="pages/landing.landing.neverExpire" />
              </Text>
              <IconContainer>
                <NeverExpiresICON />
              </IconContainer>
            </Option>

            <Option sm="12" md="4">
              <Text>
                <Message id="pages/landing.landing.personalTouch" />
              </Text>

              <IconContainer>
                <PersonalMsgICON />
              </IconContainer>
            </Option>
          </Row>
        </Container>
      </Container>
    )
  }
}

export default withRouter(GiftOfChoice)
