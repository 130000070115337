import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Box } from '../box'
import { ImageLazy } from '../image-lazy'
import * as Styled from './PromoCard.styled'
import { useCurrentBreakpoint } from '../../utils/hooks/useCurrentBreakpoint'
import { Deal } from '../deal'

const PromoCard = ({
  smImg,
  lgImg,
  title,
  banner,
  ticker,
  href,
  terms,
  isMarketingPromo,
  ...props
}) => {
  const cbp = useCurrentBreakpoint()
  const defaultImg = smImg || lgImg
  const curImg = (cbp < 1 ? smImg : lgImg) || defaultImg
  return (
    <Styled.CardWrapper {...props}>
      <Link to={href}>
        {defaultImg && (
          <Styled.ImgContainer>
            <ImageLazy src={curImg} />
            {banner && (
              <Styled.BannerImage
                data-testid="banner-image"
                src="/images/giftwrap-br.png"
                alt=""
              />
            )}
          </Styled.ImgContainer>
        )}
        <Box
          position="relative"
          display="flex"
          flexDirection={{ _: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          pt={['x1', 'x2']}
        >
          <Styled.TitleText as="h4" textStyle="h4" py={['x2', 0]}>
            {title}
          </Styled.TitleText>
          {!isMarketingPromo && !!ticker && ticker}
        </Box>
      </Link>
      <Deal offer={{ terms }} noIcon />
    </Styled.CardWrapper>
  )
}

PromoCard.propTypes = {
  smImg: PropTypes.string.isRequired,
  lgImg: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  banner: PropTypes.bool,
  ticker: PropTypes.element,
  href: PropTypes.string,
  terms: PropTypes.string,
  isMarketingPromo: PropTypes.bool,
}

PromoCard.defaultProps = {
  href: '/deals',
}

export default PromoCard
