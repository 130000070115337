import React from 'react'
import styled from '@emotion/styled/macro'
import styledIcon from '../shared/icons'
import { CalendarSvg } from '../shared/icons/svg-images'
import { FlexBox } from '../../components/box'
import {
  Link,
  Button as PPButton,
  OffersRewardsIcon as PPOffersRewardsIcon,
} from '@paypalcorp/pp-react'

export const CalenderInputDiv = styled.div`
  height: 48px;
  width: 100%;
  display: inline-block;
  border: solid 1px #b7bcbf;
  border-radius: 5px;
  padding-left: 13px;
`

export const DateLabel = styled.label`
  display: block;
  font-size: 12px;
  color: #687173;
  margin-bottom: 0;
  height: 15px;
`

export const DateInput = styled.input`
  display: table-cell;
  border: none;
  outline: none;
  font-size: 15px;
  padding-right: 228px;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
`

export const CalendarICON = styledIcon`
  & svg {
    height: 21px;
    width: 25px;
  }
`

CalendarICON.defaultProps = { svg: CalendarSvg }

export const Header = styled.h1`
  font-size: 30px;
  font-weight: 300;
  color: #2c2e2f;
  text-align: center;
  padding-top: 60px;
  margin: 0 12px;
`

export const ImgDiv = styled.div`
  margin: 48px 0;
  display: block;
  text-align: center;
`

export const Img = styled.img`
  width: 200px;
  height: 126px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 2px 3px 6px 0 #9b9b9b;
`

export const BundleItem = styled(FlexBox)`
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary.greylight};
`

export const BundleImg = styled.img`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 2px 3px 6px 0 #9b9b9b;
  max-width: 320px;
`

export const CardImage = styled.img`
  border-radius: 4px;
  max-width: 100px;
`

export const Description = styled.div`
  text-align: left;
  font-size: 15px;
  font-weight: 300;
  color: #2c2e2f;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
    padding-bottom: 24px;
  }
`

export const OfferTermsSections = styled.div`
  margin-bottom: 12px;
`

export const OffersRewardsIcon = styled(PPOffersRewardsIcon)`
  padding-right: 7px;

  & path {
    fill: ${({ theme }) => theme.colors.primary.bluemidnight};
  }
`

export const MerchantTnCSection = styled.div`
  margin-bottom: 36px;
`

export const Promotion = styled.div`
  padding-top: 24px;
`

export const Tab = styled.div`
  padding-top: 24px;
  padding-bottom: 22px;
`

export const InputContainer = styled.div`
  position: relative;
  padding-top: 0;
  margin-top: 1em;

  & .vx_form-control-error-icon {
    display: none;
  }
`

export const NoteTextInput = styled.textarea`
  padding: 18px 12px 12px;
  height: 156px;
  display: table-cell;
  width: 100%;
  border: 1px solid #9da3a6;
  border-radius: 5px;
  background: #ffffff;
  resize: none;
`

export const NoteLabel = styled.label`
  position: absolute;
  top: 12px;
  font-size: 15px;
  line-height: 20px;
  left: 12px;
  color: rgb(108, 115, 120);
  transition: all 0.25s ease-in-out;
  transform-origin: left top 0px;
`

const TabButton = (props) => <Link as="button" {...props} />

export const MeTab = styled(TabButton)`
  width: 50%;
  height: 60px;
  font-size: 15px;
  background-color: transparent;
  position: relative;
  border: none;
  text-align: center;

  &:focus {
    outline: none;
  }
`

export const GiftTab = MeTab

export const Button = styled(PPButton)`
  width: 270px;
  height: 48px;
  border-radius: 50px;
  background-color: #0070ba;
  margin: 48px 0 60px;

  @media only screen and (max-width: 768px) {
    width: 271px;
  }
`

export const Content = styled.div`
  margin: 0 174px;

  @media only screen and (max-width: 768px) {
    margin: 0 12px;
  }
`

export const ClickedStyle = {
  color: '#0070ba',
  borderBottom: `3px solid #0070ba`,
}

export const DefaultStyle = {
  color: '#2c2e2f',
  borderBottom: 'solid 1px #cbd2d6',
}

export const PromoDes = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.secondary.greymidnight};
`

export const ErrorDiv = styled.div`
  font-size: 13px;
  color: #d20000;
`
