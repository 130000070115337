import PropTypes from 'prop-types'
import { Button } from '../button'
import { Box, FlexBox } from '../box'
import { useForm } from 'react-hook-form'
import { HiddenInput } from '../../utils/styled'
import React, { useState, useContext } from 'react'
import isEmailValid from '../../utils/validate-email'
import { MessageFormat } from '@paypalcorp/worldready'
import { CartContext } from '../../context/cart-context'
import { FormToggleButton } from './Form.styled'
import { FormTextInput, FormCalendarInput } from './index'
import { log, handleSessionError } from '../../utils/error-tracking'

import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react'

const Form = (props) => {
  const [isGifting, setIsGifting] = useState(true)
  const [emailValid, setEmailValid] = useState(false)
  const [selfPurchaseSuppressed] = useState(true)
  const { register, handleSubmit } = useForm()
  const worldready = useContext(WorldReadyContext)
  const { items } = props.bundleCart

  const { addToCart, loading: cartLoading } = useContext(CartContext)

  const handleToggle = (event) => {
    setIsGifting(event.target.value === 'forYou')
  }

  const addRecipientInfo = async (info) => {
    if (!isGifting) {
      info.senderName = ''
      info.recipientEmail = ''
      info.note = ''
      info.selectedDay = new Date().toString()
    }

    return await Promise.all(
      items.map((item) => {
        const cartPayload = {
          ...item,
          ...info,
          isGifting,
        }

        return cartPayload
      }),
    )
  }

  const onSubmit = (formData) => {
    if (isGifting) {
      const emailIsValid = isEmailValid(formData.recipientEmail)
      setEmailValid(!emailIsValid)
      if (!emailIsValid) return
    }

    addRecipientInfo(formData)
      .then((data) => {
        addToCart(data)
      })
      .catch((error) => {
        handleSessionError(error)
        log({
          file: 'custom-bundle-checkout',
          err: { stack: 'Add to cart Form error from bundle checkout' },
        })
      })
  }

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)}>
      {!selfPurchaseSuppressed && (
        <FlexBox width="100%">
          <FormToggleButton isActive={isGifting}>
            <HiddenInput
              onChange={handleToggle}
              value="forYou"
              type="radio"
              name="isGifting"
              ref={register}
              checked={isGifting}
            />
            <Message id="pages/product.product.giftTab" />
          </FormToggleButton>
          <FormToggleButton isActive={!isGifting}>
            <HiddenInput
              onChange={handleToggle}
              value="forMe"
              type="radio"
              name="isGifting"
              ref={register}
            />
            <Message id="pages/product.product.meTab" />
          </FormToggleButton>
        </FlexBox>
      )}

      {isGifting ? (
        <Box pb={3}>
          <FormTextInput
            ref={register}
            name="recipientEmail"
            label={new MessageFormat(worldready, {
              id: 'pages/product.product.theirEmail',
            }).format()}
            data="recipientEmail"
            inValid={emailValid}
          />
          <FormTextInput
            ref={register}
            name="senderName"
            label={new MessageFormat(worldready, {
              id: 'pages/product.product.yourName',
            }).format()}
            data="senderName"
          />
          <FormTextInput
            ref={register}
            name="note"
            multiline
            label={new MessageFormat(worldready, {
              id: 'pages/product.product.yourNote',
            }).format()}
            data="note"
          />
          <FormCalendarInput
            ref={register}
            name="selectedDay"
            data="selectedDay"
          />
        </Box>
      ) : (
        <Box pb={3}>
          <Message id="pages/product.product.buyForMe" />
        </Box>
      )}
      <Button
        width="100%"
        variant="secondary"
        type="submit"
        label={
          cartLoading
            ? 'Adding to cart...'
            : new MessageFormat(worldready, {
                id: 'pages/product.product.btn',
              }).format()
        }
        name="AddToCart"
        data-testid="add-to-cart-button"
        disabled={cartLoading}
        data-pagename={props.pgrp}
        data-pagename2={props.page}
      />
    </form>
  )
}

Form.propTypes = {
  page: PropTypes.string,
  pgrp: PropTypes.string,
  bundleCart: PropTypes.object,
}

export default Form
