import clientData from '../../utils/client-data'
import React, { useState, useEffect } from 'react'
import { Message } from '@paypalcorp/worldready-react'
import { useQuery } from '@apollo/client'
import { useLocation, useHistory, matchPath } from 'react-router-dom'
import getSearchHeroVisibility from '../../graphql/getSearchHeroVisibility'
import { useCurrentBreakpoint } from '../../utils/hooks/useCurrentBreakpoint'

import NavBar from './nav-bar.component'
import Cart from '../cart'

const NavBarWrapper = () => {
  const history = useHistory()
  const location = useLocation()
  const breakpoint = useCurrentBreakpoint()

  const [searchHeroVisible, setSearchHeroVisibility] = useState(true)
  const { data: searchHeroVisibility } = useQuery(getSearchHeroVisibility)

  useEffect(() => {
    if (!searchHeroVisibility) return
    setSearchHeroVisibility(searchHeroVisibility.searchHeroInView)
  }, [searchHeroVisibility])

  // don't show nav search bar on search page
  const isSearchPage = matchPath(location.pathname, {
    path: '/search',
    exact: false,
  })

  const onSearchSubmit = (value) => {
    if (value.length) history.push(`/search/all?q=${encodeURIComponent(value)}`)
  }

  return (
    <NavBar
      items={[
        {
          label: <Message id="pages/nav-bar.nav.home" />,
          to: '/',
          name: 'Home',
          exact: true,
          dataPagename: 'main:consumer:gifts:home:',
          dataPagename2: 'main:consumer:gifts:home::::',
          enabled: true,
        },
        {
          label: <Message id="pages/nav-bar.nav.cards" />,
          to: '/all',
          name: 'AllGiftCards',
          exact: true,
          dataPagename: 'main:consumer:gifts:home:',
          dataPagename2: 'main:consumer:gifts:cards::::',
          enabled: true,
        },
        {
          label: <Message id="pages/nav-bar.nav.occasions" />,
          to: '/occasions',
          name: 'Occasions',
          exact: true,
          dataPagename: 'main:consumer:gifts:home:',
          dataPagename2: 'main:consumer:gifts:occasions::::',
          enabled: !!clientData.experimentation.v2occasions,
        },
        {
          label: <Message id="pages/nav-bar.nav.bundles" />,
          to: '/bundles',
          name: 'Bundles',
          exact: true,
          dataPagename: 'main:consumer:gifts:home:',
          dataPagename2: 'main:consumer:gifts:bundles::::',
          enabled: !!clientData.experimentation.v2bundles,
        },
        {
          label: <Message id="pages/nav-bar.nav.localBusinesses" />,
          to: '/business',
          name: 'Local Businesses',
          exact: true,
          dataPagename: 'main:consumer:gifts:home:',
          dataPagename2: 'main:consumer:gifts:business::::',
          enabled: !!clientData.experimentation.localBusinesses,
        },
        {
          label: <Message id="pages/nav-bar.nav.giftGuides" />,
          to: '/gift-guides',
          name: 'Gift Guides',
          exact: false,
          dataPagename: 'main:consumer:gifts:home:',
          dataPagename2: 'main:consumer:gifts:guides::::',
          enabled: !!clientData.experimentation.v2guides,
        },
        {
          label: <Message id="pages/nav-bar.nav.categories" />,
          to: '/categories',
          name: 'Categories',
          exact: true,
          dataPagename: 'main:consumer:gifts:home:',
          dataPagename2: 'main:consumer:gifts:categories::::',
          enabled: !!clientData.experimentation.categoriesTab,
        },
        {
          label: <Message id="pages/nav-bar.nav.deals" />,
          to: '/deals',
          name: 'Deals',
          exact: true,
          dataPagename: 'main:consumer:gifts:home:',
          dataPagename2: 'main:consumer:gifts:deals::::',
          enabled: true,
        },
      ].filter(({ enabled = true }) => enabled)}
      hasSearchInput={!isSearchPage && breakpoint > 1}
      searchInputVisible={!searchHeroVisible}
      onSearch={onSearchSubmit}
      CartComponent={<Cart />}
    />
  )
}

export default NavBarWrapper