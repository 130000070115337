import styled from '@emotion/styled/macro'
import { Box } from '../box'
import { Text } from '../text'

export const EditorialHero = styled(Box)`
  position: relative;
  // background-size: cover;
  // background-position: center center;
  // background-image: url(${(props) => props.imgUrl});
  margin: auto;
  overflow: hidden;
`
export const BgContainer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s ease-out;
  transform: scale(1.15);

  video {
    object-fit: cover;
  }

  .animated-in & {
    opacity: 1;
    transform: scale(1);
  }
`

export const Container = styled.div`
  position: absolute;
  bottom: 40px;
  width: 100%;

  div {
    justify-content: center;
  }
`

export const TextContainer = styled.div`
  text-align: center;
  color: white;
`
export const Eyebrow = styled(Text)`
  opacity: 0;
  transition: 0.5s ease-out;
  transform: translateY(20px);
  transition-delay: 0.15s;
  text-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);

  .animated-in & {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const Headline = styled(Text)`
  opacity: 0;
  transition: 0.5s ease-out;
  transition-delay: 0.4s;
  transform: translateY(20px);

  .animated-in & {
    opacity: 1;
    transform: translateY(0px);
  }
`
