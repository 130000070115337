import styled from '@emotion/styled/macro'

export const Wrapper = styled.div`
  width: 270px;
  position: relative;

  .DayPicker {
    display: inline-block;
  }

  .DayPicker-wrapper {
    position: relative;
    user-select: none;
    padding-bottom: 1rem;
    flex-direction: row;
    ::after,
    ::before {
      content: '';
      position: absolute;
      left: 10%;
      top: -11px;
      width: 0;
      height: 0;
    }

    ::before {
      top: -9px;
      z-index: 2;
      border-bottom: 12px solid ${(props) => props.theme.colors.primary.white};
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
    }

    ::after {
      top: -11px;
      z-index: 1;
      border-bottom: 12px solid
        ${(props) => props.theme.colors.primary.bluemidnight};
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
    }
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border: solid 1px ${(props) => props.theme.colors.primary.bluemidnight};
    border-radius: 5px;
  }

  .DayPicker-Month {
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    user-select: none;
    margin: 0 1rem;
    margin-top: 1rem;
  }

  .DayPicker-NavButton {
    position: absolute;
    cursor: pointer;
    top: 1rem;
    right: 1.5rem;
    margin-top: 2px;
    color: ${(props) => props.theme.colors.secondary.greymedium};
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .DayPicker-NavButton:hover {
    opacity: 0.8;
  }

  .DayPicker-NavButton--prev {
    margin-right: 1.5rem;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
  }

  .DayPicker-NavButton--next {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    padding: 0 0.5rem;
    display: table-caption;
    text-align: left;
    margin-bottom: 0.5rem;
    font-size: 20px;
    line-height: 1.25;
    color: ${(props) => props.theme.colors.primary.bluemidnight};
  }

  .DayPicker-Caption > div {
    font-size: 1.15rem;
    font-weight: 400;
  }

  .DayPicker-Weekdays {
    margin-top: 1rem;
    display: table-header-group;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5rem;
    font-size: 0.875em;
    text-align: center;
    color: ${(props) => props.theme.colors.secondary.greymedium};
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    outline: none;
  }

  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5rem;
    text-align: right;
    vertical-align: middle;
    min-width: 1rem;
    font-size: 0.75em;
    cursor: pointer;
    color: ${(props) => props.theme.colors.secondary.greymedium};
    border-right: 1px solid
      ${(props) => props.theme.colors.secondary.greymedium};
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: 0.5rem;
  }

  .DayPicker-TodayButton {
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary.bluemidnight};
    font-size: 0.875em;
  }

  /* Default modifiers */

  .DayPicker-Day--today {
    background-color: ${(props) => props.theme.colors.primary.bluemidnight};
    color: ${(props) => props.theme.colors.primary.white};
    border-radius: 25px;
    font-weight: 700;
  }

  .DayPicker-Day--outside {
    cursor: default;
    color: ${(props) => props.theme.colors.secondary.greymedium};
  }

  .DayPicker-Day--disabled {
    color: ${(props) => props.theme.colors.secondary.greylight};
    cursor: default;
    /* background-color: ${(props) =>
      props.theme.colors.secondary.greylight}; */
  }

  /* Example modifiers */

  .DayPicker-Day--sunday {
    background-color: ${(props) => props.theme.colors.secondary.greylight};
  }

  .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: ${(props) => props.theme.colors.secondary.greylight};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    color: ${(props) => props.theme.colors.primary.white};
    background-color: ${(props) => props.theme.colors.primary.bluemidnight};
    border-radius: 100%;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: ${(props) => props.theme.colors.primary.bluemidnight};
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: ${(props) => props.theme.colors.primary.white};
    border-radius: 50%;
  }

  /* DayPickerInput */

  .DayPickerInput {
    display: inline-block;
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    left: 0;
    z-index: 1;
    position: absolute;
    background: ${(props) => props.theme.colors.primary.white};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }
`

export const DayPickerContainer = styled.div`
  margin-top: 16px;
`
