import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react/macro'
import { useInView } from 'react-hook-inview'
import { giftGuidesPath } from '../../gift-guide-routes'
import { ImageLazy } from '../image-lazy'
import { Text } from '../text'
import { BannerGiftWrap } from '../banner-giftwrap'
import { Message } from '@paypalcorp/worldready-react'

import * as Styled from './GuideSummary.styled'

const GuideSummary = ({ card, banner, showEyebrow }) => {
  const url = `${giftGuidesPath}${card.path}`

  const theme = useTheme()
  const [ref, inView] = useInView({
    threshold: 0.25,
    unobserveOnEnter: true,
  })

  return (
    <Styled.Card to={url} ref={ref} className={inView ? theme.inViewClass : ''}>
      <Styled.CardImage>
        <ImageLazy bgSrc={card.coverImage} />
        {banner && <BannerGiftWrap />}
      </Styled.CardImage>
      {showEyebrow && (
        <Text as="div" textStyle="eyebrow2" mt="x2">
          <Message id={`gift-guides/${card.id}.section.hero.eyebrow`} />
        </Text>
      )}
      <Text as="h3" textStyle="h3" mt="x2">
        <Message id={`gift-guides/${card.id}.section.hero.headline`} />
      </Text>
      <Text textStyle="guideDescription" mt="x2">
        <Message id={`gift-guides/${card.id}.section.cover.description`} />
      </Text>
    </Styled.Card>
  )
}

GuideSummary.propTypes = {
  card: PropTypes.object,
  banner: PropTypes.bool,
  showEyebrow: PropTypes.bool,
}

GuideSummary.defaultProps = {
  showEyebrow: false,
}

export default GuideSummary
