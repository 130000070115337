import React from 'react'
import styled from '@emotion/styled/macro'
import { css } from '@emotion/react/macro'
import { Button as PPButton } from '@paypalcorp/pp-react'
import { color, space, layout, buttonStyle } from 'styled-system'

const disabledState = ({ variant, theme: { colors } }) => {
  if (variant === 'primary' || variant === 'small')
    return css`
      cursor: default !important;
      pointer-events: none;
      border-color: ${colors.secondary.greylight} !important;
      color: ${colors.secondary.greylight} !important;
      background-color: transparent !important;
    `
  if (variant === 'secondary' || variant === 'smallSecondary')
    return css`
      cursor: default !important;
      pointer-events: none;
      border-color: transparent !important;
      color: ${colors.primary.white} !important;
      background-color: ${colors.secondary.greylight} !important;
    `
  return null
}

const selectedState = ({ variant, theme: { colors } }) => {
  if (variant === 'filter')
    return css`
      background-color: ${colors.primary.bluemidnight};
      border-color: transparent;

      &,
      &:hover {
        color: ${colors.primary.white};
      }
    `

  if (variant === 'price')
    return css`
      background-color: ${colors.primary.bluemidnight};
      border-color: transparent;

      &,
      &:hover {
        color: ${colors.primary.white};
      }
    `
  return null
}

// proxy to filter out invalid props
const ProxyPPButton = ({
  maxWidth: _maxWidth,
  minWidth: _minWidth,
  ...rest
}) => <PPButton {...rest} />

export const Button = styled(ProxyPPButton)`
  &,
  &:active,
  &:focus,
  &:visited {
    border-radius: 2rem;
    border-color: inherit;
    transition: 0.2s ease-out;
    display: inline-block;
    text-align: center;
    cursor: pointer;

    ${buttonStyle}
    ${color}
    ${space}
    ${layout}

    /* buttons states */
    ${(props) =>
      props.disabled && disabledState}
    ${(props) =>
      props.selected && selectedState}
  }
`
