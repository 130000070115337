import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '../../components/box'
import { Row, Col } from '../../components/grid'

export const ResultsHeader = ({ children }) => {
  return (
    <Row py="2">
      <Col>
        <Box pb="2" borderBottom="1px solid" borderColor="secondary.greylight">
          {children}
        </Box>
      </Col>
    </Row>
  )
}

ResultsHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
