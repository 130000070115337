import React from 'react'
import styled from '@emotion/styled/macro'
import { Link as PPLink } from '@paypalcorp/pp-react'
import { NavLink as RouterNavLink } from 'react-router-dom'

const CustomLink = (props) => <PPLink as={RouterNavLink} {...props} />

export const NavLink = styled(CustomLink)`
  display: flex;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    text-decoration: none;
  }

  p {
    color: ${(props) => props.theme.colors.secondary.greymidnight};
  }

  @media (hover: hover) {
    &:hover p,
    &.active:hover {
      color: ${(props) => props.theme.colors.primary.black};
    }
  }

  &.active p {
    color: ${(props) => props.theme.colors.primary.black};
  }
`
