import { React } from 'react'

import { Helmet } from 'react-helmet'
import { usePageMetadata } from './utils/hooks/usePageMetadata'

const Metadata = () => {
  const { pageMetadata, error } = usePageMetadata()

  if (error) {
    return null
  }

  const { title, description, keywords, canonical, image } = pageMetadata

  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <title>{title}</title>

      <link rel="canonical" href={canonical} />
    </Helmet>
  )
}

export default Metadata
