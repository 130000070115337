import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'

export const Card = styled(Link)`
  display: block;
  ${({ theme }) => theme.inViewStyle()}

  &:hover {
    text-decoration: none;
  }

  &,
  &:visited {
    color: inherit;
  }
`

export const CardImage = styled.div`
  background: ${(props) => props.theme.colors.secondary.greylight};
  color: inherit;

  position: relative;
  border-radius: 0.5rem;

  overflow: hidden;

  height: 0%;
  width: 100%;
  padding-bottom: 128%;
`
