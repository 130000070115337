import React, { Fragment } from 'react'
import { useInView } from 'react-hook-inview'
import { useTheme } from '@emotion/react/macro'
import PropTypes from 'prop-types'
import { ImageLazy } from '../image-lazy'
import { Text } from '../text'
import { Box } from '../box'
import * as Styled from './GiftCard.styled'

const GiftCard = ({ img, alt, label, url, ...props }) => {
  const [ref, inView] = useInView({
    threshold: 0.25,
    unobserveOnEnter: true,
  })

  const theme = useTheme()

  const cardContent = (
    <Fragment>
      <Styled.CardImage>
        <ImageLazy src={img} alt={alt || ''} />
      </Styled.CardImage>
      {label && (
        <Text as="h3" textStyle="cardTitle" color="secondary.greydark" mt="x2">
          {label}
        </Text>
      )}
    </Fragment>
  )

  if (!url) return <Box {...props}>{cardContent}</Box>

  return (
    <Styled.GiftCard
      ref={ref}
      to={url}
      {...props}
      className={inView ? theme.inViewClass : ''}
    >
      <Styled.CardWrapper>{cardContent}</Styled.CardWrapper>
    </Styled.GiftCard>
  )
}

GiftCard.propTypes = {
  img: PropTypes.string.isRequired,
  label: PropTypes.string,
  url: PropTypes.string,
  alt: PropTypes.string,
}

export default GiftCard
