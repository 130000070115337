import { gql } from '@apollo/client'

export default gql`
  query getMayflyData($mfKey: String) {
    getMayflyData(mfKey: $mfKey) {
      quantity
      productName
      productTermsAndConditions
      urlKey
      sku
      imgUrl
      isGifting
      recipientEmail
      senderName
      note
      selectedDay
      customAmount {
        currency_code
        value
      }
      amountToPay {
        currency_code
        value
      }
      totalAmount {
        currency_code
        value
      }
      discounts {
        id
        name
        amount
      }
      uuid
    }
  }
`
