import styled from '@emotion/styled/macro'
import { position, space, layout, flexbox } from 'styled-system'

import { Box } from '../box'

export const VideoWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
  ${position}
  ${space}
  ${layout}
  ${flexbox}

  video {
    // position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
`
