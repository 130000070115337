import { gql } from '@apollo/client'

export default gql`
  query getDealsInfo {
    getDealsInfo {
      name
      description
      terms
      img_url
      url_key
      banner_url
      mobile_banner_url
      end_time
    }
  }
`
