import { gql } from '@apollo/client'

export default gql`
  query getAllOccasions {
    getAllOccasions {
      id
      name
      img_url
      url_key
      type
    }
  }
`
