import React, { Fragment } from 'react'
import stripHtmlComments from 'strip-html-comments'
import * as Styled from './text-link-toggle.styled'

const TextLinkToggle = ({
  expandText,
  handleClick,
  handleKeyPress,
  linkTitle,
  htmlTextBody,
  titleStyle = {},
}) => {
  return (
    <Fragment>
      <Styled.LinkTitle
        tabIndex="0"
        className="vx_text-body-md"
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        style={titleStyle}
      >
        {linkTitle}
        {expandText ? (
          <span className="vx_icon vx_icon-small vx_icon-arrow-up-small" />
        ) : (
          <span className="vx_icon vx_icon-small vx_icon-arrow-down-small" />
        )}
      </Styled.LinkTitle>

      {expandText && (
        <Styled.TextBody className="vx_text-body-sm">
          <div
            dangerouslySetInnerHTML={{
              __html: stripHtmlComments(htmlTextBody),
            }}
          />
        </Styled.TextBody>
      )}
    </Fragment>
  )
}

export default TextLinkToggle
