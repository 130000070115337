import styled from '@emotion/styled/macro'

export const Container = styled.div`
  position: relative;
  font-family: 'PayPalSansBig-Regular';
`

export const CalendarInputInner = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2px 0 5px;
`

export const CalenderInputWrapper = styled.div`
  width: 100%;
  border: solid 1px #b7bcbf;
  border-radius: 0.25rem;
  padding-left: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  span {
    margin-right: 14px;
    margin-top: -10px;
  }

  :hover {
    cursor: pointer;
  }
`

export const DateLabel = styled.label`
  display: block;
  font-size: 12px;
  color: ${(props) => props.theme.colors.secondary.greymedium};
  margin-bottom: 0;
  margin-top: 4px;
  height: 15px;
  cursor: inherit;
`

export const DateInput = styled.input`
  display: table-cell;
  border: none;
  outline: none;
  font-size: 15px;
  padding-right: 20px;
  width: 100%;
  cursor: inherit;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
`
