import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'

import { Box } from '../box'

export const CardWrapper = styled(Link)`
  border-radius: 0.5rem;
  -webkit-mask-image: -webkit-radial-gradient(
    white,
    black
  ); /* fix for rounded corners on video in safari */
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15), 0 0px 0px rgba(0, 0, 0, 0.1);
  ${({ theme }) => theme.inViewStyle()}

  @media (hover: hover) {
    -webkit-mask-image: none;
    &:hover {
      text-decoration: none;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15),
        0 4px 14px rgba(0, 0, 0, 0.1);
      transform: scale(1.025);
    }
  }
`
export const MainImageContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  background: grey;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem 0.5rem 0 0;
  }

  img,
  video {
    position: absolute;
    height: 100%;
    object-fit: cover;
  }
`

export const CardContent = styled(Box)`
  padding-bottom: 0;
  flex-grow: 1;
`

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const CardImages = styled(Box)`
  width: 100%;
  overflow: hidden;
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const CardImage = styled.img`
  width: calc(25% - 1rem);
  margin: 0 0.5rem 0 0;
  border-radius: 0.25rem;
`
