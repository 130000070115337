import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'

export const LogoBubble = styled(Link)`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.secondary.greylight};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  ${({ theme }) => theme.inViewStyle()}
  -webkit-mask-image: -webkit-radial-gradient(
    white,
    black
  );

  transition: box-shadow 200ms ease, transform 200ms ease;
  cursor: pointer;

  img {
    display: block;
    max-width: 70%;
    min-width: 70%;
    height: auto;
  }

  @media (hover: hover) {
    -webkit-mask-image: none;
    mask-image: none;

    &:focus,
    &:hover {
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
      transform: scale(1.025);
    }
  }
`
